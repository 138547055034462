import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import DatesLineChart from './charts/DatesLineChart';

const BySourcePerDates = ({ year, source, sites, aggregated, unit, setChartData }) => {
    const { notifyError } = useNotify();
    const [lineData, setLineData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        doSwaggerCall('Dashboards', 'getDataForSourcePerDatesLineChart', {
            year,
            source,
            siteIdList: sites,
            aggregated,
            unit: unit || 'co2e',
        })
            .then((res) => {
                setChartData(res.sourcePerDates);
                setLineData(res);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [year, source, sites, aggregated, unit, setLoading, setChartData]);

    return (
        <Grid item xs={12}>
            {lineData?.length === 0 ? (
                <Grid item>
                    {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
                </Grid>
            ) : (
                <DatesLineChart
                    data={lineData.sourcePerDates}
                    unitName={lineData.unitName}
                    itemName={lineData.sourceName}
                    year={year}
                />
            )}
        </Grid>
    );
};

export default BySourcePerDates;
