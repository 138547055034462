import { Checkbox, CircularProgress, FormControlLabel, Grid, TextField } from '@mui/material';
import React from 'react';
import AutocompleteSelect from '../../atom/AutoCompleteSelect';
import {
    esgQuestionAutocomplete,
    intensityMetricsAutocomplete,
    siteAutocomplete,
} from '../../../autocomplete';
import { groupQuestion } from '../../../helpers/groups';
import FieldsTable from './FieldsTable';
import YearSelector from './YearSelector';

const EsgQuestionPerIntensityLineSelector = ({
    panelConfig,
    setPanelConfig,
    loading,
    esgFields,
}) => {
    return (
        <>
            <Grid item xs={12} sx={{ mb: 2 }}>
                <AutocompleteSelect
                    grouping={groupQuestion}
                    initCall={esgQuestionAutocomplete.init}
                    autocompleteCall={esgQuestionAutocomplete.search}
                    value={panelConfig?.esgQuestion || ''}
                    searchParams={{
                        onlyNumeric: true,
                    }}
                    setValue={(ev, getItem) => {
                        const item = getItem(ev);
                        setPanelConfig({
                            ...panelConfig,
                            esgQuestion: item?.id,
                            fields:
                                item?.fields
                                    .filter((field) => field.type === 'number')
                                    .map((field) => ({
                                        id: field.id,
                                        measure: field.defaultMeasure,
                                    })) || [],
                        });
                    }}
                >
                    <TextField label="ESG question (only numeric types)" fullWidth />
                </AutocompleteSelect>
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
                <AutocompleteSelect
                    initCall={siteAutocomplete.init}
                    multiple
                    autocompleteCall={siteAutocomplete.search}
                    value={panelConfig?.sites || []}
                    setValue={(ev) => {
                        setPanelConfig({ ...panelConfig, sites: ev });
                    }}
                >
                    <TextField label="Sites" fullWidth />
                </AutocompleteSelect>
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
                <AutocompleteSelect
                    initCall={intensityMetricsAutocomplete.init}
                    autocompleteCall={intensityMetricsAutocomplete.search}
                    value={panelConfig?.intensityMetric || ''}
                    setValue={(ev) => {
                        setPanelConfig({ ...panelConfig, intensityMetric: ev });
                    }}
                >
                    <TextField label="Intensity metric" fullWidth />
                </AutocompleteSelect>
            </Grid>
            <YearSelector panelConfig={panelConfig} setPanelConfig={setPanelConfig} multiSelect />
            {loading ? (
                <Grid item xs={12}>
                    <CircularProgress size={15} />
                </Grid>
            ) : (
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <FieldsTable
                        fieldItems={esgFields}
                        panelConfig={panelConfig}
                        setPanelConfig={setPanelConfig}
                    />
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="Company wide total"
                            control={
                                <Checkbox
                                    checked={panelConfig?.includeTotal || false}
                                    onChange={(e) =>
                                        setPanelConfig({
                                            ...panelConfig,
                                            includeTotal: !!e.target.checked,
                                        })
                                    }
                                />
                            }
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default EsgQuestionPerIntensityLineSelector;
