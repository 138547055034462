import {
    Alert,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import FloatInput from '../../../components/atom/FloatInput';
import { MODAL_TYPE } from '../../../hooks/useModal';
import { MONTHS } from '../../../helpers/constans';

function setLabel(metric, monthly) {
    return monthly ? `default for ${metric} for each month` : `${metric} yearly value`;
}

const IntensityMetricTableWithMonths = ({ values, setFieldValue, metric, idx }) => {
    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell>Value</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {MONTHS.map((month, monthIdx) => (
                    <TableRow key={monthIdx}>
                        <TableCell>{month}</TableCell>
                        <TableCell>
                            <FloatInput
                                fullWidth
                                label={`${metric} value`}
                                type="number"
                                value={values.intensityMetrics[idx].months?.[month] || ''}
                                onChange={(ev) => {
                                    const updatedMetrics = [...values.intensityMetrics];
                                    if (!updatedMetrics[idx].months) {
                                        updatedMetrics[idx].months = {};
                                    }
                                    updatedMetrics[idx].months[month] = ev.target.rawValue;
                                    setFieldValue('intensityMetrics', updatedMetrics);
                                }}
                            />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

const IntensityMetricRow = ({
    values,
    setFieldValue,
    metric,
    value,
    disabled,
    idx,
    deleteIntensityMetric,
    showModal,
    initData,
}) => {
    const [monthly, setMonthly] = useState(!!values.intensityMetrics[idx].months);

    return (
        <>
            <TableRow key={idx}>
                <TableCell>
                    <TextField
                        fullWidth
                        autoComplete="off"
                        type="text"
                        required
                        value={metric}
                        label="metric name"
                        onChange={(ev) => {
                            setFieldValue(`intensityMetrics[${idx}]`, {
                                metric: ev.target.value,
                                value,
                            });
                        }}
                        disabled={disabled || false}
                    />
                </TableCell>
                <TableCell>
                    <FloatInput
                        fullWidth
                        label={setLabel(metric, monthly)}
                        type="number"
                        value={value}
                        onChange={(ev) => {
                            setFieldValue(`intensityMetrics[${idx}]`, {
                                metric,
                                value: ev.target.rawValue,
                                months: values.intensityMetrics[idx].months,
                            });
                        }}
                    />
                </TableCell>
                <TableCell>
                    <ToggleButtonGroup
                        color="standard"
                        size="small"
                        exclusive
                        value={values.intensityMetrics[idx].months ? 'monthly' : 'yearly'}
                        onChange={(ev, v) => {
                            if (!v) {
                                return; // if the user clicks on the same button, do nothing
                            }
                            setFieldValue(`intensityMetrics[${idx}]`, {
                                metric,
                                value,
                                months: v === 'yearly' ? null : {},
                            });
                            setMonthly(v === 'monthly');
                        }}
                    >
                        <ToggleButton value="yearly">Yearly</ToggleButton>
                        <ToggleButton value="monthly">Monthly</ToggleButton>
                    </ToggleButtonGroup>
                </TableCell>
                <TableCell>
                    <IconButton
                        onClick={() => {
                            // if metric is not saved yet, just remove it
                            if (!initData.intensityMetrics.map((e) => e.metric).includes(metric)) {
                                const updatedMetrics = [...values.intensityMetrics];
                                updatedMetrics.splice(idx, 1);
                                setFieldValue('intensityMetrics', updatedMetrics);
                                return;
                            }
                            showModal(MODAL_TYPE.CONFIRMATION, {
                                title: `Are you sure you want to delete this intensity metrics from every year?`,
                                content: 'This action is irreversible.',
                                warningContent:
                                    'Deleting a metric will remove it from EVERY year, if the intention was to not set the metric to a specific year, just empty out the input and save the metrics.',
                                confirm: () => deleteIntensityMetric(metric),
                            });
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            {monthly && (
                <>
                    {(values.intensityMetrics[idx].value === null ||
                        values.intensityMetrics[idx].value === '') && (
                        <TableRow>
                            <TableCell colSpan={4}>
                                <Alert severity="error">
                                    Before saving monthly values, please set a default value below.
                                </Alert>
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell colSpan={4}>
                            <IntensityMetricTableWithMonths
                                values={values}
                                setFieldValue={setFieldValue}
                                metric={metric}
                                idx={idx}
                            />
                        </TableCell>
                    </TableRow>
                </>
            )}
        </>
    );
};

export default IntensityMetricRow;
