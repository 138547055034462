import React, { useState } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { doSwaggerCall } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import { useGlobalFilters } from '../hooks/useGlobalFilters';
import CommonButton from '../components/atom/CommonButton';

const CopySupplierModal = ({ title, rows, year, showModal, MODAL_TYPE, reloadData }) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const { notifySuccess, notifyError } = useNotify();
    const [supplierYear, setSupplierYear] = useState(year);
    const { availableYears } = useGlobalFilters();

    const addSupplier = async () => {
        try {
            for (let i = 0; i < rows.length; i++) {
                // eslint-disable-next-line no-await-in-loop
                await doSwaggerCall(
                    'Suppliers',
                    'addSupplier',
                    {},
                    {
                        name: rows[i].name,
                        industry: rows[i].industry,
                        type: rows[i].type,
                        locationId: rows[i].locationId,
                        year: supplierYear,
                        contacts: rows[i].contacts,
                        categoryId: rows[i].categoryId,
                        supplierTagList: rows[i].supplierTags.map((tag) => tag.name),
                    }
                );
            }
            notifySuccess(`Stakeholder(s) added successfully from ${year} to ${supplierYear}`);
            reloadData();
        } catch (err) {
            notifyError(err);
        }
    };

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <FormControl fullWidth>
                    <InputLabel id="year-select">Year</InputLabel>
                    <Select
                        fullWidth
                        labelId="year-select"
                        id="demo-simple-select"
                        value={supplierYear}
                        size="small"
                        label="Year"
                        onChange={(e) => {
                            setSupplierYear(parseInt(e.target.value, 10));
                        }}
                    >
                        {availableYears.map((yearItem) => (
                            <MenuItem key={yearItem.year} value={yearItem.year}>
                                {yearItem.year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        addSupplier();
                        showModal(MODAL_TYPE.NONE);
                    }}
                    label="Yes"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default CopySupplierModal;
