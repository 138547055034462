import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField, Typography, Tooltip, FormControl, Autocomplete } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import ContactEditor from '../components/ContactEditor';
import AutocompleteSelect from '../../../components/atom/AutoCompleteSelect';
import {
    categoryWithoutLimitationForSupplierAutocomplete,
    locationAutocomplete,
} from '../../../autocomplete';
import CommonButton from '../../../components/atom/CommonButton';
import PageWithHeader from '../../../components/header/PageWithHeader';
import FreeSoloSupplierTagAutoComplete from '../../../components/atom/FreeSoloSupplierTagAutoComplete';

function groupScope(item) {
    return `${item?.scope_name}`;
}
function EditSupplierPage({ newSupplier }) {
    const { year } = useGlobalFilters();
    const { supplierId } = useParams();
    const { showModal } = useModals();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { notifyError, notifySuccess } = useNotify();
    const [supplier, setSupplier] = useState(null);
    const [industries, setIndustries] = useState([]);
    const [stakeholderTypes, setStakeholderTypes] = useState([]);

    const defaultSupplier = {
        name: 'New name',
        industry: 'New industry',
        type: 'Supplier',
        location_id: 1,
        contacts: [],
        co2e: 0,
        netzero: false,
        year,
        supplier_id: null,
        closedSurvey: 'not received yet',
        supplierTags: [],
    };

    // helper function to load industries for autocomplete
    useEffect(() => {
        fetch('/assets/industries.json')
            .then((response) => response.json())
            .then((data) => {
                setIndustries([...new Set(data)]);
            });
    }, []);

    // helper function to load stakeholder types for autocomplete
    const loadStakeholderTypes = useCallback(async () => {
        try {
            const res = await doSwaggerCall('Suppliers', 'getStakeholderTypes');
            setStakeholderTypes(res.stakeholderTypes);
        } catch (error) {
            notifyError(error);
        }
    }, []);

    const loadSupplier = useCallback(() => {
        setLoading(true);
        doSwaggerCall('Suppliers', 'getSupplier', { id: supplierId })
            .then((res) => {
                setSupplier({
                    id: res.id,
                    name: res.name,
                    industry: res.industry,
                    type: res.type,
                    location_id: res.location_id,
                    contacts: res.contacts.map((contact) => ({
                        id: `${Math.random()}`.substring(2),
                        name: contact.name,
                        email: contact.email,
                        phone: contact.phone,
                    })),
                    co2e: res.co2e,
                    netzero: res.netzero,
                    year: res.year,
                    category_id: res.category_id,
                    closedSurvey: res.closedSurvey,
                    supplierTags: res.supplierTags,
                });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                navigate('/suppliers');
                notifyError(err);
            });
    }, [supplierId, year]);

    useEffect(() => {
        loadStakeholderTypes();
        if (!newSupplier) {
            loadSupplier();
        } else {
            setLoading(false);
        }
    }, [supplierId, year, newSupplier]);

    const onEdit = (values) => {
        showModal(MODAL_TYPE.CONFIRMATION, {
            title: `Are you sure you want to edit this Stakeholder?`,
            confirm: () => {
                doSwaggerCall(
                    'Suppliers',
                    'editSupplier',
                    { id: supplierId },
                    {
                        name: values.name,
                        industry: values.industry,
                        locationId: parseInt(values.location_id, 10),
                        contacts: values.contacts.map((contact) => ({
                            name: contact.name,
                            email: contact.email,
                            phone: contact.phone,
                        })),
                        categoryId: values.category_id,
                        supplierTagList: values.supplierTags,
                        type: values.type,
                    }
                )
                    .then(() => {
                        loadSupplier();
                        navigate('/suppliers');
                        notifySuccess('Stakeholder updated');
                    })
                    .catch((err) => {
                        notifyError(err);
                    });
            },
        });
    };

    const onSubmit = async (values) => {
        if (!newSupplier) {
            return onEdit(values);
        }
        try {
            await doSwaggerCall(
                'Suppliers',
                'addSupplier',
                {},
                {
                    name: values.name,
                    industry: values.industry,
                    locationId: parseInt(values.location_id, 10),
                    year,
                    contacts: values.contacts.map((contact) => ({
                        name: contact.name,
                        email: contact.email,
                        phone: contact.phone,
                    })),
                    categoryId: values.category_id,
                    supplierTagList: values.supplierTags,
                    type: values.type,
                }
            );
            navigate('/suppliers');
            notifySuccess('Stakeholder added');
        } catch (error) {
            notifyError(error);
        }
    };

    if (loading) {
        return true;
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={supplier || { ...defaultSupplier }}>
            {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form>
                    <PageWithHeader
                        title={newSupplier ? 'Add new Stakeholder' : `${supplier.name}`}
                    >
                        <Grid item xs={12}>
                            <BlockWithTitle title="Stakeholder Details">
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        autoComplete="off"
                                        required
                                        value={values.name}
                                        onChange={(ev) => {
                                            setFieldValue('name', ev.target.value);
                                        }}
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name ? errors.name : ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            options={industries || []}
                                            freeSolo
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Industry" required />
                                            )}
                                            onInputChange={(_, inputValue) => {
                                                setFieldValue('industry', inputValue);
                                            }}
                                            value={values?.industry}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            options={stakeholderTypes}
                                            freeSolo
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Stakeholder type"
                                                    required
                                                />
                                            )}
                                            onInputChange={(_, inputValue) => {
                                                setFieldValue('type', inputValue);
                                            }}
                                            value={values?.type}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <AutocompleteSelect
                                        initCall={locationAutocomplete.init}
                                        autocompleteCall={locationAutocomplete.search}
                                        value={values?.location_id}
                                        setValue={(ev) => setFieldValue('location_id', ev)}
                                    >
                                        <TextField label="Location" required />
                                    </AutocompleteSelect>
                                </Grid>
                                <Grid item xs={12}>
                                    <AutocompleteSelect
                                        grouping={groupScope}
                                        initCall={
                                            categoryWithoutLimitationForSupplierAutocomplete.init
                                        }
                                        autocompleteCall={
                                            categoryWithoutLimitationForSupplierAutocomplete.search
                                        }
                                        value={values.category_id}
                                        setValue={(ev) => setFieldValue('category_id', ev)}
                                    >
                                        <TextField label="Account the emission to this category" />
                                    </AutocompleteSelect>
                                </Grid>
                                <Grid item xs={12}>
                                    <BlockWithTitle title="Contacts">
                                        <Grid item xs={12}>
                                            <ContactEditor
                                                values={values}
                                                setFieldValue={setFieldValue}
                                            />
                                        </Grid>
                                    </BlockWithTitle>
                                </Grid>
                                <FreeSoloSupplierTagAutoComplete
                                    fullWidth
                                    label="Stakeholder Tags"
                                    value={values.supplierTags}
                                    setValue={(ev) => setFieldValue('supplierTags', ev)}
                                />
                                <Grid item xs={12}>
                                    <Typography variant="body3">
                                        Survey {values.closedSurvey}
                                    </Typography>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} />
                                    <Grid item container xs={12} justifyContent="flex-end">
                                        <Grid item xs={2} md={2}>
                                            <CommonButton
                                                fullWidth
                                                onClick={() => navigate('/suppliers')}
                                                label="Cancel"
                                                buttonType="secondary"
                                            />
                                        </Grid>
                                        <Grid item xs={2} md={2}>
                                            {values.closedSurvey === 'closed' ? (
                                                <Tooltip title="Stakeholder with closed survey cannot be edited.">
                                                    <span>
                                                        <CommonButton
                                                            fullWidth
                                                            disabled
                                                            type="submit"
                                                            label="Save"
                                                            buttonType="primary"
                                                        />
                                                    </span>
                                                </Tooltip>
                                            ) : (
                                                <CommonButton
                                                    fullWidth
                                                    disabled={
                                                        isSubmitting || values.contacts.length === 0
                                                    }
                                                    type="submit"
                                                    label="Save"
                                                    buttonType="primary"
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </BlockWithTitle>
                        </Grid>
                    </PageWithHeader>
                </Form>
            )}
        </Formik>
    );
}

export default EditSupplierPage;
