import { Checkbox, CircularProgress, FormControlLabel, Grid, TextField } from '@mui/material';
import React from 'react';
import AutocompleteSelect from '../../atom/AutoCompleteSelect';
import { esgQuestionAutocomplete, siteAutocomplete } from '../../../autocomplete';
import YearSelector from './YearSelector';
import { groupQuestion } from '../../../helpers/groups';
import FieldsTable from './FieldsTable';

const EsgQuestionSelector = ({ panelConfig, setPanelConfig, loading, esgFields }) => {
    return (
        <>
            <Grid item xs={12}>
                <AutocompleteSelect
                    grouping={groupQuestion}
                    initCall={esgQuestionAutocomplete.init}
                    autocompleteCall={esgQuestionAutocomplete.search}
                    value={panelConfig?.esgQuestion || ''}
                    searchParams={{
                        onlyNumeric: true,
                    }}
                    setValue={(ev, getItem) => {
                        const item = getItem(ev);
                        setPanelConfig({
                            ...panelConfig,
                            esgQuestion: item?.id,
                            fields:
                                item?.fields
                                    .filter((field) => field.type === 'number')
                                    .map((field) => ({
                                        id: field.id,
                                        measure: field.defaultMeasure,
                                    })) || [],
                        });
                    }}
                >
                    <TextField label="ESG question (only numeric types)" fullWidth />
                </AutocompleteSelect>
            </Grid>
            <Grid item xs={12}>
                <AutocompleteSelect
                    initCall={siteAutocomplete.init}
                    multiple
                    autocompleteCall={siteAutocomplete.search}
                    value={panelConfig?.sites || []}
                    setValue={(ev) => {
                        setPanelConfig({ ...panelConfig, sites: ev });
                    }}
                >
                    <TextField label="Sites" fullWidth />
                </AutocompleteSelect>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    label="Company wide total"
                    control={
                        <Checkbox
                            checked={panelConfig?.includeTotal || false}
                            onChange={(e) =>
                                setPanelConfig({
                                    ...panelConfig,
                                    includeTotal: !!e.target.checked,
                                })
                            }
                        />
                    }
                />
            </Grid>
            {loading ? (
                <Grid item xs={12}>
                    <CircularProgress size={15} />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <FieldsTable
                        fieldItems={esgFields}
                        panelConfig={panelConfig}
                        setPanelConfig={setPanelConfig}
                    />
                </Grid>
            )}
            <YearSelector panelConfig={panelConfig} setPanelConfig={setPanelConfig} multiSelect />
        </>
    );
};

export default EsgQuestionSelector;
