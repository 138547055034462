import React from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    IconButton,
    Tooltip,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CommonButton from '../components/atom/CommonButton';
import useNotify from '../hooks/useNotify';
import { doSwaggerCall } from '../hooks/useApi';

const SendIntegrationModal = ({ id, contactsData, confirm, title, showModal, MODAL_TYPE }) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const { notifySuccess, notifyError } = useNotify();

    const getIntegrationLinkToken = async () => {
        try {
            const response = await doSwaggerCall(
                'Integrations',
                'sendIntegrationLink',
                {},
                {
                    integrationId: id,
                    onlyGetToken: true,
                }
            );
            navigator.clipboard.writeText(response.url);
            notifySuccess('Link copied');
        } catch (error) {
            notifyError(error);
        }
    };

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Copy Integration link
                    <IconButton
                        disableRipple
                        onClick={() => {
                            getIntegrationLinkToken();
                        }}
                    >
                        <Tooltip title="Copy link" arrow placement="right">
                            <ContentCopyIcon />
                        </Tooltip>
                    </IconButton>
                </DialogContentText>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>Sending to:</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contactsData.map((contactData) =>
                                contactData.contacts.map((contact, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell>
                                            {`${contact.name} ${idx === 0 ? '' : '(cc)'}`}
                                        </TableCell>
                                        <TableCell>{contact.email}</TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <CommonButton onClick={closeAction} autoFocus label="Back" buttonType="secondary" />
                <CommonButton
                    onClick={() => {
                        confirm();
                        closeAction();
                    }}
                    label="Send"
                    buttonType="primary"
                    disabled={contactsData[0].contacts?.length === 0}
                />
            </DialogActions>
        </Dialog>
    );
};

export default SendIntegrationModal;
