import {
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import React from 'react';
import AutocompleteSelect from '../../atom/AutoCompleteSelect';
import { esgQuestionAutocomplete, siteAutocomplete } from '../../../autocomplete';
import { groupQuestion } from '../../../helpers/groups';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import FieldsTable from './FieldsTable';

const EsgQuestionLineSelector = ({ panelConfig, setPanelConfig, loading, esgFields }) => {
    const { availableYears } = useGlobalFilters();

    return (
        <>
            <Grid item xs={12}>
                <AutocompleteSelect
                    grouping={groupQuestion}
                    initCall={esgQuestionAutocomplete.init}
                    autocompleteCall={esgQuestionAutocomplete.search}
                    value={panelConfig?.esgQuestion || ''}
                    searchParams={{
                        onlyNumeric: true,
                    }}
                    setValue={(ev, getItem) => {
                        const item = getItem(ev);
                        setPanelConfig({
                            ...panelConfig,
                            esgQuestion: item?.id,
                            fields:
                                item?.fields
                                    .filter((field) => field.type === 'number')
                                    .map((field) => ({
                                        id: field.id,
                                        measure: field.defaultMeasure,
                                    })) || [],
                        });
                    }}
                >
                    <TextField label="ESG question (only numeric types)" fullWidth />
                </AutocompleteSelect>
            </Grid>
            <Grid item xs={12}>
                <AutocompleteSelect
                    initCall={siteAutocomplete.init}
                    multiple
                    autocompleteCall={siteAutocomplete.search}
                    value={panelConfig?.sites || []}
                    setValue={(ev) => {
                        setPanelConfig({ ...panelConfig, sites: ev });
                    }}
                >
                    <TextField label="Sites" fullWidth />
                </AutocompleteSelect>
            </Grid>
            <Grid container spacing={1} sx={{ mb: 1, mt: 0.5 }}>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="from-year-select">From</InputLabel>
                        <Select
                            fullWidth
                            labelId="from-year-select"
                            id="demo-simple-select"
                            value={panelConfig?.fromYear || ''}
                            size="small"
                            label="From year"
                            onChange={(e) => {
                                setPanelConfig({
                                    ...panelConfig,
                                    fromYear: e.target.value,
                                });
                            }}
                        >
                            {availableYears.map((yearItem) => (
                                <MenuItem key={yearItem.year} value={yearItem.year}>
                                    {yearItem.year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="to-year-select">To</InputLabel>
                        <Select
                            fullWidth
                            labelId="to-year-select"
                            id="demo-simple-select"
                            value={panelConfig?.toYear || ''}
                            size="small"
                            label="To year"
                            onChange={(e) => {
                                setPanelConfig({
                                    ...panelConfig,
                                    toYear: e.target.value,
                                });
                            }}
                        >
                            {availableYears.map((yearItem) => (
                                <MenuItem key={yearItem.year} value={yearItem.year}>
                                    {yearItem.year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {loading ? (
                <Grid item xs={12}>
                    <CircularProgress size={15} />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <FieldsTable
                        fieldItems={esgFields}
                        panelConfig={panelConfig}
                        setPanelConfig={setPanelConfig}
                    />
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="Company wide total"
                            control={
                                <Checkbox
                                    checked={panelConfig?.includeTotal || false}
                                    onChange={(e) =>
                                        setPanelConfig({
                                            ...panelConfig,
                                            includeTotal: !!e.target.checked,
                                        })
                                    }
                                />
                            }
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default EsgQuestionLineSelector;
