import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import { AVAILABLE_OPTIONS_FOR_SUPPLIERS } from '../../../helpers/constans';

const TableSelectDropdown = ({
    open,
    anchorEl,
    onClose,
    reloadData,
    selectedSuppliers,
    setSelectedSuppliers,
    setSuppliersChanged,
    sendSurveyIdList,
    year,
    rows,
    getContactsData,
    limit,
    page,
    setParams,
}) => {
    const { showModal } = useModals();
    const { notifySuccess, notifyError } = useNotify();

    function menuClickHandler(value) {
        // always close menu when clicking
        onClose();
        switch (value) {
            case 'copySuppliers':
                showModal(MODAL_TYPE.COPY_SUPPLIER, {
                    title: `Copy and add as a new Stakeholder`,
                    rows: rows.filter((row) => selectedSuppliers.includes(row.id)),
                    year,
                    reloadData,
                });
                break;
            case 'sendSurvey':
                showModal(MODAL_TYPE.CONFIRMATION_SEND_SURVEY, {
                    title: 'Are you sure you want to send survey?',
                    contactsData: getContactsData([...selectedSuppliers]),
                    limit,
                    page,
                    setParams,
                    confirm: (selectedCustomQuestions, isGhg) =>
                        sendSurveyIdList([...selectedSuppliers], selectedCustomQuestions, isGhg),
                });
                break;
            case 'deleteSuppliers':
                setSuppliersChanged(false);
                showModal(MODAL_TYPE.CONFIRMATION, {
                    title: `Are you sure you want to remove Stakeholders?`,
                    content: 'This action is irreversible.',
                    warningContent:
                        'Datalines created by these Stakeholders will be deleted if the year is not closed.',
                    confirm: () => {
                        doSwaggerCall('Suppliers', 'deleteSuppliersByList', {
                            idList: selectedSuppliers,
                        })
                            .then(() => {
                                notifySuccess('Stakeholders deleted');
                                reloadData();
                                setSelectedSuppliers([]);
                                setSuppliersChanged(true);
                            })
                            .catch((err) => {
                                notifyError(err);
                            });
                    },
                });
                break;
            default:
                // do nothing
                console.log('default');
                break;
        }
    }

    return (
        <Menu
            id="dropdown-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            aria-haspopup="true"
        >
            {AVAILABLE_OPTIONS_FOR_SUPPLIERS.map((option) => (
                <MenuItem
                    key={option.value}
                    value={option.value}
                    onClick={() => menuClickHandler(option.value)}
                    disabled={selectedSuppliers.length === 0}
                >
                    {option.label}
                </MenuItem>
            ))}
        </Menu>
    );
};

export default TableSelectDropdown;
