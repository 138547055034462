import React from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { MONTHS } from '../../helpers/constans';
import returnMonthFromDateRange from '../../pages/datalineedit/helpers/returnMonthFromDateRange';

const DateRangeInput = ({
    monthDateRange,
    setMonthDateRange,
    values,
    setFieldValue,
    year,
    disabled,
    touched,
    errors,
}) => {
    return (
        <>
            <Grid item xs={4}>
                <InputLabel sx={{ fontSize: '1rem' }}>From date</InputLabel>
                <TextField
                    fullWidth
                    type="date"
                    id="from_date"
                    name="from_date"
                    value={values.from_date}
                    onChange={(ev) => {
                        setFieldValue('from_date', ev.target.value);
                        // check if the date range is a month in this year and set monthDateRange
                        const monthIdx = returnMonthFromDateRange(
                            ev.target.value,
                            values.to_date,
                            year
                        );
                        if (monthIdx !== 0) {
                            setMonthDateRange(monthIdx);
                        } else {
                            setMonthDateRange(0);
                        }
                    }}
                    error={touched.from_date && Boolean(errors.from_date)}
                    helperText={touched.from_date ? errors.from_date : ''}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={4}>
                <InputLabel sx={{ fontSize: '1rem' }}>To date</InputLabel>
                <TextField
                    fullWidth
                    type="date"
                    id="to_date"
                    name="to_date"
                    value={values.to_date}
                    onChange={(ev) => {
                        setFieldValue('to_date', ev.target.value);
                        // check if the date range is a month in this year and set monthDateRange
                        const monthIdx = returnMonthFromDateRange(
                            values.from_date,
                            ev.target.value,
                            year
                        );
                        if (monthIdx !== 0) {
                            setMonthDateRange(monthIdx);
                        } else {
                            setMonthDateRange(0);
                        }
                    }}
                    error={touched.to_date && Boolean(errors.to_date)}
                    helperText={touched.to_date ? errors.to_date : ''}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <InputLabel id="months-select">Month</InputLabel>
                    <Select
                        fullWidth
                        labelId="months-select"
                        id="demo-simple-select"
                        value={monthDateRange}
                        label="Month"
                        onChange={(e) => {
                            // 'None' case
                            if (e.target.value === 0) {
                                setMonthDateRange(0);
                                return;
                            }
                            // selected month - set from_date and to_date based on selected month
                            const firstDay = new Date(year, e.target.value - 1, 1);
                            const lastDay = new Date(year, e.target.value, 0);
                            const formatDate = (date) =>
                                `${year}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
                                    date.getDate()
                                ).padStart(2, '0')}`;
                            setFieldValue('from_date', formatDate(firstDay));
                            setFieldValue('to_date', formatDate(lastDay));
                            setMonthDateRange(e.target.value);
                        }}
                        disabled={disabled}
                    >
                        <MenuItem value={0}>Specific dates</MenuItem>
                        {MONTHS.map((month, idx) => (
                            <MenuItem key={idx} value={idx + 1}>
                                {month}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </>
    );
};

export default DateRangeInput;
