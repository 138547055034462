import {
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import AutocompleteSelect from '../atom/AutoCompleteSelect';
import {
    categoryAutocomplete,
    datalineTagAutocomplete,
    scopeAutocomplete,
    siteAutocomplete,
    intensityMetricsAutocomplete,
    scenarioAutocomplete,
    sourceAutocomplete,
    supplierAutocomplete,
    subcategoryAutocomplete,
    esgScenarioAutocomplete,
    esgQuestionAutocomplete,
} from '../../autocomplete';
import YearSelector from './components/YearSelector';
import { doSwaggerCall } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import EsgQuestionSelector from './components/EsgQuestionSelector';
import {
    groupCategory,
    groupEmissionSource,
    groupQuestion,
    groupSubcategory,
} from '../../helpers/groups';
import EsgScenarioQuestionSelector from './components/EsgScenarioQuestionSelector';
import EsgQuestionLineSelector from './components/EsgQuestionLineSelector';
import SupplierCustomQuestionSelector from './components/SupplierCustomQuestionSelector';
import SupplierSelector from './components/SupplierSelector';
import FieldsTable from './components/FieldsTable';
import EsgQuestionPerIntensityLineSelector from './components/EsgQuestionPerIntensityLineSelector';
import SupplierPieSelector from './components/SupplierPieSelector';

const ExtraFieldSelector = ({ selectedPanel, panelConfig, setPanelConfig }) => {
    const [esgFields, setEsgFields] = useState(panelConfig?.fields || []);
    const [loading, setLoading] = useState(false);
    const { notifyError } = useNotify();
    const [hierarchyLevel, setHierarchyLevel] = useState(panelConfig?.scope ? 'Scope' : 'Category');

    useEffect(() => {
        setLoading(false);
        if (
            selectedPanel === 'esg_question_bar_chart' ||
            selectedPanel === 'esg_question_line_chart' ||
            selectedPanel === 'esg_question_pie_chart' ||
            selectedPanel === 'esg_question_per_intensity_line_chart' ||
            selectedPanel === 'esg_question_per_dates_line_chart'
        ) {
            if (!panelConfig.esgQuestion) {
                setEsgFields([]);
                return;
            }
            setLoading(true);
            doSwaggerCall('EsgQuestions', 'getAllEsgQuestions', {
                idList: [panelConfig.esgQuestion],
            })
                .then((res) => {
                    setEsgFields(
                        res.esgQuestions[0]?.fields.filter((f) => f.type === 'number') || []
                    );
                    setLoading(false);
                })
                .catch((err) => {
                    notifyError(err);
                    setLoading(false);
                });
        }
    }, [selectedPanel, panelConfig?.esgQuestion]);

    return (
        (selectedPanel === 'scope_line_chart' && (
            <Grid item xs={12}>
                <AutocompleteSelect
                    initCall={scopeAutocomplete.init}
                    multiple
                    autocompleteCall={scopeAutocomplete.search}
                    value={panelConfig?.scopes || []}
                    setValue={(ev) => {
                        setPanelConfig({ ...panelConfig, scopes: ev });
                    }}
                >
                    <TextField label="Scopes" fullWidth />
                </AutocompleteSelect>
            </Grid>
        )) ||
        (selectedPanel === 'category_bar_chart' && (
            <>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <AutocompleteSelect
                        grouping={groupCategory}
                        initCall={categoryAutocomplete.init}
                        multiple
                        autocompleteCall={categoryAutocomplete.search}
                        value={panelConfig?.categories || []}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, categories: ev });
                        }}
                        searchParams={{
                            sourceLimitation: true,
                        }}
                    >
                        <TextField label="Categories" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect
                />
            </>
        )) ||
        (selectedPanel === 'tag_bar_chart' && (
            <>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <AutocompleteSelect
                        initCall={datalineTagAutocomplete.init}
                        multiple
                        autocompleteCall={datalineTagAutocomplete.search}
                        value={panelConfig?.tags || []}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, tags: ev });
                        }}
                    >
                        <TextField label="Tags" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect
                />
            </>
        )) ||
        (selectedPanel === 'scope_pie_chart' && (
            <>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <AutocompleteSelect
                        initCall={scopeAutocomplete.init}
                        autocompleteCall={scopeAutocomplete.search}
                        value={panelConfig?.scope || ''}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, scope: ev });
                        }}
                    >
                        <TextField label="Scope" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect={false}
                />
            </>
        )) ||
        (selectedPanel === 'category_pie_chart' && (
            <>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <AutocompleteSelect
                        grouping={groupCategory}
                        initCall={categoryAutocomplete.init}
                        autocompleteCall={categoryAutocomplete.search}
                        value={panelConfig?.category || ''}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, category: ev });
                        }}
                        searchParams={{
                            sourceLimitation: true,
                        }}
                    >
                        <TextField label="Category" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect={false}
                />
            </>
        )) ||
        (selectedPanel === 'subcategory_pie_chart' && (
            <>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <AutocompleteSelect
                        grouping={groupSubcategory}
                        initCall={subcategoryAutocomplete.init}
                        autocompleteCall={subcategoryAutocomplete.search}
                        value={panelConfig?.subcategory || ''}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, subcategory: ev });
                        }}
                        searchParams={{
                            sourceLimitation: true,
                        }}
                    >
                        <TextField label="Subcategory" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect={false}
                />
            </>
        )) ||
        (selectedPanel === 'site_pie_chart' && (
            <>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <AutocompleteSelect
                        initCall={siteAutocomplete.init}
                        autocompleteCall={siteAutocomplete.search}
                        value={panelConfig?.site}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, site: ev });
                        }}
                    >
                        <TextField label="Site" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect={false}
                />
            </>
        )) ||
        (selectedPanel === 'site_bar_chart' && (
            <>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        initCall={siteAutocomplete.init}
                        multiple
                        autocompleteCall={siteAutocomplete.search}
                        value={panelConfig?.sites || []}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, sites: ev });
                        }}
                    >
                        <TextField label="Sites" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        initCall={scopeAutocomplete.init}
                        multiple
                        autocompleteCall={scopeAutocomplete.search}
                        value={panelConfig?.scopes || []}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, scopes: ev });
                        }}
                    >
                        <TextField label="Scopes" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <AutocompleteSelect
                        grouping={groupCategory}
                        initCall={categoryAutocomplete.init}
                        multiple
                        autocompleteCall={categoryAutocomplete.search}
                        value={panelConfig?.categories || []}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, categories: ev });
                        }}
                        searchParams={{
                            sourceLimitation: true,
                        }}
                    >
                        <TextField label="Categories" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect={false}
                />
            </>
        )) ||
        (selectedPanel === 'scenario_line_chart' && (
            <Grid item xs={12}>
                <AutocompleteSelect
                    initCall={scenarioAutocomplete.init} // TODO: later we need some validation for scenario compare
                    multiple
                    autocompleteCall={scenarioAutocomplete.search}
                    value={panelConfig?.scenarios || []}
                    setValue={(ev) => {
                        setPanelConfig({ ...panelConfig, scenarios: ev });
                    }}
                >
                    <TextField label="Scenarios" fullWidth />
                </AutocompleteSelect>
            </Grid>
        )) ||
        (selectedPanel === 'source_bar_chart' && (
            <>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <AutocompleteSelect
                        grouping={groupEmissionSource}
                        initCall={sourceAutocomplete.init}
                        autocompleteCall={sourceAutocomplete.search}
                        value={panelConfig?.source || ''}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, source: ev });
                        }}
                        searchParams={{
                            sourceLimitation: true,
                            companyLimitation: true,
                        }}
                    >
                        <TextField label="Source" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect
                />
            </>
        )) ||
        (selectedPanel === 'supplier_bar_chart' && (
            <>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <AutocompleteSelect
                        initCall={supplierAutocomplete.init}
                        multiple
                        autocompleteCall={supplierAutocomplete.search}
                        value={panelConfig?.suppliers || []}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, suppliers: ev });
                        }}
                    >
                        <TextField label="Stakeholders" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect
                />
            </>
        )) ||
        (selectedPanel === 'scopes_per_intensity_line_chart' && (
            <>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        initCall={scopeAutocomplete.init}
                        multiple
                        autocompleteCall={scopeAutocomplete.search}
                        value={panelConfig?.scopes || []}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, scopes: ev });
                        }}
                    >
                        <TextField label="Scopes" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        label="Aggregate selected scopes"
                        control={
                            <Checkbox
                                checked={panelConfig?.aggregated || false}
                                onChange={(e) =>
                                    setPanelConfig({
                                        ...panelConfig,
                                        aggregated: !!e.target.checked,
                                    })
                                }
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <AutocompleteSelect
                        initCall={intensityMetricsAutocomplete.init}
                        autocompleteCall={intensityMetricsAutocomplete.search}
                        value={panelConfig?.intensityMetric || ''}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, intensityMetric: ev });
                        }}
                    >
                        <TextField label="Intensity metric" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect
                />
            </>
        )) ||
        (selectedPanel === 'supplier_netzero_targets_bar_chart' && (
            <YearSelector panelConfig={panelConfig} setPanelConfig={setPanelConfig} />
        )) ||
        (selectedPanel === 'esg_question_bar_chart' && (
            <EsgQuestionSelector
                panelConfig={panelConfig}
                setPanelConfig={setPanelConfig}
                loading={loading}
                esgFields={esgFields}
            />
        )) ||
        (selectedPanel === 'sites_per_intensity_bar_chart' && (
            <>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        initCall={siteAutocomplete.init}
                        multiple
                        autocompleteCall={siteAutocomplete.search}
                        value={panelConfig?.sites || []}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, sites: ev });
                        }}
                    >
                        <TextField label="Sites" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        initCall={intensityMetricsAutocomplete.init}
                        autocompleteCall={intensityMetricsAutocomplete.search}
                        value={panelConfig?.intensityMetric || ''}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, intensityMetric: ev });
                        }}
                    >
                        <TextField label="Intensity metric" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <Grid container sx={{ mt: 0.5, mb: 2 }}>
                    <Grid item xs={4}>
                        <InputLabel id="hierarchy-level">Hierarchy level</InputLabel>
                        <Select
                            fullWidth
                            size="small"
                            labelId="hierarchy-level"
                            value={hierarchyLevel}
                            label="Hierarchy level"
                            onChange={(e) => {
                                setHierarchyLevel(e.target.value);
                                setPanelConfig({ ...panelConfig, category: null, scope: null });
                            }}
                        >
                            <MenuItem value="Scope">Scope</MenuItem>
                            <MenuItem value="Category">Category</MenuItem>
                        </Select>
                    </Grid>
                    {hierarchyLevel === 'Category' ? (
                        <Grid item xs={8}>
                            <AutocompleteSelect
                                grouping={groupCategory}
                                initCall={categoryAutocomplete.init}
                                autocompleteCall={categoryAutocomplete.search}
                                value={panelConfig?.category || ''}
                                setValue={(ev) => {
                                    setPanelConfig({ ...panelConfig, category: ev });
                                }}
                                searchParams={{
                                    sourceLimitation: true,
                                }}
                            >
                                <TextField label="Category" fullWidth />
                            </AutocompleteSelect>
                        </Grid>
                    ) : (
                        <Grid item xs={8}>
                            <AutocompleteSelect
                                initCall={scopeAutocomplete.init}
                                autocompleteCall={scopeAutocomplete.search}
                                value={panelConfig?.scope || ''}
                                setValue={(ev) => {
                                    setPanelConfig({ ...panelConfig, scope: ev });
                                }}
                            >
                                <TextField label="Scope" fullWidth />
                            </AutocompleteSelect>
                        </Grid>
                    )}
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect
                />
            </>
        )) ||
        (selectedPanel === 'source_per_dates_line_chart' && (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <AutocompleteSelect
                            grouping={groupEmissionSource}
                            initCall={sourceAutocomplete.init}
                            autocompleteCall={sourceAutocomplete.search}
                            value={panelConfig?.source || ''}
                            setValue={(ev) => {
                                setPanelConfig({ ...panelConfig, source: ev });
                            }}
                            searchParams={{
                                sourceLimitation: true,
                                companyLimitation: true,
                            }}
                        >
                            <TextField label="Source" fullWidth />
                        </AutocompleteSelect>
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel id="unit-type">Unit type</InputLabel>
                        <Select
                            fullWidth
                            size="small"
                            labelId="unit-type"
                            value={panelConfig?.unit || 'co2e'}
                            label="Unit type"
                            onChange={(e) => {
                                setPanelConfig({ ...panelConfig, unit: e.target.value });
                            }}
                        >
                            <MenuItem value="co2e">CO2e (mt)</MenuItem>
                            <MenuItem value="baseunit">Base unit</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        initCall={siteAutocomplete.init}
                        multiple
                        autocompleteCall={siteAutocomplete.search}
                        value={panelConfig?.sites || []}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, sites: ev });
                        }}
                    >
                        <TextField label="Sites" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector panelConfig={panelConfig} setPanelConfig={setPanelConfig} />
                <Grid item xs={12}>
                    <FormControlLabel
                        label="Aggregate selected sites"
                        control={
                            <Checkbox
                                checked={panelConfig?.aggregated || false}
                                onChange={(e) =>
                                    setPanelConfig({
                                        ...panelConfig,
                                        aggregated: !!e.target.checked,
                                    })
                                }
                            />
                        }
                    />
                </Grid>
            </>
        )) ||
        (selectedPanel === 'esg_scenario_line_chart' && (
            <>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        initCall={esgScenarioAutocomplete.init}
                        autocompleteCall={esgScenarioAutocomplete.search}
                        value={panelConfig?.scenario || ''}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, scenario: ev });
                        }}
                    >
                        <TextField label="Scenario" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <EsgScenarioQuestionSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                />
            </>
        )) ||
        (selectedPanel === 'esg_question_line_chart' && (
            <EsgQuestionLineSelector
                panelConfig={panelConfig}
                setPanelConfig={setPanelConfig}
                loading={loading}
                esgFields={esgFields}
            />
        )) ||
        (selectedPanel === 'esg_question_pie_chart' && (
            <>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        grouping={groupQuestion}
                        initCall={esgQuestionAutocomplete.init}
                        autocompleteCall={esgQuestionAutocomplete.search}
                        value={panelConfig?.esgQuestion || ''}
                        searchParams={{
                            onlyNumeric: true,
                        }}
                        setValue={(ev, getItem) => {
                            const item = getItem(ev);
                            setPanelConfig({
                                ...panelConfig,
                                esgQuestion: item?.id,
                                fields:
                                    item?.fields
                                        .filter((field) => field.type === 'number')
                                        .map((field) => ({
                                            id: field.id,
                                            measure: field.defaultMeasure,
                                        })) || [],
                            });
                        }}
                    >
                        <TextField label="ESG question (only numeric types)" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector panelConfig={panelConfig} setPanelConfig={setPanelConfig} />
                {loading ? (
                    <Grid item xs={12}>
                        <CircularProgress size={15} />
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <FieldsTable
                            fieldItems={esgFields}
                            panelConfig={panelConfig}
                            setPanelConfig={setPanelConfig}
                        />
                    </Grid>
                )}
            </>
        )) ||
        (selectedPanel === 'supplier_custom_survey_bar_chart' && (
            <>
                <YearSelector panelConfig={panelConfig} setPanelConfig={setPanelConfig} />
                <SupplierSelector panelConfig={panelConfig} setPanelConfig={setPanelConfig} />
                <SupplierCustomQuestionSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                />
            </>
        )) ||
        (selectedPanel === 'esg_question_per_intensity_line_chart' && (
            <EsgQuestionPerIntensityLineSelector
                panelConfig={panelConfig}
                setPanelConfig={setPanelConfig}
                loading={loading}
                esgFields={esgFields}
            />
        )) ||
        (selectedPanel === 'esg_question_per_dates_line_chart' && (
            <>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        grouping={groupQuestion}
                        initCall={esgQuestionAutocomplete.init}
                        autocompleteCall={esgQuestionAutocomplete.search}
                        value={panelConfig?.esgQuestion || ''}
                        searchParams={{
                            onlyNumeric: true,
                        }}
                        setValue={(ev, getItem) => {
                            const item = getItem(ev);
                            setPanelConfig({
                                ...panelConfig,
                                esgQuestion: item?.id,
                                fields:
                                    item?.fields
                                        .filter((field) => field.type === 'number')
                                        .map((field) => ({
                                            id: field.id,
                                            measure: field.defaultMeasure,
                                        })) || [],
                            });
                        }}
                    >
                        <TextField label="ESG question (only numeric types)" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        initCall={siteAutocomplete.init}
                        multiple
                        autocompleteCall={siteAutocomplete.search}
                        value={panelConfig?.sites || []}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, sites: ev });
                        }}
                    >
                        <TextField label="Sites" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        label="Aggregate selected sites"
                        control={
                            <Checkbox
                                checked={panelConfig?.aggregated || false}
                                onChange={(e) =>
                                    setPanelConfig({
                                        ...panelConfig,
                                        aggregated: !!e.target.checked,
                                    })
                                }
                            />
                        }
                    />
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect={false}
                />
                {loading ? (
                    <Grid item xs={12}>
                        <CircularProgress size={15} />
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <FieldsTable
                            fieldItems={esgFields}
                            panelConfig={panelConfig}
                            setPanelConfig={setPanelConfig}
                        />
                    </Grid>
                )}
            </>
        )) ||
        (selectedPanel === 'supplier_custom_survey_cert_pie_chart' && (
            <>
                <YearSelector panelConfig={panelConfig} setPanelConfig={setPanelConfig} />
                <SupplierPieSelector panelConfig={panelConfig} setPanelConfig={setPanelConfig} />
            </>
        ))
    );
};

export default ExtraFieldSelector;
