import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import React, { useState } from 'react';
import CommonButton from '../components/atom/CommonButton';
import AutocompleteSelect from '../components/atom/AutoCompleteSelect';
import { supplierTagAutocomplete } from '../autocomplete';

function SelectSupplierReportModal({ showModal, MODAL_TYPE, confirm, multipleSupplier = false }) {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const reportTypes = ['PDF', 'HTML', 'XLSX'];
    const [reportType, setReportType] = useState(reportTypes[0]);
    const [auditReportFormat, setAuditReportFormat] = useState(false);
    const [selectedSupplierTag, setSelectedSupplierTag] = useState(null);

    return (
        <Dialog
            aria-labelledby="select-tag-modal"
            open
            onClose={closeAction}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Generate Stakeholder Report</DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description">
                    Select the type of the report that you would like to generate for this
                    Stakeholder.
                </DialogContentText>
                <Grid item xs={12} sx={{ pt: 1 }}>
                    <FormControl fullWidth>
                        <InputLabel id="report-type">Report type</InputLabel>
                        <Select
                            fullWidth
                            labelId="report-type"
                            size="small"
                            label="Report type"
                            value={reportType || ''}
                            onChange={(e) => {
                                setReportType(e.target.value);
                            }}
                        >
                            {reportTypes.map((type) => {
                                return (
                                    <MenuItem value={type} key={type}>
                                        {type}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                {multipleSupplier && (
                    <Grid item xs={12} sx={{ pt: 1 }}>
                        <AutocompleteSelect
                            initCall={supplierTagAutocomplete.init}
                            autocompleteCall={supplierTagAutocomplete.search}
                            value={selectedSupplierTag}
                            setValue={(ev, getItem) => {
                                const item = getItem(ev);
                                setSelectedSupplierTag(item?.id || null);
                            }}
                        >
                            <TextField label="Stakeholder Tags" fullWidth />
                        </AutocompleteSelect>
                    </Grid>
                )}
                {reportType !== 'XLSX' ? (
                    <Grid item xs={12} sx={{ pt: 2 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={auditReportFormat}
                                    onChange={(e) => {
                                        setAuditReportFormat(!!e.target.checked);
                                    }}
                                />
                            }
                            label="Audit report format"
                            labelPlacement="end"
                        />
                    </Grid>
                ) : null}
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={() => showModal(MODAL_TYPE.NONE)}
                    label="Close"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        confirm(
                            reportType,
                            auditReportFormat,
                            multipleSupplier ? selectedSupplierTag : null
                        );
                        showModal(MODAL_TYPE.NONE);
                    }}
                    type="submit"
                    label="Save"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
}

export default SelectSupplierReportModal;
