const formatAxis = (value, data, responseLimit = 12, dataLimit = 15) => {
    let response = value;
    // trimStart for remove starting space
    if (value[0] === ' ') {
        response = value.trimStart();
    }
    if (response.length > responseLimit) {
        response = `${response.substring(0, responseLimit)}...`;
    }
    if (data.length > dataLimit) {
        response = '';
        const words = value.trimStart().split(' ');
        for (let i = 0; i < words.length - 2; i++) {
            response += Number.isNaN(parseFloat(words[i])) ? `${words[i][0]}` : `${words[i]}`;
        }
        if (response.charAt(response.length - 1) === '(') {
            response = response.slice(0, -1);
        }
    }
    return response;
};

export default formatAxis;
