import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { subcategoryWithCategoryAutocomplete } from '../autocomplete';
import AutocompleteSelect from '../components/atom/AutoCompleteSelect';
import { doSwaggerCall } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import CommonButton from '../components/atom/CommonButton';

function AddEmissionSourceModal({ showModal, MODAL_TYPE }) {
    const { notifySuccess, notifyError } = useNotify();
    const navigate = useNavigate();

    const onSubmit = async (values) => {
        try {
            const res = await doSwaggerCall(
                'EmissionSources',
                'addEmissionSource',
                {},
                {
                    name: values.name.trim(),
                    subcategoryId: parseInt(values.subcategory_id, 10),
                    source: '',
                    baseUnit: '',
                    defaultCaltulationLogic: null,
                    adminNote: null,
                    guideNote: null,
                    companySpecificList: 'Not specific',
                }
            );
            navigate(`/emissionsources/edit/${res.id}`);
            notifySuccess('New emission source created!');
            showModal(MODAL_TYPE.NONE);
        } catch (error) {
            notifyError(error);
        }
    };

    const validateSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        subcategory_id: Yup.string().required('Required'),
    });

    return (
        <Dialog
            aria-labelledby="add-emission-source-modal"
            open
            maxWidth="sm"
            fullWidth
            onClose={() => showModal(MODAL_TYPE.NONE)}
        >
            <DialogTitle>Add Emission Source</DialogTitle>
            <Formik
                onSubmit={onSubmit}
                initialValues={{
                    name: '',
                    subcategory_id: '',
                }}
                validationSchema={validateSchema}
            >
                {({ isSubmitting, values, touched, errors, setFieldValue, isValid, dirty }) => (
                    <Form>
                        <Grid>
                            <DialogContent>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <AutocompleteSelect
                                            initCall={subcategoryWithCategoryAutocomplete.init}
                                            autocompleteCall={
                                                subcategoryWithCategoryAutocomplete.search
                                            }
                                            value={values.subcategory_id}
                                            setValue={(ev) => setFieldValue('subcategory_id', ev)}
                                            error={
                                                touched.subcategory_id &&
                                                Boolean(errors.subcategory_id)
                                            }
                                            helperText={
                                                touched.subcategory_id ? errors.subcategory_id : ''
                                            }
                                        >
                                            <TextField label="Subcategory" />
                                        </AutocompleteSelect>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            autoComplete="off"
                                            label="Emission Source name"
                                            type="text"
                                            value={values.name}
                                            onChange={(ev) =>
                                                setFieldValue('name', ev.target.value)
                                            }
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name ? errors.name : ''}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Grid>
                        <DialogActions>
                            <CommonButton
                                variant="inherit"
                                onClick={() => showModal(MODAL_TYPE.NONE)}
                                label="Close"
                                buttonType="secondary"
                            />
                            <CommonButton
                                disabled={isSubmitting || !(isValid && dirty)}
                                type="submit"
                                label="Submit"
                                buttonType="primary"
                            />
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}

export default AddEmissionSourceModal;
