import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import SupplierSurveyCertificatePieChart from './charts/SupplierSurveyCertificatePieChart';

const BySupplierCustomSurveyCertificates = ({ suppliers, year, allSuppliers, setChartData }) => {
    const { notifyError } = useNotify();
    const [certificatePieData, setCertificatePieData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        doSwaggerCall('Dashboards', 'getDataForCertificatePieChart', {
            supplierIds: suppliers.map((supplier) => supplier.id),
            year,
            allSuppliers,
        })
            .then((res) => {
                setCertificatePieData(res);
                setChartData(res.certData);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [suppliers, year, allSuppliers, setLoading, setCertificatePieData, setChartData]);

    return (
        <Grid item xs={12}>
            {certificatePieData?.length === 0 ? (
                <Grid item>
                    {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
                </Grid>
            ) : (
                <SupplierSurveyCertificatePieChart data={certificatePieData.certData} year={year} />
            )}
        </Grid>
    );
};

export default BySupplierCustomSurveyCertificates;
