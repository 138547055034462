import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';

const SupplierPieSelector = ({ panelConfig, setPanelConfig }) => {
    const [suppliers, setSuppliers] = useState([]);
    const { notifyError } = useNotify();
    const [showSuppliers, setShowSuppliers] = useState(!panelConfig?.allSuppliers);

    const getAllSuppliersByYear = async () => {
        try {
            const res = await doSwaggerCall('Suppliers', 'getAllSuppliers', {
                year: panelConfig.year,
                pageSize: 1000,
            });
            setSuppliers(res.suppliers);
        } catch (err) {
            notifyError(err);
        }
    };

    useEffect(() => {
        if (panelConfig.year) {
            getAllSuppliersByYear();
        }
    }, [panelConfig.year]);

    return (
        <Grid item xs={12}>
            {panelConfig?.year && (
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <FormControlLabel
                        label="Allow all Stakeholders"
                        control={
                            <Checkbox
                                checked={panelConfig?.allSuppliers || false}
                                onChange={(e) => {
                                    setPanelConfig({
                                        ...panelConfig,
                                        suppliers: [],
                                        allSuppliers: !!e.target.checked,
                                    });
                                    setShowSuppliers(!e.target.checked);
                                }}
                            />
                        }
                    />
                    <FormControl fullWidth>
                        <Autocomplete
                            options={suppliers}
                            getOptionLabel={(option) => `${option.name}`}
                            value={
                                panelConfig.suppliers
                                    ? suppliers.filter((supplier) =>
                                          panelConfig.suppliers.some(
                                              (selectedSupplier) =>
                                                  selectedSupplier.id === supplier.id
                                          )
                                      )
                                    : []
                            }
                            onChange={(_, newValue) => {
                                setPanelConfig({
                                    ...panelConfig,
                                    suppliers: newValue,
                                });
                            }}
                            multiple
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                                <TextField {...params} label="Only these Stakeholders" />
                            )}
                            disabled={!showSuppliers}
                        />
                    </FormControl>
                </Grid>
            )}
        </Grid>
    );
};

export default SupplierPieSelector;
