import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper, Typography, CircularProgress, Alert } from '@mui/material';
import { useParams } from 'react-router-dom';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import DeckWidget from '../components/DeckWidget';

const ConnectIntegrationPage = () => {
    const { token } = useParams();
    const [integration, setIntegration] = useState(null);
    const [deckLinkToken, setDeckLinkToken] = useState(null);
    const [loading, setLoading] = useState(false);
    const { notifyError } = useNotify();

    useEffect(() => {
        const resolveIntegrationType = async () => {
            setLoading(true);
            try {
                const response = await doSwaggerCall('Integrations', 'resolveIntegration', {
                    token,
                });
                setIntegration({
                    type: response.type,
                    notes: response.notes,
                    companyName: response.companyName,
                    siteName: response.siteName,
                    connected: !!response.connected,
                });

                if (response.type === 'deck') {
                    setDeckLinkToken(response.linkToken);
                }
                setLoading(false);
            } catch (error) {
                notifyError(error);
                setIntegration(null);
                setLoading(false);
            }
        };
        resolveIntegrationType();
    }, [token]);

    const handleFedexIntegration = () => {
        console.log('FedEx integration coming soon.');
    };

    const renderIntegration = () => {
        if (loading) {
            return <CircularProgress size={24} />;
        }
        switch (integration?.type) {
            case 'deck':
                return (
                    <DeckWidget
                        deckToken={deckLinkToken}
                        token={token}
                        onExit={() => console.log('Deck integration exited.')}
                        autoInitialize={false} // set to true to auto initialize
                    />
                );
            case 'fedex':
                return (
                    <Button variant="contained" color="primary" onClick={handleFedexIntegration}>
                        Connect to FedEx
                    </Button>
                );
            default:
                return <p>Something went wrong.</p>;
        }
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={10} md={6}>
                <Paper
                    style={{
                        padding: '2rem',
                        borderRadius: '16px',
                        textAlign: 'center',
                        minHeight: '80vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant="h4" sx={{ mt: 2 }}>
                        Welcome to North Star Carbon & Impact Integration Platform!
                    </Typography>
                    {!loading && (
                        <>
                            {integration?.connected && (
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        This integration is already connected. New connections will
                                        override the existing one.
                                    </Alert>
                                </Grid>
                            )}
                            <Typography variant="body1">
                                {integration?.companyName} ({integration?.siteName}) has invited you
                                to connect your {integration?.type} account to our platform.{' '}
                                {integration?.notes && (
                                    <span style={{ display: 'inline-block', marginTop: '2rem' }}>
                                        Please read the following note: {integration.notes}
                                    </span>
                                )}
                            </Typography>
                        </>
                    )}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {renderIntegration()}
                        </Grid>
                    </Grid>
                    <Typography
                        variant="caption"
                        style={{
                            marginTop: '2rem',
                            color: '#888',
                        }}
                    >
                        If you have any questions or need help, please contact us for further
                        assistance.
                        <p>
                            <a href="info@northstarcarbon.com">info@northstarcarbon.com</a>
                        </p>
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default ConnectIntegrationPage;
