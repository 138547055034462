import { Form, Formik } from 'formik';
import {
    Checkbox,
    FormControlLabel,
    Typography,
    Grid,
    TextField,
    InputAdornment,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import AddItemButton from '../../../components/atom/AddItemButton';
import AutocompleteSelect from '../../../components/atom/AutoCompleteSelect';
import { userAutocomplete, siteAutocomplete } from '../../../autocomplete';
import DatalineFormulaPickerDropdown from './DatalineFormulaPickerDropdown';
import LastModified from './LastModified';
import FreeSoloDatalineTagAutoComplete from '../../../components/atom/FreeSoloDatalineTagAutoComplete';
import FloatInput from '../../../components/atom/FloatInput';
import { useAuth } from '../../../hooks/useAuth';
import { PERMISSIONS, ROLES } from '../../../helpers/constans';
import DocumentField from './DocumentField';
import HelperIcon from '../../../components/atom/HelperIcon';
import validateDataline from '../helpers/validateDataline';
import CommonButton from '../../../components/atom/CommonButton';
import DateRangeAlert from '../../../components/atom/DateRangeAlert';
import returnMonthFromDateRange from '../helpers/returnMonthFromDateRange';
import DateRangeInput from '../../../components/atom/DateRangeInput';
import { doSwaggerCall } from '../../../hooks/useApi';

function DatalineForm({
    onSubmit,
    dataline,
    newDataline,
    setEdited,
    percentages,
    setPercentages,
    closedYear,
    isAirportCalculatorVisible,
    year,
}) {
    const [showNotes, setShowNotes] = useState(!(dataline.notes === null || dataline.notes === ''));
    const [showSop, setShowSop] = useState(!(dataline.sop === null || dataline.sop === ''));
    const [showDocuments, setShowDocuments] = useState(dataline?.documents?.length > 0);
    const [showAssign, setShowAssign] = useState(!!dataline?.assigned_user?.user_id);
    const [showOwner, setShowOwner] = useState(!!dataline?.owner_user?.user_id);
    const [showDatalineTags, setShowDatalineTags] = useState(dataline?.datalineTags.length > 0);
    const [defaultField, setDefaultField] = useState(null);
    const [showDate, setShowDate] = useState(!!dataline?.to_date || !!dataline?.from_date);
    const [showCost, setShowCost] = useState(!!dataline?.cost);
    const [disabledByAirportCalculator, setDisabledByAirportCalculator] = useState(false);
    const [usedDateRange, setUsedDateRange] = useState(false);
    const [monthDateRange, setMonthDateRange] = useState(
        returnMonthFromDateRange(dataline?.from_date, dataline?.to_date, year)
    );
    const [loading, setLoading] = useState(false);
    const [factors, setFactors] = useState(null);

    const { userPermissions, user } = useAuth();
    const basicModifyDatalinePermission = userPermissions.includes(
        PERMISSIONS.BASIC_MODIFY_DATALINES
    );
    const complexModifyDatalinePermission = userPermissions.includes(
        PERMISSIONS.COMPLEX_MODIFY_DATALINES
    );
    const systemManagedDataline = dataline?.system_managed;
    const systemManagedEmissionSource = dataline?.system_managed_emission_source;

    // set site default_field
    useEffect(() => {
        setDefaultField(dataline?.site_default_field);
    }, [dataline]);

    const validate = (values) => {
        return validateDataline(values);
    };

    const getFactors = async () => {
        setLoading(true);
        try {
            const res = await doSwaggerCall('EmissionFactors', 'getEmissionFactors', {
                id: dataline.emission_source_id,
                year,
                limit: 100,
            });
            setFactors(res.factors);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user.role === ROLES.SUPERADMIN) {
            getFactors();
        }
    }, [dataline.emission_source_id, year]);

    return (
        <Formik onSubmit={onSubmit} enableReinitialize initialValues={dataline} validate={validate}>
            {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item container spacing={2}>
                            <Grid item container justifyContent="space-between">
                                <Grid item>
                                    {newDataline && (
                                        <Grid item sx={{ pb: 1 }}>
                                            <Typography variant="h4" style={{ fontSize: '14px' }}>
                                                Add new Dataline
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Typography variant="h6">
                                            {values.emission_source.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {!loading &&
                                (dataline.emission_source.guide_note ||
                                    user.role === ROLES.SUPERADMIN) ? (
                                    <Grid item>
                                        <HelperIcon
                                            title={dataline.emission_source.name}
                                            guideNote={dataline.emission_source.guide_note}
                                            factors={factors}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    autoComplete="off"
                                    label="Emission description"
                                    value={values.emission_description}
                                    onChange={(ev) => {
                                        setFieldValue('emission_description', ev.target.value);
                                        setUsedDateRange(false); // reset alert if user changes the description
                                    }}
                                    InputProps={{
                                        startAdornment: values.incorrect === true && (
                                            <WarningIcon sx={{ mr: 0.5, color: 'red' }} />
                                        ),
                                    }}
                                    disabled={
                                        (!basicModifyDatalinePermission &&
                                            !complexModifyDatalinePermission) ||
                                        systemManagedDataline ||
                                        systemManagedEmissionSource ||
                                        closedYear
                                    }
                                />
                            </Grid>
                            {!usedDateRange &&
                                (basicModifyDatalinePermission ||
                                    complexModifyDatalinePermission) &&
                                !systemManagedDataline &&
                                !systemManagedEmissionSource &&
                                !closedYear && (
                                    <DateRangeAlert
                                        values={values}
                                        setShowDate={setShowDate}
                                        year={year}
                                        setFieldValue={setFieldValue}
                                        setUsedDateRange={setUsedDateRange}
                                        setMonthDateRange={setMonthDateRange}
                                    />
                                )}
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!values.is_estimated}
                                            onChange={(e) =>
                                                setFieldValue('is_estimated', !!e.target.checked)
                                            }
                                        />
                                    }
                                    label="Estimated value"
                                    labelPlacement="end"
                                    disabled={
                                        (!basicModifyDatalinePermission &&
                                            !complexModifyDatalinePermission) ||
                                        systemManagedDataline ||
                                        systemManagedEmissionSource ||
                                        closedYear
                                    }
                                />
                            </Grid>
                            <Grid item style={{ flex: '1 1 0' }}>
                                <AutocompleteSelect
                                    size="small"
                                    initCall={siteAutocomplete.init}
                                    autocompleteCall={siteAutocomplete.search}
                                    value={values?.site_id}
                                    setValue={(ev, getItem) => {
                                        setDefaultField(getItem(ev));
                                        setFieldValue('site_id', ev);
                                    }}
                                    disabled={
                                        (!basicModifyDatalinePermission &&
                                            !complexModifyDatalinePermission) ||
                                        systemManagedDataline ||
                                        systemManagedEmissionSource ||
                                        closedYear
                                    }
                                >
                                    <TextField label="Site" required />
                                </AutocompleteSelect>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <DatalineFormulaPickerDropdown
                                noBaseUnit={values?.emission_source?.has_children}
                                conversionLogic={values?.emission_source?.unit_conversion_logic}
                                calculationType={values?.calculation_type}
                                setCalculationType={(newCalcType) => {
                                    setFieldValue('calculation_type', newCalcType);
                                    setDisabledByAirportCalculator(false);
                                }}
                                fields={values.fields}
                                setFieldValue={setFieldValue}
                                baseUnit={values?.emission_source?.base_unit}
                                amount={values.amount}
                                defaultField={defaultField}
                                setAmount={(amount) => {
                                    setFieldValue('amount', amount);
                                }}
                                systemManagedDataline={systemManagedDataline}
                                systemManagedEmissionSource={systemManagedEmissionSource}
                                closedYear={closedYear}
                                isAirportCalculatorVisible={isAirportCalculatorVisible}
                                notes={values.notes}
                                setShowNotes={setShowNotes}
                                setDisabledByAirportCalculator={setDisabledByAirportCalculator}
                            />
                        </Grid>
                        {showNotes && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    name="notes"
                                    autoComplete="off"
                                    label="Notes (optional, incl. in Audit Report)"
                                    value={values.notes || ''}
                                    onChange={(ev) => setFieldValue('notes', ev.target.value)}
                                    // notes can be edited in case of systemManagedDataline
                                    disabled={
                                        (!basicModifyDatalinePermission &&
                                            !complexModifyDatalinePermission) ||
                                        closedYear
                                    }
                                />
                            </Grid>
                        )}
                        {showCost && (
                            <Grid item xs={6}>
                                <FloatInput
                                    fullWidth
                                    label="Cost (optional)"
                                    type="number"
                                    autoComplete="off"
                                    value={values.cost}
                                    onChange={(ev) => setFieldValue('cost', ev.target.rawValue)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">$</InputAdornment>
                                        ),
                                    }}
                                    disabled={
                                        (!basicModifyDatalinePermission &&
                                            !complexModifyDatalinePermission) ||
                                        systemManagedDataline ||
                                        systemManagedEmissionSource ||
                                        closedYear
                                    }
                                />
                            </Grid>
                        )}
                        {showAssign && (
                            <Grid item xs={6}>
                                <AutocompleteSelect
                                    initCall={userAutocomplete.init}
                                    autocompleteCall={userAutocomplete.search}
                                    value={values.assigned_user?.user_id}
                                    setValue={(ev) => {
                                        setFieldValue('assigned_user.user_id', ev);
                                    }}
                                    disabled={
                                        !complexModifyDatalinePermission ||
                                        systemManagedDataline ||
                                        systemManagedEmissionSource ||
                                        closedYear
                                    }
                                >
                                    <TextField label="Assigned user" />
                                </AutocompleteSelect>
                            </Grid>
                        )}
                        {showOwner && (
                            <Grid item xs={6}>
                                <AutocompleteSelect
                                    initCall={userAutocomplete.init}
                                    autocompleteCall={userAutocomplete.search}
                                    value={values?.owner_user?.user_id}
                                    setValue={(ev) => {
                                        setFieldValue('owner_user.user_id', ev);
                                    }}
                                    disabled={
                                        !complexModifyDatalinePermission ||
                                        systemManagedDataline ||
                                        systemManagedEmissionSource ||
                                        closedYear
                                    }
                                >
                                    <TextField label="Owner" />
                                </AutocompleteSelect>
                            </Grid>
                        )}
                        {showDate && (
                            <Grid container item xs={12}>
                                <DateRangeInput
                                    monthDateRange={monthDateRange}
                                    setMonthDateRange={setMonthDateRange}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    year={year}
                                    disabled={
                                        (!basicModifyDatalinePermission &&
                                            !complexModifyDatalinePermission) ||
                                        systemManagedDataline ||
                                        systemManagedEmissionSource ||
                                        closedYear
                                    }
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                        )}
                        {showDocuments && (
                            <DocumentField
                                values={values}
                                setFieldValue={setFieldValue}
                                basicModifyDatalinePermission={basicModifyDatalinePermission}
                                complexModifyDatalinePermission={complexModifyDatalinePermission}
                                systemManagedDataline={systemManagedDataline}
                                systemManagedEmissionSource={systemManagedEmissionSource}
                                closedYear={closedYear}
                            />
                        )}
                        {showDatalineTags && (
                            // dataline tags can be edited in case of systemManagedDataline
                            <FreeSoloDatalineTagAutoComplete
                                fullWidth
                                label="Tags"
                                value={values?.datalineTags}
                                setValue={(newValue) => setFieldValue('datalineTags', newValue)}
                                percentages={percentages}
                                setPercentages={setPercentages}
                                basicModifyDatalinePermission={basicModifyDatalinePermission}
                                complexModifyDatalinePermission={complexModifyDatalinePermission}
                                closedYear={closedYear}
                            >
                                <TextField label="Dataline tags" required />
                            </FreeSoloDatalineTagAutoComplete>
                        )}
                        {showSop && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    name="sop"
                                    autoComplete="off"
                                    label="Standard Operating Procedure (optional, internal use only)"
                                    value={values.sop || ''}
                                    onChange={(ev) => setFieldValue('sop', ev.target.value)}
                                    disabled={
                                        (!basicModifyDatalinePermission &&
                                            !complexModifyDatalinePermission) ||
                                        closedYear
                                    }
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {!showNotes &&
                                    (basicModifyDatalinePermission ||
                                        complexModifyDatalinePermission) &&
                                    !closedYear && (
                                        // notes can be edited in case of systemManagedDataline
                                        <AddItemButton
                                            label="Note"
                                            onClick={() => setShowNotes(true)}
                                        />
                                    )}
                                {!showDocuments &&
                                    (basicModifyDatalinePermission ||
                                        complexModifyDatalinePermission) &&
                                    !systemManagedDataline &&
                                    !systemManagedEmissionSource &&
                                    !closedYear && (
                                        <AddItemButton
                                            label="Document"
                                            onClick={() => setShowDocuments(true)}
                                        />
                                    )}
                                {!showAssign &&
                                    complexModifyDatalinePermission &&
                                    !systemManagedDataline &&
                                    !systemManagedEmissionSource &&
                                    !closedYear && (
                                        <AddItemButton
                                            label="Assign"
                                            onClick={() => setShowAssign(true)}
                                        />
                                    )}
                                {!showOwner &&
                                    complexModifyDatalinePermission &&
                                    !systemManagedDataline &&
                                    !systemManagedEmissionSource &&
                                    !closedYear && (
                                        <AddItemButton
                                            label="Owner"
                                            onClick={() => setShowOwner(true)}
                                        />
                                    )}
                                {!showDate &&
                                    (basicModifyDatalinePermission ||
                                        complexModifyDatalinePermission) &&
                                    !systemManagedDataline &&
                                    !systemManagedEmissionSource &&
                                    !closedYear && (
                                        <AddItemButton
                                            label="Dates (from-to)"
                                            onClick={() => setShowDate(true)}
                                        />
                                    )}
                                {!showCost &&
                                    (basicModifyDatalinePermission ||
                                        complexModifyDatalinePermission) &&
                                    !systemManagedDataline &&
                                    !systemManagedEmissionSource &&
                                    !closedYear && (
                                        <AddItemButton
                                            label="Cost"
                                            onClick={() => setShowCost(true)}
                                        />
                                    )}
                                {!showDatalineTags &&
                                    (basicModifyDatalinePermission ||
                                        complexModifyDatalinePermission) &&
                                    !closedYear && (
                                        // dataline tags can be edited in case of systemManagedDataline
                                        <AddItemButton
                                            label="Dataline tags"
                                            onClick={() => setShowDatalineTags(true)}
                                        />
                                    )}
                                {!showSop &&
                                    (basicModifyDatalinePermission ||
                                        complexModifyDatalinePermission) &&
                                    !closedYear && (
                                        <AddItemButton
                                            label="SOP"
                                            onClick={() => setShowSop(true)}
                                        />
                                    )}
                                <Grid item style={{ flex: '1 1 0' }}>
                                    <Grid container spacing={2} justifyContent="flex-end">
                                        <Grid item>
                                            <CommonButton
                                                fullWidth
                                                onClick={() => setEdited(false)}
                                                label="Cancel"
                                                buttonType="secondary"
                                            />
                                        </Grid>
                                        {!closedYear && (
                                            <Grid item>
                                                <CommonButton
                                                    // notes & dataline tags can be edited in case of systemManagedDataline
                                                    fullWidth
                                                    disabled={
                                                        isSubmitting ||
                                                        disabledByAirportCalculator ||
                                                        (!basicModifyDatalinePermission &&
                                                            !complexModifyDatalinePermission)
                                                    }
                                                    type="submit"
                                                    label="Save"
                                                    buttonType="primary"
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {values.modified_user?.user_name && values?.modified_at && (
                            <Grid item xs={12} pt={0}>
                                <LastModified
                                    modifiedUser={values?.modified_user}
                                    modifiedAt={values?.modified_at}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}

export default DatalineForm;
