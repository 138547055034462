import {
    Avatar,
    CircularProgress,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TopicIcon from '@mui/icons-material/Topic';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SubjectIcon from '@mui/icons-material/Subject';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { FORMULA_OPTIONS } from '../../../helpers/defaultFieldHelpers';
import { doSwaggerCall, doSwaggerCallForPdf, uploadImpDocument } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { useAuth } from '../../../hooks/useAuth';
import { BACKEND_URL } from '../../../config';

const TABLE_HEADERS = [
    { name: 'Audit', width: '5%', align: 'center' },
    { name: 'Year', width: '15%' },
    { name: 'State', width: '10%' },
    { name: 'Formula', width: '15%' },
    { name: 'IMP Status', width: '15%', align: 'center' },
    { name: 'Download completed IMP', width: '15%', align: 'center' },
    { name: 'IMP Edited by', width: '10%', align: 'center' },
    { name: 'IMP Actions', width: '15%', align: 'center' },
];

const DELETE_ITEM_LIST = [
    'GHG and ESG Inventory',
    'Inventory Boundaries',
    'Inventory Management Plan',
    'GHG and ESG Targets and Plannings',
    'Stakeholders and Surveys',
];

const YearsOfReportingTable = ({
    values,
    setFieldValue,
    formulaEditorValues,
    setFormulaEditorValues,
    defaultFormula,
    reloadData,
    companyId,
    setCompanyYears,
}) => {
    const { user, setUser } = useAuth();
    const { showModal } = useModals();
    const { notifyError, notifySuccess, notifyInfo } = useNotify();
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const [generatingYear, setGeneratingYear] = useState(null);
    const [generatingAttachment, setGeneratingAttachment] = useState(false);

    const clearImpForm = async (impId) => {
        try {
            await doSwaggerCall('ImpForms', 'clearImpForm', { id: impId });
            notifySuccess('Inventory management plan form cleared');
            reloadData();
        } catch (err) {
            notifyError(err);
        }
    };

    const openImpDocument = async (year, impFormId, excludeBlankSections) => {
        try {
            const impDocument = await doSwaggerCall('ImpForms', 'getImpForm', {
                year,
                companyId,
            });

            // if status is true, and url is null, generate pdf
            if (impDocument.status && !impDocument.url) {
                setGeneratingPdf(true);
                setGeneratingYear(year);
                let reportRequest = null;
                reportRequest = await doSwaggerCallForPdf('Reports', 'impReportRequestPdf', {
                    year,
                    impFormId,
                    excludeBlankSections,
                });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(reportRequest);
                link.download = `inventory_management_plan_${year}.pdf`;
                link.click();
            } else if (impDocument.url) {
                const documentUrl = !impDocument.url
                    ? null
                    : `${BACKEND_URL}/s3proxy?url=${encodeURIComponent(impDocument.url)}`;
                window.open(documentUrl, '_blank');
            } else {
                notifyInfo('No inventory management plan form uploaded');
            }
        } catch (err) {
            notifyError(err);
            setGeneratingPdf(false);
            setGeneratingYear(null);
        } finally {
            setGeneratingPdf(false);
            setGeneratingYear(null);
        }
    };

    const deleteCompanyYear = async (year) => {
        try {
            await doSwaggerCall('CompanyYears', 'deleteCompanyYear', { companyId, year });
            // update available years in user when user is in the same company
            if (user.company_id === parseInt(companyId, 10)) {
                // filter out the deleted year
                setUser({
                    ...user,
                    availableYears: values.companyYears
                        .filter((companyYear) => companyYear.year !== year)
                        .sort((a, b) => a - b),
                });
            }
            notifySuccess('Company year deleted');
            reloadData();
            setCompanyYears((prev) => prev.filter((y) => y !== parseInt(year, 10)));
        } catch (err) {
            notifyError('Cannot delete company year');
        }
    };

    const uploadEventHandler = async (event, impId) => {
        if (event.target.files.length === 0) {
            return;
        }
        const f = event.target.files[0];
        notifyInfo('Uploading inventory management plan form...');
        await uploadImpDocument(f, impId);
        notifyInfo('Inventory management plan form uploaded');
        reloadData();
    };

    const getSurveyAttachment = async (year) => {
        try {
            setGeneratingAttachment(true);
            const impDocument = await doSwaggerCall('ImpForms', 'getImpForm', {
                year,
                companyId,
            });
            if (impDocument.url) {
                const documentUrl = !impDocument.url
                    ? null
                    : `${BACKEND_URL}/s3proxy?url=${encodeURIComponent(impDocument.url)}`;
                window.open(documentUrl, '_blank');
            } else {
                notifyInfo('No inventory management plan form uploaded');
                setGeneratingAttachment(false);
            }
        } catch (err) {
            notifyError(err);
            setGeneratingAttachment(false);
        } finally {
            setGeneratingAttachment(false);
        }
    };

    const downloadImpRow = (impState, year, impId) => {
        let icon = '-';
        let impStateText = 'No inventory management plan form uploaded';
        if (generatingPdf && generatingYear === year) {
            icon = <CircularProgress color="inherit" size={20} />;
            impStateText = 'Generating PDF...';
        } else if (impState === 'Completed') {
            icon = <SubjectIcon />;
            impStateText = 'Download IMP survey';
        } else if (impState === 'Completed via Upload') {
            icon = <TopicIcon />;
            impStateText = 'Download IMP document';
        }
        return (
            <Tooltip title={impStateText} placement="top">
                <IconButton
                    disabled={
                        !impId || generatingPdf || impState === '-' || impState === 'In Progress'
                    }
                    onClick={() =>
                        impStateText === 'Download IMP survey'
                            ? showModal(MODAL_TYPE.CONFIRMATION_IMP_DOWNLOAD, {
                                  title: 'Download IMP Form',
                                  content: 'Please select the options below.',
                                  confirm: (excludeBlankSections) =>
                                      openImpDocument(year, impId, excludeBlankSections),
                              })
                            : openImpDocument(year, impId)
                    }
                >
                    {icon}
                </IconButton>
            </Tooltip>
        );
    };

    function getUploadedByProfilePictureUrl(uploadedByProfilePicture) {
        return !uploadedByProfilePicture
            ? null
            : `${BACKEND_URL}/s3proxy?url=${encodeURIComponent(uploadedByProfilePicture)}`;
    }

    return (
        <Table size="small" sx={{ mt: 1 }}>
            <TableHead>
                <TableRow>
                    {TABLE_HEADERS.map(({ name, width, align }, idx) => (
                        <TableCell key={idx} style={{ width, textAlign: align }}>
                            {name}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {values.companyYears.map(
                    (
                        {
                            yearId,
                            year,
                            state,
                            disabled,
                            impId,
                            impState,
                            uploadedBy,
                            audited,
                            auditUrl,
                        },
                        idx
                    ) => (
                        <TableRow key={idx}>
                            <TableCell align="center">
                                <Tooltip title={audited ? 'Audited' : 'Not audited yet'}>
                                    <IconButton
                                        disabled={!yearId}
                                        onClick={() => {
                                            showModal(MODAL_TYPE.AUDIT_COMPANY_YEAR, {
                                                title: `Audit company year ${year}`,
                                                content: `Here you can audit the company year for ${year}. This action will close the year permanently.`,
                                                auditUrl,
                                                audited,
                                                year,
                                                yearId,
                                                companyId,
                                                reloadData: () => reloadData(),
                                            });
                                        }}
                                    >
                                        {audited ? (
                                            <CheckIcon color="success" />
                                        ) : (
                                            <CloseIcon color="error" />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    autoComplete="off"
                                    type="number"
                                    value={year}
                                    required
                                    onChange={(ev) => {
                                        setFieldValue(`companyYears[${idx}]`, {
                                            year: ev.target.value,
                                            state,
                                            disabled,
                                        });
                                    }}
                                    disabled={disabled}
                                />
                            </TableCell>
                            <TableCell>
                                <FormControl fullWidth>
                                    {audited ? (
                                        <Tooltip title="Audited years cannot be modified">
                                            <Select value={state} disabled>
                                                <MenuItem value="closed">closed</MenuItem>
                                            </Select>
                                        </Tooltip>
                                    ) : (
                                        <Select
                                            value={state}
                                            required
                                            onChange={(ev) => {
                                                setFieldValue(`companyYears[${idx}]`, {
                                                    year,
                                                    state: ev.target.value,
                                                    disabled,
                                                });
                                            }}
                                        >
                                            <MenuItem value="open">open</MenuItem>
                                            <MenuItem value="closed">closed</MenuItem>
                                        </Select>
                                    )}
                                </FormControl>
                            </TableCell>
                            <TableCell>
                                <FormControl fullWidth>
                                    {audited ? (
                                        <Tooltip title="Audited years cannot be modified">
                                            <Select
                                                value={formulaEditorValues[year] || defaultFormula}
                                                disabled
                                            >
                                                {FORMULA_OPTIONS.map((formula) => (
                                                    <MenuItem key={formula} value={formula}>
                                                        {defaultFormula === formula
                                                            ? 'Default'
                                                            : formula}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Tooltip>
                                    ) : (
                                        <Select
                                            value={formulaEditorValues[year] || defaultFormula}
                                            required
                                            onChange={(ev) => {
                                                setFormulaEditorValues({
                                                    ...formulaEditorValues,
                                                    [year]: ev.target.value,
                                                });
                                            }}
                                        >
                                            {FORMULA_OPTIONS.map((formula) => (
                                                <MenuItem key={formula} value={formula}>
                                                    {defaultFormula === formula
                                                        ? 'Default'
                                                        : formula}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                </FormControl>
                            </TableCell>
                            <TableCell align="center">
                                <Typography>{impState}</Typography>
                            </TableCell>
                            <TableCell align="center">
                                {downloadImpRow(impState, year, impId)}
                            </TableCell>
                            <TableCell>
                                {uploadedBy?.id && (
                                    <Tooltip title={uploadedBy.name} placement="left">
                                        <Avatar
                                            style={{
                                                height: '35px',
                                                width: '35px',
                                                marginLeft: '35px',
                                            }}
                                            src={getUploadedByProfilePictureUrl(
                                                uploadedBy?.profile_picture
                                            )}
                                        >
                                            {uploadedBy.name.substr(0, 1)?.toUpperCase()}
                                        </Avatar>
                                    </Tooltip>
                                )}
                            </TableCell>
                            <TableCell align="center">
                                <Tooltip title="Upload IMP">
                                    <IconButton disabled={!impId}>
                                        <input
                                            style={{
                                                opacity: 0,
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                            }}
                                            type="file"
                                            onChange={(event) =>
                                                uploadEventHandler(event, impId).catch(console.log)
                                            }
                                        />
                                        <FileUploadIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Download attachment">
                                    <IconButton
                                        disabled={generatingAttachment}
                                        onClick={() => getSurveyAttachment(year)}
                                    >
                                        <FileDownloadIcon />
                                    </IconButton>
                                </Tooltip>
                                <IconButton
                                    disabled={!impId}
                                    onClick={() => {
                                        showModal(MODAL_TYPE.COPY_IMP, {
                                            title: 'Copy Inventory Management Plan Form',
                                            content:
                                                'This action is irreversible and it will override the current answers.',
                                            year,
                                            availableYears: values.companyYears.map((companyYear) =>
                                                parseInt(companyYear.year, 10)
                                            ),
                                            impId,
                                            reloadData: () => reloadData(),
                                        });
                                    }}
                                >
                                    <Tooltip title="Copy IMP">
                                        <ContentCopyIcon />
                                    </Tooltip>
                                </IconButton>
                                <IconButton
                                    disabled={!impId}
                                    onClick={() => {
                                        showModal(MODAL_TYPE.CONFIRMATION, {
                                            title: 'Are you sure you want to clear this IMP?',
                                            content:
                                                'This action is irreversible and will clear the IMP and the uploaded file as well.',
                                            confirm: () => clearImpForm(impId),
                                        });
                                    }}
                                >
                                    <Tooltip title="Clear IMP">
                                        <DeleteSweepIcon />
                                    </Tooltip>
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    disabled={!impId}
                                    onClick={() => {
                                        showModal(MODAL_TYPE.CONFIRMATION_DELETE, {
                                            title: 'Are you sure you want to delete this company year?',
                                            warningContent: (
                                                <>
                                                    <Typography sx={{ mb: 1 }} variant="body2">
                                                        This action is irreversible and will delete
                                                        the following data for the year:
                                                    </Typography>
                                                    {DELETE_ITEM_LIST.map((item, index) => (
                                                        <Typography variant="body2" key={index}>
                                                            <li key={index}>{item}</li>
                                                        </Typography>
                                                    ))}
                                                </>
                                            ),
                                            confirm: () => deleteCompanyYear(year),
                                        });
                                    }}
                                >
                                    <Tooltip title="Delete company year">
                                        <DeleteIcon />
                                    </Tooltip>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )
                )}
                <TableRow>
                    <TableCell colSpan={TABLE_HEADERS.length + 1}>
                        <IconButton
                            sx={{ float: 'right' }}
                            onClick={() => {
                                setFieldValue('companyYears', [
                                    ...values.companyYears,
                                    {
                                        year: '',
                                        state: 'open',
                                        disabled: false,
                                    },
                                ]);
                            }}
                        >
                            <Add />
                        </IconButton>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default YearsOfReportingTable;
