import {
    Checkbox,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeselectIcon from '@mui/icons-material/Deselect';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import { convertUnitGToKg, formatNumber } from '../../../helpers/convertUnits';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';
import MultipleLineSelect from '../../../components/atom/MultipleLineSelect';
import TableSelectDropdown from './TableSelectDropdown';
import { TABLE_HEADERS, checkClosedStatus, isNetZero } from './helpers/tableHelpers';

const SuppliersTable = ({
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    reloadData,
    sort,
    sortDir,
    setSorting,
    year,
    setSuppliersChanged,
}) => {
    const { showModal } = useModals();
    const { notifyError, notifySuccess } = useNotify();
    const [selectMode, setSelectMode] = useState(false);
    const navigate = useNavigate();
    const rowsIdList = rows?.map((r) => r.id);
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleSelectAll = (ev) => {
        if (ev.target.checked) {
            const newSelectedIds = rows.map((supplier) => supplier.id);
            setSelectedSuppliers(newSelectedIds);
            return;
        }
        setSelectedSuppliers([]);
    };

    const deleteSupplier = useCallback((id) => {
        setSuppliersChanged(false);
        doSwaggerCall('Suppliers', 'deleteSuppliersByList', { idList: [id] })
            .then(() => {
                notifySuccess('Stakeholder deleted');
                reloadData();
                setSuppliersChanged(true);
            })
            .catch((err) => {
                notifyError(err);
            });
    }, []);

    const sendSurveyIdList = async (supplierIdList, selectedCustomQuestions, isGhg) => {
        try {
            const customQuestionIds =
                selectedCustomQuestions.length > 0 ? selectedCustomQuestions.map((q) => q.id) : [];
            for (let i = 0; i < supplierIdList.length; i++) {
                // eslint-disable-next-line no-await-in-loop
                await doSwaggerCall(
                    'Surveys',
                    'sendSurvey',
                    {},
                    {
                        supplierId: supplierIdList[i],
                        customQuestionIds,
                        isGhg,
                    }
                );
            }
            notifySuccess('The survey(s) has been successfully sent');
            reloadData();
        } catch (error) {
            notifyError(error);
        }
    };

    const openSurvey = async (supplierId) => {
        try {
            const response = await doSwaggerCall(
                'Surveys',
                'sendSurvey',
                {},
                { supplierId, forAdmin: true }
            );
            window.open(response.url, '_blank');
        } catch (error) {
            notifyError(error);
        }
    };

    const getContactsData = (selectedIds) => {
        const data = rows
            .filter((row) => selectedIds.includes(row.id))
            .map((row) => ({
                name: row.name,
                contacts: row.contacts,
            }));
        return data;
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            {selectMode && (
                                <TableCell>
                                    <Checkbox
                                        checked={
                                            rowsIdList?.length > 0 &&
                                            selectedSuppliers.length === rowsIdList.length
                                        }
                                        onChange={(ev) => handleSelectAll(ev)}
                                        indeterminate={
                                            selectedSuppliers.length > 0 &&
                                            selectedSuppliers.length < rowsIdList.length
                                        }
                                    />
                                </TableCell>
                            )}
                            {TABLE_HEADERS.map((header) =>
                                header.sortable !== false ? (
                                    <SortableTableCell
                                        key={header.id}
                                        arrow={sort === header.id ? sortDir : ''}
                                        onClick={() => setSorting(header.id)}
                                    >
                                        {header.label}
                                    </SortableTableCell>
                                ) : (
                                    <TableCell key={header.id}>{header.label}</TableCell>
                                )
                            )}
                            <TableCell align="right">
                                {!selectMode ? (
                                    <MultipleLineSelect setSelectMode={setSelectMode} />
                                ) : (
                                    <div>
                                        <Tooltip title="Cancel selection">
                                            <IconButton
                                                onClick={() => {
                                                    setSelectMode(false);
                                                }}
                                            >
                                                <DeselectIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Select action">
                                            <IconButton onClick={handleClick}>
                                                <MoreHorizIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <TableSelectDropdown
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            reloadData={reloadData}
                                            selectedSuppliers={selectedSuppliers}
                                            setSelectedSuppliers={setSelectedSuppliers}
                                            setSuppliersChanged={setSuppliersChanged}
                                            sendSurveyIdList={sendSurveyIdList}
                                            year={year}
                                            rows={rows}
                                            getContactsData={getContactsData}
                                            limit={limit}
                                            page={page}
                                            setParams={setParams}
                                        />
                                    </div>
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <SkeletonTable rows={limit} cells={selectMode ? 12 : 11} />
                        ) : (
                            rows?.map((row) => (
                                <TableRow key={row.id}>
                                    {selectMode && (
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedSuppliers.includes(row.id)}
                                                onChange={() =>
                                                    setSelectedSuppliers(
                                                        selectedSuppliers.includes(row.id)
                                                            ? selectedSuppliers.filter(
                                                                  (e) => e !== row.id
                                                              )
                                                            : [...selectedSuppliers, row.id]
                                                    )
                                                }
                                            />
                                        </TableCell>
                                    )}
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.industry}</TableCell>
                                    <TableCell>{row.type}</TableCell>
                                    <TableCell>{row.locationName}</TableCell>
                                    <TableCell>
                                        {formatNumber(row.co2e) === '?'
                                            ? '?'
                                            : formatNumber(convertUnitGToKg(row.co2e) / 1000)}
                                    </TableCell>
                                    <TableCell width="5%">{isNetZero(row.netzero)}</TableCell>
                                    <TableCell>
                                        {row.perScope
                                            ?.filter((s) => s.co2e > 0)
                                            .map((scope) => scope.scopeId)
                                            .join(', ')}
                                    </TableCell>
                                    <TableCell>{checkClosedStatus(row)}</TableCell>
                                    <TableCell>
                                        {row.certifications?.map((cert) => (
                                            <div key={cert}>{cert}</div>
                                        ))}
                                    </TableCell>
                                    <TableCell>
                                        {row.supplierTags.map((tag) => tag.name).join(', ')}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Tooltip title="Send survey">
                                            <IconButton
                                                onClick={() => {
                                                    showModal(MODAL_TYPE.CONFIRMATION_SEND_SURVEY, {
                                                        title: 'Are you sure you want to send survey?',
                                                        contactsData: getContactsData([row.id]),
                                                        limit,
                                                        page,
                                                        setParams,
                                                        confirm: (selectedCustomQuestions, isGhg) =>
                                                            sendSurveyIdList(
                                                                [row.id],
                                                                selectedCustomQuestions,
                                                                isGhg
                                                            ),
                                                    });
                                                }}
                                            >
                                                <SendIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Edit">
                                            <Link to={`/suppliers/edit/${row.id}`}>
                                                <IconButton>
                                                    <EditIcon />
                                                </IconButton>
                                            </Link>
                                        </Tooltip>
                                        <Tooltip title="Copy Stakeholder">
                                            <IconButton
                                                onClick={() => {
                                                    showModal(MODAL_TYPE.COPY_SUPPLIER, {
                                                        title: 'Copy and add as a new Stakeholder',
                                                        rows: [row],
                                                        year,
                                                        reloadData,
                                                    });
                                                }}
                                            >
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="View survey">
                                            <span>
                                                <IconButton
                                                    disabled={!row.savedSurvey}
                                                    onClick={() => {
                                                        navigate(`/surveydetails/${row.id}`);
                                                    }}
                                                >
                                                    <RemoveRedEyeIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip title="Open survey">
                                            <span>
                                                <IconButton
                                                    disabled={!row.surveyId}
                                                    onClick={() => {
                                                        openSurvey(row.id);
                                                    }}
                                                >
                                                    <OpenInNewIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <IconButton
                                                onClick={() => {
                                                    showModal(MODAL_TYPE.CONFIRMATION, {
                                                        title: 'Are you sure you want to delete this Stakeholder?',
                                                        content: 'This action is irreversible.',
                                                        warningContent:
                                                            'Datalines created by this Stakeholder will be deleted if the year is not closed.',
                                                        confirm: () => deleteSupplier(row.id),
                                                    });
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default SuppliersTable;
