import { Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React from 'react';
import { useDarkMode } from '../../hooks/useDarkMode';
import { useGuideBoxSidebar } from '../../hooks/useGuideBoxSidebar';

function formatValue(value) {
    const fixedValue = parseFloat(value).toFixed(12);
    const replacedValue = fixedValue
        .toString()
        .replace(/^([\d,]+)$|^([\d,]+)\.0*$|^([\d,]+\.[0-9]*?)0*$/, '$1$2$3');
    return replacedValue;
}

function generateContent(guideNote, factors) {
    let content = '';

    if (guideNote && factors) {
        content = `${guideNote}\n\nFactors - ${factors[0].year}:\n`;
    } else if (guideNote) {
        return guideNote;
    } else if (factors) {
        content = `Factors - ${factors[0].year}:\n`;
    } else {
        return 'No guidance available';
    }

    factors.forEach((factor) => {
        content += `Location: ${factor.location_name}\n`;
        content += `CO2 factor kg / base unit: ${formatValue(factor.co2_factor / 1000)}\n`;
        content += `CH4 factor g / base unit: ${formatValue(factor.ch4_factor)}\n`;
        content += `N2O factor g / base unit: ${formatValue(factor.n2o_factor)}\n`;
        content += `Source: ${factor.source}\n\n`;
    });

    return content;
}

const HelperIcon = ({ title, guideNote, factors = null }) => {
    const { darkMode } = useDarkMode();
    const { openSidebar } = useGuideBoxSidebar();

    return (
        <sup>
            <Tooltip
                title="Click here to open guidance"
                sx={{ cursor: 'pointer' }}
                placement="right"
                onClick={() => openSidebar({ title, content: generateContent(guideNote, factors) })}
            >
                <HelpOutlineIcon
                    style={{
                        fontSize: '15px',
                        color: !darkMode ? 'grey' : 'white',
                    }}
                />
            </Tooltip>
        </sup>
    );
};

export default HelperIcon;
