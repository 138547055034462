import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import {
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    CircularProgress,
    Tooltip,
    IconButton,
    Divider,
    Button,
} from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import FilterListIcon from '@mui/icons-material/FilterList';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PageWithHeader from '../../../components/header/PageWithHeader';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import AutocompleteSelect from '../../../components/atom/AutoCompleteSelect';
import CommonButton from '../../../components/atom/CommonButton';
import useNotify from '../../../hooks/useNotify';
import { siteAutocomplete } from '../../../autocomplete';
import { doSwaggerCall } from '../../../hooks/useApi';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import ContactEditor from '../../suppliers/components/ContactEditor';
import validateIntegrations from '../helpers/validateIntegrations';
import FloatInput from '../../../components/atom/FloatInput';

const SYNC_PERIODS = {
    MONTHLY: 'monthly',
    QUARTERLY: 'quarterly',
    ANNUALLY: 'annually',
    NONE: 'none',
};

const INTEGRATION_TYPES = {
    DECK: 'deck',
    // FEDEX: 'fedex',
};

const defaultIntegration = {
    name: 'New name',
    site_id: null,
    sync_period: SYNC_PERIODS.MONTHLY,
    type: INTEGRATION_TYPES.DECK,
    from_date: null,
    to_date: null,
    contacts: [],
    settings: {},
};

const EditIntegraionPage = ({ newIntegration }) => {
    const navigate = useNavigate();
    const { integrationId } = useParams();
    const { showModal } = useModals();
    const { notifyError, notifySuccess } = useNotify();
    const [integration, setIntegration] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingSync, setLoadingSync] = useState(false);
    const [loadingManualSync, setLoadingManualSync] = useState(false);
    const [services, setServices] = useState({});

    const loadIntegration = useCallback(() => {
        setLoading(true);
        doSwaggerCall('Integrations', 'getIntegration', { id: integrationId })
            .then((res) => {
                setIntegration({
                    id: res.id,
                    site_id: res.site_id === null ? 0 : res.site_id,
                    site_name: res.site_name,
                    company_id: res.company_id,
                    name: res.name,
                    service_type: res.service_type,
                    contacts: res.contacts.map((contact) => ({
                        id: `${Math.random()}`.substring(2),
                        name: contact.name,
                        email: contact.email,
                        phone: contact.phone,
                    })),
                    settings: res.settings,
                    from_date: res.settings?.date_range?.from_date,
                    to_date: res.settings?.date_range?.to_date,
                    sync_period: res.sync_period,
                    type: res.type,
                    access_key: res.access_key,
                    notes: res.notes,
                });
                setServices(res.service_type);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                navigate('/integrations');
                notifyError(err);
            });
    }, []);

    const onEdit = (values) => {
        showModal(MODAL_TYPE.CONFIRMATION, {
            title: `Are you sure you want to edit this integration?`,
            confirm: () => {
                let dateRange;
                if (values.from_date && values.to_date) {
                    dateRange = { from_date: values.from_date, to_date: values.to_date };
                }

                // create a matrix - it's value will be true if a multiplier is not a number
                const validMultipliers = Object.entries(services).map((s) =>
                    // eslint-disable-next-line no-restricted-globals
                    s[1].multipliers.map((m) => isNaN(m))
                );

                // check if the matrix contains any true value
                if (!validMultipliers.some((row) => row.some((value) => value))) {
                    doSwaggerCall(
                        'Integrations',
                        'editIntegration',
                        { id: integrationId },
                        {
                            name: values.name,
                            siteId: values.site_id,
                            type: values.type,
                            settings: dateRange
                                ? { ...values.settings, date_range: { ...dateRange } }
                                : values.settings,
                            syncPeriod: values.sync_period,
                            serviceType: services, // from sync
                            contacts: values.contacts.map((contact) => ({
                                name: contact.name,
                                email: contact.email,
                                phone: contact.phone,
                            })),
                            notes: values.notes,
                        }
                    )
                        .then(() => {
                            loadIntegration();
                            navigate('/integrations');
                            notifySuccess('Integration updated');
                        })
                        .catch((err) => {
                            notifyError(err);
                        });
                } else {
                    notifyError('Multiplier should be number');
                }
            },
        });
    };

    const onSubmit = async (values) => {
        if (!newIntegration) {
            return onEdit(values);
        }
        try {
            let dateRange;
            if (values.from_date && values.to_date) {
                dateRange = { from_date: values.from_date, to_date: values.to_date };
            }
            await doSwaggerCall(
                'Integrations',
                'addIntegration',
                {},
                {
                    name: values.name,
                    siteId: values.site_id,
                    type: values.type,
                    settings: dateRange
                        ? { ...values.settings, date_range: { ...dateRange } }
                        : values.settings,
                    syncPeriod: values.sync_period,
                    contacts: values.contacts.map((contact) => ({
                        name: contact.name,
                        email: contact.email,
                        phone: contact.phone,
                    })),
                    notes: values.notes,
                }
            );
            navigate('/integrations');
            notifySuccess('Integration added');
        } catch (error) {
            notifyError(error);
        }
    };

    useEffect(() => {
        if (!newIntegration) {
            loadIntegration();
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        return true;
    }

    const validate = (values) => {
        return validateIntegrations(values);
    };

    const handleDelete = (source, key) => {
        setServices({
            ...services,
            [key]: {
                ...services[key],
                emissionSources: services[key].emissionSources.filter((s) => s.id !== source.id),
                multipliers: services[key].multipliers.filter(
                    (_, index) => services[key].emissionSources[index].id !== source.id
                ),
            },
        });
    };

    const isCorrectSync = () => {
        return Object.values(integration?.service_type).some(
            (v) => v?.emissionSources?.length > 0 && v?.multipliers?.length > 0
        );
    };

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={integration || { ...defaultIntegration }}
            validate={validate}
        >
            {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form>
                    <PageWithHeader
                        title={newIntegration ? 'Add new Integration' : `${integration.name}`}
                    >
                        <Grid item xs={12}>
                            <BlockWithTitle title="Integration">
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        autoComplete="off"
                                        required
                                        value={values.name}
                                        onChange={(ev) => {
                                            setFieldValue('name', ev.target.value);
                                        }}
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name ? errors.name : ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AutocompleteSelect
                                        size="small"
                                        initCall={siteAutocomplete.init}
                                        autocompleteCall={siteAutocomplete.search}
                                        value={values.site_id}
                                        setValue={(ev) => {
                                            setFieldValue('site_id', ev);
                                        }}
                                    >
                                        <TextField label="Site" required />
                                    </AutocompleteSelect>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Type</InputLabel>
                                        <Select
                                            label="Type"
                                            value={values.type}
                                            onChange={(ev) =>
                                                setFieldValue('type', ev.target.value)
                                            }
                                            error={touched.type && Boolean(errors.type)}
                                            helperText={touched.type ? errors.type : ''}
                                        >
                                            {Object.keys(INTEGRATION_TYPES).map((key) => (
                                                <MenuItem value={INTEGRATION_TYPES[key]}>
                                                    {INTEGRATION_TYPES[key]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <FormControl fullWidth sx={{ flexGrow: 1 }}>
                                        <InputLabel>Sync period</InputLabel>
                                        <Select
                                            label="Sync period"
                                            value={values.sync_period}
                                            onChange={(ev) => {
                                                setFieldValue('sync_period', ev.target.value);
                                            }}
                                            error={
                                                touched.sync_period && Boolean(errors.sync_period)
                                            }
                                            helperText={
                                                touched.sync_period ? errors.sync_period : ''
                                            }
                                        >
                                            {Object.keys(SYNC_PERIODS).map((key) => (
                                                <MenuItem key={key} value={SYNC_PERIODS[key]}>
                                                    {SYNC_PERIODS[key]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Tooltip title="Run integration manually">
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                showModal(MODAL_TYPE.CONFIRMATION, {
                                                    title: 'Are you sure you want to run this integration manually?',
                                                    warningContent:
                                                        'Closed years will be excluded from the sync.',
                                                    content: 'This action is irreversible',
                                                    confirm: () => {
                                                        setLoadingManualSync(true);
                                                        doSwaggerCall(
                                                            'Integrations',
                                                            'importFromDeckManually',
                                                            {
                                                                id: integrationId,
                                                            }
                                                        )
                                                            .then(() => {
                                                                notifySuccess(
                                                                    'Integration synchronized manually'
                                                                );
                                                                setLoadingManualSync(false);
                                                            })
                                                            .catch((err) => {
                                                                notifyError(err);
                                                                setLoadingManualSync(false);
                                                            });
                                                    },
                                                });
                                            }}
                                            disabled={
                                                !integration?.access_key ||
                                                !isCorrectSync() ||
                                                loadingManualSync
                                            }
                                            sx={{
                                                ml: 2,
                                                minWidth: 36,
                                                height: 36,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {loadingManualSync ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : (
                                                <PlayCircleOutlineIcon />
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                {/* remove from first iteration - since deck only support 12/24 month of data option */}
                                {/*  <Grid item xs={6}>
                                    <InputLabel>From date</InputLabel>
                                    <TextField
                                        fullWidth
                                        type="date"
                                        id="from_date"
                                        name="from_date"
                                        value={values.from_date}
                                        onChange={(ev) =>
                                            setFieldValue('from_date', ev.target.value)
                                        }
                                        error={touched.from_date && Boolean(errors.from_date)}
                                        helperText={touched.from_date ? errors.from_date : ''}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel>To date</InputLabel>
                                    <TextField
                                        fullWidth
                                        type="date"
                                        id="to_date"
                                        name="to_date"
                                        value={values.to_date}
                                        onChange={(ev) => setFieldValue('to_date', ev.target.value)}
                                        error={touched.to_date && Boolean(errors.to_date)}
                                        helperText={touched.to_date ? errors.to_date : ''}
                                    />
                                </Grid> */}
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        name="notes"
                                        autoComplete="off"
                                        label="Notes (optional)"
                                        value={values.notes || ''}
                                        onChange={(ev) => setFieldValue('notes', ev.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <BlockWithTitle title="Contacts">
                                        <Grid item xs={12}>
                                            <ContactEditor
                                                values={values}
                                                setFieldValue={setFieldValue}
                                            />
                                        </Grid>
                                    </BlockWithTitle>
                                </Grid>
                                {!newIntegration && (
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            startIcon={
                                                loadingSync ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : (
                                                    <SyncIcon />
                                                )
                                            }
                                            disabled={
                                                integration?.access_key === null || loadingSync
                                            }
                                            onClick={() => {
                                                setLoadingSync(true);
                                                doSwaggerCall('Integrations', 'syncIntegration', {
                                                    id: integrationId,
                                                })
                                                    .then((res) => {
                                                        setServices(res.serviceType);
                                                        setLoadingSync(false);
                                                    })
                                                    .catch((err) => {
                                                        notifyError(err);
                                                        setLoadingSync(false);
                                                    });
                                            }}
                                        >
                                            Connect service types to emission sources
                                        </Button>
                                    </Grid>
                                )}
                                {Object.entries(services)?.length !== 0 && (
                                    <>
                                        {Object.entries(services).map(([key, serviceValues]) => (
                                            <>
                                                <Grid item xs={12} sx={{ mt: 3 }}>
                                                    <Divider>
                                                        <Typography variant="h5">
                                                            {key} [{serviceValues.unit}]
                                                        </Typography>
                                                    </Divider>
                                                </Grid>
                                                {services[key].emissionSources?.length !== 0 && (
                                                    <Grid item xs={6}>
                                                        <Typography variant="body3">
                                                            Emission sources
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                <Grid
                                                    item
                                                    xs={
                                                        services[key].emissionSources?.length !== 0
                                                            ? 6
                                                            : 12
                                                    }
                                                    sx={{ textAlign: 'end' }}
                                                >
                                                    <Tooltip
                                                        title="Select emission sources"
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            disabled={
                                                                integration?.access_key === null ||
                                                                loadingSync
                                                            }
                                                            onClick={() => {
                                                                showModal(
                                                                    MODAL_TYPE.CONNECT_SERVICE_TYPES_TO_EMISSIONS,
                                                                    {
                                                                        title: `Connect ${key} to emission sources`,
                                                                        serviceType: key,
                                                                        services,
                                                                        setServices,
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <FilterListIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                                {services[key].emissionSources.map((e) => (
                                                    <Grid
                                                        container
                                                        item
                                                        xs={12}
                                                        sx={{ alignItems: 'center' }}
                                                    >
                                                        <Grid item xs={1}>
                                                            <Tooltip title="Remove emission source">
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() => {
                                                                        handleDelete(e, key);
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography>{e.name}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <FloatInput
                                                                sx={{ pb: 2 }}
                                                                fullWidth
                                                                label="Multiplier"
                                                                value={
                                                                    serviceValues.multipliers[
                                                                        services[
                                                                            key
                                                                        ].emissionSources.indexOf(e)
                                                                    ] || 1
                                                                }
                                                                positiveOnly={false}
                                                                onChange={(ev) => {
                                                                    setServices({
                                                                        ...services,
                                                                        [key]: {
                                                                            ...serviceValues,
                                                                            multipliers:
                                                                                serviceValues.multipliers.map(
                                                                                    (m, i) =>
                                                                                        i ===
                                                                                        services[
                                                                                            key
                                                                                        ].emissionSources.indexOf(
                                                                                            e
                                                                                        )
                                                                                            ? ev
                                                                                                  .target
                                                                                                  .rawValue
                                                                                            : m
                                                                                ),
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </>
                                        ))}
                                    </>
                                )}
                                <Grid container>
                                    <Grid item xs={12} />
                                    <Grid item container xs={12} justifyContent="flex-end">
                                        <Grid item xs={2} md={2}>
                                            <CommonButton
                                                fullWidth
                                                onClick={() => navigate('/integrations')}
                                                label="Cancel"
                                                buttonType="secondary"
                                            />
                                        </Grid>
                                        <Grid item xs={2} md={2}>
                                            <CommonButton
                                                fullWidth
                                                disabled={isSubmitting}
                                                type="submit"
                                                label="Save"
                                                buttonType="primary"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </BlockWithTitle>
                        </Grid>
                    </PageWithHeader>
                </Form>
            )}
        </Formik>
    );
};

export default EditIntegraionPage;
