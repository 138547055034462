import { read, utils } from 'xlsx';
import { doSwaggerCall } from '../hooks/useApi';
import { MONTHS } from './constans';

// Parse xlsx sheets
const parseXlsxSheets = (file, unitConversionLogics) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
            const bufferArray = e?.target.result;
            const wb = read(bufferArray, { type: 'buffer' });

            // [ { sheet: 'name', data: [...] } ]
            const result = [];
            for (let i = 0; i < wb.SheetNames.length; i++) {
                let replacedSheetName = wb.SheetNames[i];
                // excel sheet name cannot contain '/' and cannot exceed 31 chars, so:

                // if the original conversion logic name was longer than 31 chars and we have duplicate logic names, need some extra changes
                const truncatedLogicNames = unitConversionLogics.map((l) =>
                    l.name.substring(0, 31)
                );
                const duplicatedLogicNames = truncatedLogicNames.filter(
                    (item, index) => truncatedLogicNames.indexOf(item) !== index
                );
                if (duplicatedLogicNames.length > 0) {
                    // replacedSheetName, remove first word, because that was shortened by initial letters
                    replacedSheetName = replacedSheetName.split(' ').splice(1).join(' ');
                }

                // if the original conversion logic name was longer than 31 chars, we truncated it, so we need to find the original name
                if (replacedSheetName.length === 31 || duplicatedLogicNames.length > 0) {
                    const foundConversionLogic = unitConversionLogics.find((logic) =>
                        logic.name.includes(replacedSheetName)
                    );
                    if (foundConversionLogic) {
                        replacedSheetName = foundConversionLogic.name;
                    }
                }
                const sheetData = utils.sheet_to_json(wb.Sheets[wb.SheetNames[i]], {
                    raw: false, // Keep dates as Date objects
                });
                // check empty sheets
                if (sheetData.length === 1) {
                    let emptyRow = true;
                    Object.entries(sheetData[0]).forEach((attr) => {
                        if (attr[1] !== '') {
                            emptyRow = false;
                        }
                    });
                    if (emptyRow) {
                        continue;
                    }
                }
                result.push({
                    name: replacedSheetName,
                    data: sheetData,
                });
            }
            return resolve(result);
        };
    });
};

// Handle import
export default async function handleImport(
    file,
    emissionSource,
    year,
    siteIdList,
    dryRun,
    unitConversionLogics
) {
    const xlsxFile = await parseXlsxSheets(file, unitConversionLogics);
    const allImportLines = xlsxFile
        .map((sheet) => {
            // skip Sites, Users and Tags sheets, these are just helper sheets
            if (sheet.name === 'Sites' || sheet.name === 'Users' || sheet.name === 'Tags') {
                return [];
            }
            return [
                ...sheet.data.map((item) => {
                    if (item.month) {
                        // add from_date and to_date for monthly data (capitalize first letter of month)
                        const monthIndex = MONTHS.findIndex(
                            (m) => m === item.month.charAt(0).toUpperCase() + item.month.slice(1)
                        );
                        if (monthIndex !== -1) {
                            const firstDay = new Date(year, monthIndex, 1);
                            const lastDay = new Date(year, monthIndex + 1, 0);
                            const formatDate = (date) =>
                                `${year}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
                                    date.getDate()
                                ).padStart(2, '0')}`;
                            item.from_date = formatDate(firstDay);
                            item.to_date = formatDate(lastDay);
                        }
                    }
                    return {
                        ...item,
                        calculationName: sheet.name,
                    };
                }),
            ];
        })
        .reduce((all, item) => [...all, ...item], []);
    // Import in 3 steps: check data (dryRun) - show data on confirmation modal - run import (!dryRun)
    const importedDatalines = await doSwaggerCall(
        'Datalines',
        'importDatalines',
        { emissionSourceId: emissionSource, year, dryRun },
        { allImportLines }
    );
    return importedDatalines;
}
