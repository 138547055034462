/*
 * This file contains the esg unit conversion options
 */

export const jsonConstants = [
    {
        type: 'mass',
        name: 'Gigatonne',
        oneInBaseValue: 1000000000000000, // basevalue is gramm
    },
    {
        type: 'mass',
        name: 'Megatonne',
        oneInBaseValue: 1000000000000,
    },
    {
        type: 'mass',
        name: 'Kilogram',
        oneInBaseValue: 1000,
    },
    {
        type: 'mass',
        name: 'Miligram',
        oneInBaseValue: 0.001,
    },
    {
        type: 'mass',
        name: 'Microgram',
        oneInBaseValue: 0.000001,
    },
    {
        type: 'mass',
        name: 'Nanogram',
        oneInBaseValue: 0.000000001,
    },
    {
        type: 'mass',
        name: 'Picogram',
        oneInBaseValue: 0.000000000001,
    },
    {
        type: 'mass',
        name: 'US ton',
        oneInBaseValue: 907184.74,
    },
    {
        type: 'mass',
        name: 'Metric ton',
        oneInBaseValue: 1000000,
    },
    {
        type: 'mass',
        name: 'Pound',
        oneInBaseValue: 453.59237,
    },
    {
        type: 'mass',
        name: 'Stone',
        oneInBaseValue: 6350.29318,
    },
    {
        type: 'mass',
        name: 'Ounce',
        oneInBaseValue: 28.3495231,
    },
    {
        type: 'mass',
        name: 'UK ton',
        oneInBaseValue: 1016046.91,
    },
    {
        type: 'volume',
        name: 'Cubic decimeter',
        oneInBaseValue: 1, // basevalue is liter
    },
    {
        type: 'volume',
        name: 'Deciliter',
        oneInBaseValue: 0.1,
    },
    {
        type: 'volume',
        name: 'Mililiter',
        oneInBaseValue: 0.001,
    },
    {
        type: 'volume',
        name: 'Cubic Centimeter',
        oneInBaseValue: 0.001,
    },
    {
        type: 'volume',
        name: 'Centiliter',
        oneInBaseValue: 0.01,
    },
    {
        type: 'volume',
        name: 'Cubic Nanometer',
        oneInBaseValue: 1e-24,
    },
    {
        type: 'volume',
        name: 'Cubic Meter',
        oneInBaseValue: 1000,
    },
    {
        type: 'volume',
        name: 'Gallon',
        oneInBaseValue: 3.78541,
    },
    {
        type: 'volume',
        name: 'Ounce',
        oneInBaseValue: 0.0295735,
    },
    {
        type: 'volume',
        name: 'Quart',
        oneInBaseValue: 0.946353,
    },
    {
        type: 'volume',
        name: 'Pint',
        oneInBaseValue: 0.473176,
    },
    {
        type: 'volume',
        name: 'Cubic Feet',
        oneInBaseValue: 28.3168,
    },
    {
        type: 'volume',
        name: 'Cubic Yard',
        oneInBaseValue: 764.555,
    },
    {
        type: 'volume',
        name: 'Million Hectoliter',
        oneInBaseValue: 100000000,
    },
    {
        type: 'volume',
        name: 'Centum Cubic Feet',
        oneInBaseValue: 2831.6846592,
    },
    {
        type: 'distance',
        name: 'Milimeter',
        oneInBaseValue: 0.001, // basevalue is meter
    },
    {
        type: 'distance',
        name: 'Kilometer',
        oneInBaseValue: 1000,
    },
    {
        type: 'distance',
        name: 'Yard',
        oneInBaseValue: 0.9144,
    },
    {
        type: 'distance',
        name: 'Feet',
        oneInBaseValue: 0.3048,
    },
    {
        type: 'distance',
        name: 'Mile',
        oneInBaseValue: 1609.34,
    },
    {
        type: 'distance',
        name: 'Inch',
        oneInBaseValue: 0.0254,
    },
    {
        type: 'distance',
        name: 'Nautical Mile',
        oneInBaseValue: 1852,
    },
    {
        type: 'energy',
        name: 'Kilowatt-hour',
        oneInBaseValue: 1000, // basevalue is watt
    },
    {
        type: 'energy',
        name: 'Gigawatt-hour',
        oneInBaseValue: 1000000,
    },
    {
        type: 'energy',
        name: 'Megawatt-hour',
        oneInBaseValue: 100000,
    },
    {
        type: 'energy',
        name: 'Joule',
        oneInBaseValue: 1,
    },
    {
        type: 'energy',
        name: 'Btu',
        oneInBaseValue: 1055.06,
    },
    {
        type: 'energy',
        name: 'MMBtu',
        oneInBaseValue: 1055055852.62,
    },
    {
        type: 'area',
        name: 'Square Feet',
        oneInBaseValue: 0.092903, // basevalue is square meter
    },
    {
        type: 'area',
        name: 'Square Yard',
        oneInBaseValue: 0.836127,
    },
    {
        type: 'area',
        name: 'Square Mile',
        oneInBaseValue: 2589988.11,
    },
    {
        type: 'area',
        name: 'Hectare',
        oneInBaseValue: 10000,
    },
    {
        type: 'area',
        name: 'Acre',
        oneInBaseValue: 4046.86,
    },
    {
        type: 'currency',
        name: '$USD',
    },
    {
        type: 'currency',
        name: '€EUR',
    },
    {
        type: '%',
        name: '%',
    },
    {
        type: '-',
        name: 'Without unit',
    },
];

export function unitConverter(value, fromUnit, toUnit) {
    // find the conversion rate from the jsonConstants array
    const fromUnitConversion = jsonConstants.find((unit) => unit.name === fromUnit);
    const toUnitConversion = jsonConstants.find((unit) => unit.name === toUnit);

    // convert the value to the base unit
    const baseValue = value * fromUnitConversion.oneInBaseValue;
    // convert the base value to the desired unit
    return baseValue / toUnitConversion.oneInBaseValue || 0;
}

export const filterJsonConstantsByMeasure = (name) => {
    const targetUnit = jsonConstants.find((unit) => unit.name === name);
    if (!targetUnit) {
        return [];
    }
    return jsonConstants.filter((unit) => unit.type === targetUnit.type);
};

// helper constant in case we need to modify this in a couple of places
export const WITHOUT_UNIT = 'Without unit';
