import React, { useCallback, useEffect, useState } from 'react';
import {
    Grid,
    TextField,
    Paper,
    InputLabel,
    IconButton,
    Typography,
    Container,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import useNotify from '../../hooks/useNotify';
import { doSwaggerCall } from '../../hooks/useApi';
import EsgScenarioForm from './components/EsgScenarioForm';
import CommonButton from '../../components/atom/CommonButton';
import AddPlaceholder from '../../components/atom/AddPlaceholder';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';

function getQuestionList(targetHierarchy) {
    const questionLevels = [];

    const findQuestionLevels = (node, path = []) => {
        if (node.type === 'question') {
            questionLevels.push({
                node,
                path: [...path],
            });
        }
        if (node.children) {
            node.children.forEach((child, index) => {
                findQuestionLevels(child, [...path, index], [child.id]);
            });
        }
    };

    findQuestionLevels({ children: targetHierarchy });
    return questionLevels;
}

function EditEsgScenarioPage() {
    const { scenarioId } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [scenario, setScenario] = useState(null);
    const { notifyError, notifySuccess } = useNotify();
    const [scenarioEdit, setScenarioEdit] = useState(false);
    const [questionList, setQuestionList] = useState([]);
    const { showModal } = useModals();

    const loadScenario = useCallback(() => {
        setLoading(true);
        doSwaggerCall('EsgScenarios', 'getEsgScenario', { id: scenarioId })
            .then((res) => {
                setScenario({
                    id: res.id,
                    name: res.name,
                    company_id: res.company_id,
                    baseline_year: res.baseline_year,
                    target_year: res.target_year,
                    target_hierarchy: res.target_hierarchy,
                    esg_tag_id: res.esg_tag_id,
                    esg_tag_name: res.esg_tag_name,
                    site_filter: res.site_filter,
                    site_names: res.site_names,
                });
                setQuestionList(getQuestionList(res.target_hierarchy));
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
                navigate('/esgscenarios');
            });
    }, []);

    useEffect(() => {
        loadScenario();
    }, [scenarioId]);

    const onSubmit = async (values) => {
        try {
            await doSwaggerCall(
                'EsgScenarios',
                'editEsgScenario',
                { id: scenarioId },
                {
                    name: values.name,
                }
            );
            setScenarioEdit(false);
            notifySuccess('Scenario updated');
        } catch (err) {
            notifyError(err);
            setScenarioEdit(false);
        }
    };

    const saveHierarchy = async (values) => {
        try {
            await doSwaggerCall(
                'EsgScenarios',
                'editEsgScenario',
                { id: scenario.id },
                {
                    name: values.name,
                    targetHierarchy: scenario.target_hierarchy,
                }
            );
            notifySuccess('ESG Scenario updated');
        } catch (err) {
            notifyError(err);
        }
    };

    if (loading) {
        return true;
    }

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Formik onSubmit={onSubmit} initialValues={scenario}>
                        {({ isSubmitting, values, setFieldValue }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        xs={12}
                                        sx={{
                                            pl: scenarioEdit ? 0 : 2,
                                        }}
                                        style={{
                                            height: '70px',
                                        }}
                                    >
                                        {scenarioEdit ? (
                                            <Grid item xs={6} sx={{ pb: 2 }}>
                                                <TextField
                                                    fullWidth
                                                    label="Name"
                                                    autoComplete="off"
                                                    value={values.name}
                                                    required
                                                    onChange={(ev) =>
                                                        setFieldValue('name', ev.target.value)
                                                    }
                                                    onBlur={() => {
                                                        onSubmit(values);
                                                    }}
                                                    autoFocus
                                                />
                                            </Grid>
                                        ) : (
                                            <Grid item xs={6} style={{ alignContent: 'center' }}>
                                                <Typography
                                                    variant="h5"
                                                    style={{
                                                        display: 'inline-block',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {values.name}
                                                </Typography>
                                                <IconButton
                                                    disabled={isSubmitting}
                                                    onClick={() => setScenarioEdit(true)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Grid>
                                        )}
                                        <Grid item style={{ alignContent: 'center' }}>
                                            <CommonButton
                                                fullWidth
                                                onClick={() => saveHierarchy(values)}
                                                label="Save Scenario"
                                                buttonType="primary"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            item
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >
                                            <Grid item xs={12}>
                                                <Paper sx={{ p: '16px 32px' }}>
                                                    <Grid
                                                        container
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Grid item>
                                                            <InputLabel>Years</InputLabel>
                                                            {values.baseline_year} {'->'}
                                                            {values.target_year}
                                                        </Grid>
                                                        {values.esg_tag_name && (
                                                            <Grid item>
                                                                <InputLabel>ESG Tag</InputLabel>
                                                                {values.esg_tag_name}
                                                            </Grid>
                                                        )}
                                                        {values.site_names.length > 0 && (
                                                            <Grid item>
                                                                <InputLabel>Sites</InputLabel>
                                                                {values.site_names.join(', ')}
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
                <Grid item xs={12}>
                    {questionList.map(({ node: question, path }) => (
                        <EsgScenarioForm
                            key={question?.id}
                            scenario={scenario}
                            setScenario={setScenario}
                            question={question}
                            path={path}
                        />
                    ))}
                </Grid>
                <Grid item xs={12}>
                    <AddPlaceholder
                        onClick={() => {
                            showModal(MODAL_TYPE.ADD_ESG_QUESTION_TO_SCENARIO, {
                                scenario,
                                questionList: questionList.map((item) => ({
                                    type: 'question',
                                    id: item?.node.id,
                                })),
                                reloadData: () => loadScenario(),
                            });
                        }}
                        customStyle={{ height: '50px', iconHeight: '25px', iconWidth: '25px' }}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default EditEsgScenarioPage;
