import { Autocomplete, FormControl, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import FieldsTable from './FieldsTable';
import { FIELD_TYPES } from '../../../helpers/constans';

// Set only numeric fields
function setOnlyNumericFields(fields) {
    const uniqueFields = new Map();
    fields
        .filter((field) => field.type === FIELD_TYPES.NUMBER)
        .forEach((field) => {
            if (field.id && !uniqueFields.has(field.id)) {
                uniqueFields.set(field.id, {
                    id: field.id,
                    label: field.label || '',
                    measure: field.measure || '',
                    defaultMeasure: field.defaultMeasure || '',
                });
            }
        });
    return Array.from(uniqueFields.values());
}

const SupplierCustomQuestionSelector = ({ panelConfig, setPanelConfig }) => {
    const [customQuestions, setCustomQuestions] = useState([]);
    const { notifyError } = useNotify();
    const [customSurveyFields, setCustomSurveyFields] = useState(panelConfig?.fields || []);

    const getSupplierSurvey = async () => {
        if (panelConfig?.suppliers.length !== 0) {
            try {
                const customQs = await doSwaggerCall(
                    'CustomQuestions',
                    'getCustomQuestionsForSupplier',
                    {
                        idList: [...panelConfig.suppliers.map((s) => s.id)],
                    }
                );
                setCustomQuestions(customQs);

                // Set numeric fields only when supplier custom questions are initially loaded
                const allNumericFields = customQs.flatMap((question) => {
                    const customQ = customQs.find(
                        (q) => parseInt(q.id, 10) === parseInt(question.id, 10)
                    );
                    return setOnlyNumericFields(question.fields || [], customQ);
                });
                setCustomSurveyFields(allNumericFields);
            } catch (err) {
                notifyError(err);
            }
        }
    };

    useEffect(() => {
        if (panelConfig.suppliers) {
            setCustomSurveyFields(panelConfig?.fields || []);
            getSupplierSurvey();
        }
    }, [panelConfig.suppliers]);

    return (
        <>
            <Grid item xs={12}>
                {customQuestions.length > 0 && panelConfig?.suppliers.length > 0 ? (
                    <FormControl fullWidth>
                        <Autocomplete
                            options={customQuestions}
                            getOptionLabel={(option) => `${option.name}`}
                            value={
                                customQuestions.find(
                                    (question) => question.id === panelConfig?.customQuestion
                                ) || null
                            }
                            onChange={(_, newValue) => {
                                setPanelConfig({
                                    ...panelConfig,
                                    customQuestion: newValue?.id,
                                    fields: setOnlyNumericFields(newValue?.fields || []),
                                });
                                setCustomSurveyFields(setOnlyNumericFields(newValue?.fields || []));
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                                <TextField {...params} label="Custom question" />
                            )}
                        />
                    </FormControl>
                ) : null}
            </Grid>
            {panelConfig?.customQuestion && panelConfig?.suppliers.length > 0 ? (
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <FieldsTable
                        fieldItems={customSurveyFields}
                        panelConfig={panelConfig}
                        setPanelConfig={setPanelConfig}
                    />
                </Grid>
            ) : null}
        </>
    );
};

export default SupplierCustomQuestionSelector;
