import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import PageWithHeader from '../../components/header/PageWithHeader';
import SearchInput from '../../components/atom/SearchInput';
import ButtonWithIcon from '../../components/header/ButtonWithIcon';
import IntegrationsTable from './components/IntegrationsTable';
import { doSwaggerCall, useApi } from '../../hooks/useApi';
import { ROLES, TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import useSorting from '../../hooks/useSorting';
import useNotify from '../../hooks/useNotify';
import { useAuth } from '../../hooks/useAuth';

const IntegrationsPage = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const { notifyError } = useNotify();
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const [companyName, setCompanyName] = useState(null);
    const { user } = useAuth();

    const [data, loading, error, setData, reloadData] = useApi('Integrations', 'getIntegrations', {
        search,
        sort,
        sortDir,
        limit,
        offset: page * limit,
    });

    // just for superadmins to show current company name in the title
    const getCompanyName = async () => {
        setCompanyName('Loading...');
        try {
            const response = await doSwaggerCall('Companies', 'getCompany', {
                id: user.company_id,
                year: 2022, // endpoint requires year
            });
            setCompanyName(response.name);
        } catch (e) {
            notifyError(e);
            setCompanyName('Loading failed');
        }
    };

    useEffect(() => {
        if (user.role === ROLES.SUPERADMIN) {
            getCompanyName();
        }
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError, user?.role]);

    return (
        <PageWithHeader
            title={`Integrations ${companyName ? `(${companyName})` : ''}`}
            searchInput={
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        height: '100%',
                    }}
                >
                    <SearchInput fullWidth search={search} setSearch={setSearch} />
                </Grid>
            }
            buttons={[
                <ButtonWithIcon
                    title="Add new Integration"
                    onClick={() => {
                        navigate('/integrations/new');
                    }}
                    icon={<AddIcon />}
                />,
            ]}
        >
            <IntegrationsTable
                rows={data?.integrations}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                reloadData={reloadData}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
            />
        </PageWithHeader>
    );
};

export default IntegrationsPage;
