import { Box, Tooltip } from '@mui/material';
import React from 'react';

function serviceStatus(s, st) {
    if (s) {
        return Object.values(st).some(
            (v) => v?.emissionSources?.length > 0 && v?.multipliers?.length > 0
        )
            ? {
                  status: 'Ready to import',
                  color: 'green',
              }
            : {
                  status: 'Ready to sync',
                  color: 'orange',
              };
    }
    return {
        status: 'Not ready to sync',
        color: 'red',
    };
}

const StatusIcon = ({ status, service_type }) => {
    return (
        <Tooltip title={serviceStatus(status, service_type).status}>
            <Box
                sx={{
                    ml: 2,
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: serviceStatus(status, service_type).color,
                    display: 'inline-block',
                }}
            />
        </Tooltip>
    );
};

export default StatusIcon;
