import { CircularProgress, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import { ESG_GROUPBY_OPTIONS, ESG_GROUPS, transformEsgHierarchyToItem } from '../../helpers/groups';
import { useEsgFilters } from '../../hooks/useEsgFilters';
import useNotify from '../../hooks/useNotify';
import { useAuth } from '../../hooks/useAuth';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import SearchInput from '../../components/atom/SearchInput';
import { doSwaggerCall, doSwaggerCallForPdf } from '../../hooks/useApi';
import EsgGroup from './components/EsgGroup';
import { BACKEND_URL } from '../../config';
import GroupByComponent from '../../components/atom/GroupByComponent';
import { allEsgsExport } from '../../helpers/excelExportEsgsHelpers';
import { useEsgFrameworkFilters } from '../../hooks/useEsgFrameworkFilters';
import { exportToXlsx } from '../../helpers/excelExportHelpers';
import ClosedYearAlert from '../../components/atom/ClosedYearAlert';
import { EXPORT_SHEET_TYPES, PERMISSIONS } from '../../helpers/constans';
import ResponsibilitySwitcherTab from '../../components/atom/ResponsibilitySwitcherTab';
import getMeasureValue from './helpers/getMeasureValue';
import PageWithHeader from '../../components/header/PageWithHeader';
import ButtonWithIcon from '../../components/header/ButtonWithIcon';

const EXPORT_LIMIT_CHUNK = 2;

const EsgsPage = ({ responsibility }) => {
    const { user, userPermissions } = useAuth();
    const { showModal } = useModals();
    const { esgCategory, esgSubcategory, esgDisclosure, esgQuestion, edited, setEdited } =
        useEsgFilters();
    const { esgFramework } = useEsgFrameworkFilters();
    const { site, year, closedYear } = useGlobalFilters();
    const [search, setSearch] = useQueryParam('search', withDefault(StringParam, ''));
    const [groupby, setGroupby] = useQueryParam(
        'groupby',
        withDefault(StringParam, responsibility ? ESG_GROUPS.NONE : ESG_GROUPS.HIERARCHY)
    );
    const [groupOpen, setGroupOpen] = useState([0]);
    const [dataGroups, setDataGroups] = useState([]);
    const { notifyError, notifyInfo, notifySuccess } = useNotify();
    const [generatingXlsx, setGeneratingXlsx] = useState(false);
    const [generatingEsgXlsx, setGeneratingEsgXlsx] = useState(false);
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const manageEsgsPermission = userPermissions.includes(PERMISSIONS.MANAGE_ESGS);

    // TODO: new tag should reload the whole page
    const [reloadKey, setReloadKey] = useState(0);
    const triggerReload = () => setReloadKey((prev) => prev + 1);

    // create a group props (category,subcategory,disclosure,question,site)
    useEffect(() => {
        const baseline = {
            esgCategory,
            esgSubcategory,
            esgDisclosure,
            esgQuestion,
            site,
            sectionHeader: '',
        };
        if (groupby === ESG_GROUPS.NONE) {
            let sectionHeader = '';
            if (responsibility === 'assignedToMe') {
                sectionHeader = 'Sustainability Data assigned to me';
            }
            if (responsibility === 'ownedByMe') {
                sectionHeader = 'Sustainability Data owned by me';
            }
            setDataGroups([
                {
                    id: 0,
                    name: 'All ESGs',
                    section: { ...baseline, sectionHeader },
                    type: 'all',
                },
            ]);
            setGroupOpen(['0-all']);
            return;
        }

        if (groupby === ESG_GROUPS.SITE) {
            doSwaggerCall('Sites', 'getSitesInCompany', {
                id: user.company_id,
                // TODO: fix meeee
                limit: 1000,
                offset: 0,
            })
                .then((res) => {
                    // filter sites on the group if any of them are selected
                    const filteredSites =
                        site.length === 0
                            ? res.sites
                            : res.sites.filter((e) => site.includes(e.id));
                    if (site.length === 0 || site.includes(0)) {
                        filteredSites.push({ id: 0 });
                    }
                    setDataGroups(
                        filteredSites.map((oneSite) => {
                            const sectionName = oneSite.id === 0 ? 'Corporate' : `${oneSite.name}`;
                            return {
                                id: oneSite.id,
                                name: sectionName,
                                section: {
                                    ...baseline,
                                    site: [oneSite.id],
                                    sectionHeader: sectionName,
                                },
                                type: 'site',
                            };
                        })
                    );
                    // open first 3 sites
                    setGroupOpen(
                        [...filteredSites, { id: 0 }].slice(0, 3).map((e) => `${e.id}-site`)
                    );
                })
                .catch((error) => notifyError(error));
            return;
        }
        if (groupby === ESG_GROUPS.HIERARCHY) {
            doSwaggerCall('EsgQuestions', 'getEsgQuestionHierarchy', {
                esgCategoryId: esgCategory,
                esgSubcategoryId: esgSubcategory,
                esgDisclosureId: esgDisclosure,
                esgQuestionId: esgQuestion,
                responsibility,
                siteIdList: site,
                year,
                hasanyesgs: true,
                esgFrameworkId: esgFramework,
            })
                .then((hierarchy) => {
                    const transformResult = hierarchy
                        .map((e) =>
                            transformEsgHierarchyToItem(
                                e,
                                esgCategory,
                                esgSubcategory,
                                esgDisclosure,
                                null, // question
                                baseline
                            )
                        )
                        // one more sort here since the transformEsgHierarchyToItem only sort children
                        .sort((a, b) =>
                            a.name.localeCompare(b.name, undefined, {
                                numeric: true,
                                sensitivity: 'base',
                            })
                        );
                    setDataGroups(transformResult);
                    if (transformResult.length === 0) {
                        let sectionHeader = 'Sustainability Data';
                        if (responsibility === 'assignedToMe') {
                            sectionHeader = 'Sustainability Data assigned to me';
                        }
                        if (responsibility === 'ownedByMe') {
                            sectionHeader = 'Sustainability Data owned by me';
                        }
                        setDataGroups([
                            {
                                id: 0,
                                name: 'All ESGs assigned to me',
                                section: { ...baseline, sectionHeader },
                                type: 'allAssigned',
                            },
                        ]);
                        setGroupOpen(['0-allAssigned']);
                    } else {
                        // open scopes
                        const dataGroupOpen = [];
                        transformResult.forEach((dataCategory) => {
                            if (dataCategory.type === 'category') {
                                dataGroupOpen.push(`${dataCategory.id}-${dataCategory.type}`);
                            }
                            // if there is only one question, we open the section
                            if (!transformResult[0].children && transformResult.length === 1) {
                                dataGroupOpen.push(`${dataCategory.id}-${dataCategory.type}`);
                            }
                        });
                        setGroupOpen(dataGroupOpen);
                    }
                })
                .catch((error) => {
                    notifyError(error);
                });
        }
        if (groupby === ESG_GROUPS.TAG) {
            doSwaggerCall('EsgTags', 'getAllEsgTags', {
                pageSize: 1000,
            })
                .then((res) => {
                    res.esgTags.push({ id: 0 });
                    setDataGroups(
                        res.esgTags.map((tag) => {
                            const sectionName = tag.id === 0 ? 'Untagged' : `${tag.name}`;
                            return {
                                id: tag.id,
                                name: sectionName,
                                section: {
                                    ...baseline,
                                    tag: [tag.id],
                                    sectionHeader: sectionName,
                                },
                                type: 'tag',
                            };
                        })
                    );
                    // open first 3 tags
                    setGroupOpen([...res.esgTags, { id: 0 }].slice(0, 3).map((e) => `${e.id}-tag`));
                })
                .catch((error) => notifyError(error));
        }
    }, [
        user,
        groupby,
        esgCategory,
        esgSubcategory,
        esgDisclosure,
        esgQuestion,
        site,
        setDataGroups,
        responsibility,
        year,
        esgFramework,
    ]);

    // transform esg questions and answers
    const transformEsgQuestionsAndAnswers = (esgQuestionFields, esgValues, maxFields) => {
        let counters = 0;
        const transformedFields = {};

        esgQuestionFields.forEach((field) => {
            const { label, type } = field;
            const index = counters + 1;
            transformedFields[`Question ${index}`] = label;

            // Find the corresponding value
            const foundValue = esgValues.find((e) => e.id === field.id);
            const value = foundValue?.value ? foundValue.value : '';
            const measure = type === 'number' ? getMeasureValue(foundValue) : ''; // Only calculate measure for numeric fields
            const hasValidValue = value !== '' && value !== null && value !== undefined;

            // Append measure only for numeric fields
            transformedFields[`Question ${index} answer`] = hasValidValue
                ? `${value}${measure ? ` ${measure}` : ''}`
                : '';

            counters++;
        });

        // Fill remaining questions and answers with empty values up to maxFields
        for (let i = counters + 1; i <= maxFields; i++) {
            transformedFields[`Question ${i}`] = '';
            transformedFields[`Question ${i} answer`] = '';
        }

        const orderedFields = {};

        // Add numeric fields first
        for (let i = 1; i <= maxFields; i++) {
            orderedFields[`Question ${i}`] = transformedFields[`Question ${i}`] || '';
            orderedFields[`Question ${i} answer`] = transformedFields[`Question ${i} answer`] || '';
        }

        return orderedFields;
    };

    // export all esgs
    const allExport = async () => {
        let esgs = [];
        let total = EXPORT_LIMIT_CHUNK + 1;
        for (let page = 0; page * EXPORT_LIMIT_CHUNK < total; page++) {
            // eslint-disable-next-line no-await-in-loop
            const esgChunk = await doSwaggerCall('Esgs', 'getEsgs', {
                year,
                siteIdList: site,
                limit: EXPORT_LIMIT_CHUNK,
                offset: page * EXPORT_LIMIT_CHUNK,
            });
            total = esgChunk.total;
            esgs = [...esgs, ...esgChunk.esgs];
        }

        // find max fields count from esgQuestionFields
        const maxFields = Math.max(...esgs.map((esg) => esg.esg_question_fields.length));
        return esgs.map((esg) => ({
            id: esg.id,
            site:
                esg.site_name === '' || typeof esg.site_name === 'undefined'
                    ? 'Corporate'
                    : esg.site_name,
            location: esg.location_name,
            question: esg.esg_question,
            disclosure: esg.esg_disclosure,
            subcategory: esg.esg_subcategory,
            category: esg.esg_category,
            notes: esg.notes,
            mark_completed: esg.mark_completed ? 'Yes' : 'No',
            completion: `${esg.field_filled_out}/${esg.field_count}`,
            ...transformEsgQuestionsAndAnswers(esg.esg_question_fields, esg.values, maxFields),
        }));
    };

    const esgReportXlsx = async (
        frameworkId,
        tagId,
        siteIdList,
        selectedEsgCategory,
        selectedEsgSubcategory,
        selectedEsgDisclosure,
        selectedEsgQuestion,
        selectedYear
    ) => {
        const reportRequest = await doSwaggerCall('Reports', 'esgReportRequest', {
            year: selectedYear,
        });
        const params = {
            token: reportRequest.token,
        };
        if (frameworkId && frameworkId !== 0) {
            params.reportByFramework = frameworkId;
        }
        if (tagId && tagId !== 0) {
            params.reportByTag = tagId;
        }
        if (selectedEsgCategory) {
            params.esgCategoryId = selectedEsgCategory;
        }
        if (selectedEsgSubcategory) {
            params.esgSubcategoryId = selectedEsgSubcategory;
        }
        if (selectedEsgDisclosure) {
            params.esgDisclosureId = selectedEsgDisclosure;
        }
        if (selectedEsgQuestion) {
            params.esgQuestionId = selectedEsgQuestion;
        }
        if (siteIdList && siteIdList.length > 0) {
            params.siteIdList = siteIdList;
        }
        const esgs = await doSwaggerCall('Reports', 'getXlsxEsgReport', {
            ...params,
        });

        return esgs.map((esg) => ({
            id: esg.id,
            site: esg.site,
            question: esg.question,
            disclosure: esg.disclosure,
            subcategory: esg.subcategory,
            category: esg.category,
            notes: esg.notes,
            tags: esg.tags,
            from_date: esg.from_date,
            to_date: esg.to_date,
            documents: esg.documents,
            mark_completed: esg.mark_completed,
            completion: esg.completion,
            values: esg.values,
        }));
    };

    const xlsxConfirm = async (
        frameworkId,
        tagId,
        siteIdList,
        selectedEsgCategory,
        selectedEsgSubcategory,
        selectedEsgDisclosure,
        selectedEsgQuestion,
        selectedYear
    ) => {
        setGeneratingEsgXlsx(true);
        let listToExport = [];
        try {
            listToExport = await esgReportXlsx(
                frameworkId,
                tagId,
                siteIdList,
                selectedEsgCategory,
                selectedEsgSubcategory,
                selectedEsgDisclosure,
                selectedEsgQuestion,
                selectedYear
            );
        } catch (err) {
            notifyError(err);
        }
        if (listToExport.length === 0) {
            notifyInfo('No data for selected report!');
            setGeneratingEsgXlsx(false);
            return;
        }

        exportToXlsx(listToExport, `esg_report_${selectedYear}`, EXPORT_SHEET_TYPES.ESG_REPORT);
        setGeneratingEsgXlsx(false);
        notifySuccess('Reports exported');
    };

    const pdfExport = async (
        frameworkId,
        tagId,
        siteIdList,
        selectedEsgCategory,
        selectedEsgSubcategory,
        selectedEsgDisclosure,
        selectedEsgQuestion,
        selectedYear,
        auditReportFormat
    ) => {
        setGeneratingPdf(true);
        let reportRequest = null;
        const params = {
            year: selectedYear,
        };
        if (frameworkId && frameworkId !== 0) {
            params.reportByFramework = frameworkId;
        }
        if (tagId && tagId !== 0) {
            params.reportByTag = tagId;
        }
        if (selectedEsgCategory) {
            params.esgCategoryId = selectedEsgCategory;
        }
        if (selectedEsgSubcategory) {
            params.esgSubcategoryId = selectedEsgSubcategory;
        }
        if (selectedEsgDisclosure) {
            params.esgDisclosureId = selectedEsgDisclosure;
        }
        if (selectedEsgQuestion) {
            params.esgQuestionId = selectedEsgQuestion;
        }
        if (siteIdList && siteIdList.length > 0) {
            params.siteIdList = siteIdList;
        }
        if (auditReportFormat) {
            params.auditReportFormat = auditReportFormat;
        }
        try {
            reportRequest = await doSwaggerCallForPdf('Reports', 'esgReportRequestPdf', {
                ...params,
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(reportRequest);
            link.download = `esg_report_${selectedYear}.pdf`;
            link.click();
        } catch (err) {
            notifyError(err);
            setGeneratingPdf(false);
        } finally {
            setGeneratingPdf(false);
        }
    };

    const esgExport = async (
        frameworkId,
        tagId,
        siteIdList,
        selectedEsgCategory,
        selectedEsgSubcategory,
        selectedEsgDisclosure,
        selectedEsgQuestion,
        selectedYear,
        auditReportFormat
    ) => {
        try {
            const reportRequest = await doSwaggerCall('Reports', 'esgReportRequest', {
                year: selectedYear,
            });
            let url = `${BACKEND_URL}/1.0/reports/esgdownload?token=${reportRequest.token}`;
            if (frameworkId && frameworkId !== 0) {
                url += `&reportByFramework=${frameworkId}`;
            }
            if (tagId && tagId !== 0) {
                url += `&reportByTag=${tagId}`;
            }
            if (selectedEsgCategory) {
                url += `&esgCategoryId=${selectedEsgCategory}`;
            }
            if (selectedEsgSubcategory) {
                url += `&esgSubcategoryId=${selectedEsgSubcategory}`;
            }
            if (selectedEsgDisclosure) {
                url += `&esgDisclosureId=${selectedEsgDisclosure}`;
            }
            if (selectedEsgQuestion) {
                url += `&esgQuestionId=${selectedEsgQuestion}`;
            }
            if (siteIdList && siteIdList.length > 0) {
                const encodedSiteIdList = encodeURIComponent(siteIdList);
                url += `&siteList=${encodedSiteIdList}`;
            }
            if (auditReportFormat) {
                url += `&auditReportFormat=${auditReportFormat}`;
            }
            window.open(url, '_blank', 'noopener,noreferrer');
        } catch (err) {
            notifyError(err);
        }
    };

    // different confirm function for esg report by reportType
    const confirmFunction = (
        frameworkId,
        reportTagId,
        siteIdList,
        reportType,
        selectedEsgCategory,
        selectedEsgSubcategory,
        selectedEsgDisclosure,
        selectedEsgQuestion,
        selectedYear,
        auditReportFormat
    ) => {
        const commonParams = {
            frameworkId,
            reportTagId,
            siteIdList,
            selectedEsgCategory,
            selectedEsgSubcategory,
            selectedEsgDisclosure,
            selectedEsgQuestion,
            selectedYear,
            auditReportFormat,
        };
        const handleExport = async (exportFunction) => {
            try {
                await exportFunction(...Object.values(commonParams));
            } catch (err) {
                notifyError(err);
            }
        };
        switch (reportType) {
            case 'XLSX':
                return handleExport(xlsxConfirm);
            case 'PDF':
                return handleExport(pdfExport);
            case 'HTML':
                return handleExport(esgExport);
            default:
                return () => {
                    notifyError('Something went wrong');
                };
        }
    };

    const loadDocumentsForDownload = async (structured = false) => {
        try {
            const { token } = await doSwaggerCall('Files', 'documentsRequest', {
                year,
                siteIdList: site,
                documentType: 'esg',
            });
            const url = `${BACKEND_URL}/1.0/documents/fordownload?token=${token}&structured=${structured}`;
            window.open(url, '_blank', 'noopener,noreferrer');
        } catch (documentError) {
            notifyError(documentError);
        }
    };

    return (
        <>
            {closedYear && (
                <Grid item xs={12} sx={{ mb: 1 }}>
                    <ClosedYearAlert />
                </Grid>
            )}
            <PageWithHeader
                title="Sustainability Data"
                searchInput={
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            height: '100%',
                        }}
                    >
                        <SearchInput
                            fullWidth
                            search={search}
                            setSearch={setSearch}
                            groupby={groupby}
                            setGroupby={setGroupby}
                        />
                    </Grid>
                }
                filters={[
                    <GroupByComponent
                        groupBy={groupby}
                        setGroupBy={setGroupby}
                        search={search}
                        groupByOptions={ESG_GROUPBY_OPTIONS}
                    />,
                    <ResponsibilitySwitcherTab responsibility={responsibility} type="esgs" />,
                ]}
                buttons={
                    manageEsgsPermission && [
                        <ButtonWithIcon
                            title="Add new ESG"
                            onClick={() => {
                                showModal(MODAL_TYPE.ESG_FILTER, {
                                    title: `ESG Filter`,
                                    setGroupOpen,
                                    setGroupby,
                                });
                            }}
                            icon={<AddIcon />}
                        />,
                        <ButtonWithIcon
                            title="Generate ESG Report"
                            onClick={() => {
                                showModal(MODAL_TYPE.SELECT_ESG_REPORT, {
                                    confirm: (
                                        frameworkId,
                                        reportTagId,
                                        siteIdList,
                                        reportType,
                                        selectedEsgCategory,
                                        selectedEsgSubcategory,
                                        selectedEsgDisclosure,
                                        selectedEsgQuestion,
                                        selectedYear,
                                        auditReportFormat
                                    ) => {
                                        confirmFunction(
                                            frameworkId,
                                            reportTagId,
                                            siteIdList,
                                            reportType,
                                            selectedEsgCategory,
                                            selectedEsgSubcategory,
                                            selectedEsgDisclosure,
                                            selectedEsgQuestion,
                                            selectedYear,
                                            auditReportFormat
                                        ).catch((err) => {
                                            notifyError(err);
                                        });
                                    },
                                });
                            }}
                            icon={
                                generatingEsgXlsx || generatingPdf ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : (
                                    <ArrowDropDownIcon />
                                )
                            }
                        />,
                        <ButtonWithIcon
                            title="Export to xlsx"
                            onClick={async () => {
                                setGeneratingXlsx(true);
                                let listToExport = [];
                                try {
                                    listToExport = await allExport();
                                } catch (err) {
                                    notifyError(err);
                                }
                                if (listToExport.length === 0) {
                                    notifyInfo('No data for selected report!');
                                    setGeneratingXlsx(false);
                                    return;
                                }
                                allEsgsExport(listToExport, `all_ESGs_${year}`);
                                setGeneratingXlsx(false);
                                notifySuccess('Reports exported');
                            }}
                            icon={
                                generatingXlsx ? (
                                    <CircularProgress color="inherit" size={25} />
                                ) : (
                                    <FileDownloadIcon />
                                )
                            }
                        />,
                        <ButtonWithIcon
                            title="Download all source data in a structured format"
                            onClick={() => {
                                loadDocumentsForDownload(true).catch((err) => {
                                    notifyError(err);
                                });
                            }}
                            icon={<FolderZipIcon />}
                        />,
                    ]
                }
            >
                <Grid item xs={12}>
                    <Grid container spacing={0.5} key={reloadKey}>
                        {dataGroups.map((item, idx) => {
                            return (
                                <EsgGroup
                                    key={idx}
                                    groupby={groupby}
                                    search={search}
                                    toc={[idx + 1]}
                                    setGroupOpen={setGroupOpen}
                                    responsibility={responsibility}
                                    groupOpen={groupOpen}
                                    item={item}
                                    edited={edited}
                                    setEdited={setEdited}
                                    triggerReload={triggerReload}
                                />
                            );
                        })}
                    </Grid>
                </Grid>
            </PageWithHeader>
        </>
    );
};

export default EsgsPage;
