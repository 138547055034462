import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import EsgOrCustomQuestionBarChart from './charts/EsgOrCustomQuestionBarChart';

const ChartWrapper = ({
    years,
    esgQuestionBarKeys,
    esgQuestionBarData,
    esgQuestionName,
    esgQuestionFieldMeasures,
}) => {
    return esgQuestionBarKeys.map((key, index) => {
        return (
            <EsgOrCustomQuestionBarChart
                data={esgQuestionBarData.filter((item) => item.label === key)}
                questionName={esgQuestionName}
                keys={years}
                fieldName={key}
                indexBy="site"
                axisLeftLegend={esgQuestionFieldMeasures[index]}
                type="esg"
            />
        );
    });
};

const BySelectedEsgQuestion = ({
    esgQuestion,
    sites,
    includeTotal,
    years,
    fields,
    setChartData,
}) => {
    const { notifyError } = useNotify();
    const [esgQuestionBarData, setEsgQuestionBarData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [esgQuestionBarKeys, setEsgQuestionBarKeys] = useState([]);
    const [esgQuestionFieldMeasures, setEsgQuestionFieldMeasures] = useState([]);
    const [esgQuestionName, setEsgQuestionName] = useState('');

    useEffect(() => {
        doSwaggerCall(
            'Dashboards',
            'getDataByEsgQuestionForBarChart',
            {
                esgQuestion,
                siteIdList: sites,
                includeTotal,
                years,
            },
            { fields }
        )
            .then((res) => {
                setEsgQuestionName(res.esgQuestionName);
                setEsgQuestionBarData(
                    res.esgData?.reduce((acc, curr) => {
                        // Find existing entry by site and label
                        let existing = acc.find(
                            (item) => item.site === curr.site && item.label === curr.label
                        );

                        // If not found, create a new entry
                        if (!existing) {
                            existing = {
                                site: curr.site,
                                label: curr.label,
                            };
                            acc.push(existing);
                        }

                        // Add yearly data to the existing or new entry
                        Object.keys(curr).forEach((key) => {
                            existing[key] = curr[key];
                        });

                        return acc;
                    }, [])
                );
                setEsgQuestionBarKeys(res.esgQuestionFields.map((field) => field.label));
                setEsgQuestionFieldMeasures(
                    res.esgQuestionFields.map((field) => field.defaultMeasure)
                );
                setChartData(res.esgData);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [
        setLoading,
        setEsgQuestionBarData,
        setEsgQuestionBarKeys,
        setEsgQuestionFieldMeasures,
        esgQuestion,
        sites,
        includeTotal,
        years,
        JSON.stringify(fields),
        setChartData,
    ]);

    return (
        <Grid item xs={12}>
            {esgQuestionBarData?.length === 0 ? (
                <Grid item>
                    {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
                </Grid>
            ) : (
                <ChartWrapper
                    years={years}
                    esgQuestionBarKeys={esgQuestionBarKeys}
                    esgQuestionBarData={esgQuestionBarData}
                    esgQuestionName={esgQuestionName}
                    esgQuestionFieldMeasures={esgQuestionFieldMeasures}
                />
            )}
        </Grid>
    );
};

export default BySelectedEsgQuestion;
