import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import EsgQuestionLineChart from './charts/EsgQuestionLineChart';

const ChartWrapper = ({
    years,
    esgQuestionKeys,
    esgQuestionLineData,
    esgQuestionFieldMeasures,
}) => {
    return esgQuestionKeys.map((key, index) => {
        return (
            <EsgQuestionLineChart
                key={index}
                data={esgQuestionLineData.filter((item) => item.label === key)}
                fieldName={key}
                axisLeftLegend={esgQuestionFieldMeasures[index]}
            />
        );
    });
};

const BySelectedEsgQuestionPerIntensity = ({
    esgQuestion,
    years,
    intensityMetric,
    sites,
    includeTotal,
    fields,
    setChartData,
}) => {
    const { notifyError } = useNotify();
    const [esgQuestionLineData, setEsgQuestionLineData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [esgQuestionKeys, setEsgQuestionKeys] = useState([]);
    const [esgQuestionFieldMeasures, setEsgQuestionFieldMeasures] = useState([]);
    const [esgQuestionName, setEsgQuestionName] = useState('');

    useEffect(() => {
        doSwaggerCall(
            'Dashboards',
            'getDataForEsgQuestionPerIntensityLineChart',
            {
                esgQuestion,
                intensityMetric,
                siteIdList: sites,
                includeTotal,
                years,
            },
            { fields }
        )
            .then((res) => {
                setEsgQuestionName(res.esgQuestionName);
                setEsgQuestionLineData(
                    res.esgData?.reduce((acc, curr) => {
                        // Find existing entry by site (id) and label
                        let existing = acc.find(
                            (item) => item.id === curr.id && item.label === curr.label
                        );

                        // If not found, create a new entry
                        if (!existing) {
                            existing = {
                                id: curr.id,
                                label: curr.label,
                            };
                            acc.push(existing);
                        }

                        // Add yearly data to the existing or new entry
                        Object.keys(curr).forEach((key) => {
                            existing[key] = curr[key];
                        });

                        return acc;
                    }, [])
                );
                setEsgQuestionKeys(res.esgQuestionFields.map((field) => field.label));
                setEsgQuestionFieldMeasures(
                    res.esgQuestionFields.map((field) => field.defaultMeasure)
                );
                setChartData(res.esgData);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [
        setLoading,
        setEsgQuestionLineData,
        setEsgQuestionKeys,
        setEsgQuestionFieldMeasures,
        esgQuestion,
        years,
        intensityMetric,
        sites,
        includeTotal,
        JSON.stringify(fields),
        setChartData,
    ]);

    return (
        <Grid item xs={12}>
            {esgQuestionLineData?.length === 0 ? (
                <Grid item>
                    {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
                </Grid>
            ) : (
                <>
                    <Grid item xs={12}>
                        <Typography variant="h5">{esgQuestionName}</Typography>
                    </Grid>
                    <ChartWrapper
                        years={years}
                        esgQuestionKeys={esgQuestionKeys}
                        esgQuestionLineData={esgQuestionLineData}
                        esgQuestionName={esgQuestionName}
                        esgQuestionFieldMeasures={esgQuestionFieldMeasures}
                    />
                </>
            )}
        </Grid>
    );
};

export default BySelectedEsgQuestionPerIntensity;
