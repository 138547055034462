import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, CircularProgress, Grid, Paper, Tooltip, Typography } from '@mui/material';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { doSwaggerCall, doSwaggerCallForPdf } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import SurveyResultNetzero from './components/SurveyResultNetzero';
import SurveyResultScopes from './components/SurveyResultScopes';
import SurveyResultReport from './components/SurveyResultReport';
import SurveyResultDatalines from './components/SurveyResultDatalines';
import SurveyResultSummary from './components/SurveyResultSummary';
import PageWithHeader from '../../components/header/PageWithHeader';
import SurveyCustomQuestions from './components/SurveyCustomQuestions';
import { BACKEND_URL } from '../../config';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import { exportToXlsx } from '../../helpers/excelExportHelpers';
import { EXPORT_SHEET_TYPES, FIELD_TYPES } from '../../helpers/constans';
import { convertUnitGToKg } from '../../helpers/convertUnits';

const MIN_SUPPORTED_SURVEY_VERSION_FOR_DETAILS = 4;

/** Survey results can be calculated in the following ways:
 *
 * - Zero target is totally independent from other survey results
 * 1. Supplier directly has company specific data (haveExactData === true)
 * 2. Supplier has report for scope 1 and 2 (hasScope12FullReport === true) but not or just partial for scope 3 (hasScope3Cat1Report === false || hasScope3Cat5Report === false)
 * 3. Supplier has nothing, we do calculate based on datalines (datalines.length > 0)
 */
function SupplierCo2Results({ survey }) {
    // 1. Supplier directly has company specific data (haveExactData === true)
    if (survey?.details?.haveExactData === true) {
        return <SurveyResultReport survey={survey} />;
    }
    // 2. Supplier has report for scope 3 (hasScope3Cat1Report === true && hasScope3Cat5Report === true OR datalines) and for scope 1 and 2 (hasScope12FullReport === true)
    if (
        survey?.details?.haveExactData === false &&
        survey?.details?.hasScope12FullReport === true
    ) {
        return <SurveyResultScopes survey={survey} />;
    }

    // Supplier has nothing, we do calculate based on datalines (datalines.length > 0)
    if (
        survey?.details?.haveExactData === false &&
        survey?.details?.hasScope12FullReport === false &&
        survey?.details?.datalines?.length > 0
    ) {
        return <SurveyResultDatalines survey={survey} />;
    }

    return <Grid item>Result has a strange combination, please contact support!</Grid>;
}

// transform per scope data to object
const transformPerScope = (scopeData) => {
    const transformedFields = {};
    scopeData.forEach((target) => {
        const { scopeId, co2e } = target;
        transformedFields[`Scope ${scopeId}`] = convertUnitGToKg(co2e) / 1000;
    });
    return transformedFields;
};

// transform custom questions
const transformCustomDetails = (customDetails, customQuestions) => {
    const transformedFields = {};
    customDetails.details.forEach((question) => {
        const value = question.fields
            .map((field) => {
                const customQuestion = customQuestions.find(
                    (customQ) => customQ.id === question.id
                );
                const customField = customQuestion.fields.find((f) => f.id === field.id);
                // file field value is an array of files
                let fieldValue = field?.value || [];
                if (customField.type === FIELD_TYPES.FILE) {
                    fieldValue = fieldValue.map((file) => file.name).join(', ');
                }
                // display number fields with measure if it has a measure
                if (
                    customField.type === FIELD_TYPES.NUMBER &&
                    field.measure !== 'Without unit' &&
                    fieldValue !== ''
                ) {
                    fieldValue = `${field.value} ${field.measure}`;
                }
                return `${customField.label}: ${fieldValue}`;
            })
            .join('\n');
        transformedFields[question.name] = value;
    });
    return transformedFields;
};

function SurveyDetailsPage() {
    const { supplierId } = useParams();
    const [loading, setLoading] = useState(true);
    const { notifyError, notifySuccess } = useNotify();
    const [survey, setSurvey] = useState(null);
    const [surveyClosed, setSurveyClosed] = useState(false);
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const { showModal } = useModals();

    const loadSurvey = useCallback(() => {
        setLoading(true);
        setSurveyClosed(false);
        doSwaggerCall('Suppliers', 'getSupplierSurvey', { id: supplierId })
            .then((res) => {
                setSurvey({
                    id: res.id,
                    name: res.name,
                    year: res.year,
                    co2e: res.co2e,
                    netzero: res.netzero,
                    netzeroTargets: res.netzeroTargets,
                    details: res.details,
                    notes: res.notes,
                    step: res.details.step,
                    categoryId: res.categoryId,
                    reviewedBy: res.reviewedBy,
                    datalineId: res.datalineId,
                    // extras
                    locations: res.locations,
                    emissionSources: res.emissionSources,
                    user: res.user,
                    // and everything else :D
                    ...res,
                });
                if (res.reviewedBy !== null && res.datalineId !== null) {
                    setSurveyClosed(true);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
            });
    }, [supplierId]);

    useEffect(() => {
        loadSurvey();
    }, [loadSurvey, supplierId]);

    // handle approve and close survey
    const handleApproveAndCloseSurvey = (id) => {
        setLoading(true);
        doSwaggerCall('Suppliers', 'approveAndCloseSurvey', { surveyId: id })
            .then(() => {
                setLoading(false);
                loadSurvey();
                notifySuccess('Survey approved and closed');
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
            });
    };

    // handle reopen survey
    const handleReopenSurvey = (id) => {
        setLoading(true);
        doSwaggerCall('Suppliers', 'reopenSurvey', { surveyId: id })
            .then(() => {
                setLoading(false);
                loadSurvey();
                notifySuccess('Survey reopened and dataline deleted');
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
            });
    };

    const checkClosedStatus = (surveyParam) => {
        if (
            surveyParam.reviewedBy &&
            surveyParam.datalineId &&
            surveyParam.reviewedBy !== null &&
            surveyParam.datalineId !== null
        ) {
            return 'closed';
        }
        return 'opened';
    };

    const supplierExport = async (id, auditReportFormat) => {
        try {
            const reportRequest = await doSwaggerCall('Reports', 'supplierReportRequest', {
                supplierIds: [id],
            });
            let url = `${BACKEND_URL}/1.0/reports/supplierdownload?token=${reportRequest.token}`;
            if (auditReportFormat) {
                url += `&auditReportFormat=${auditReportFormat}`;
            }
            window.open(url, '_blank', 'noopener,noreferrer');
        } catch (err) {
            notifyError(err);
        }
    };

    const pdfExport = async (id, auditReportFormat) => {
        setGeneratingPdf(true);
        let reportRequest;
        const params = { supplierIds: [id] };
        if (auditReportFormat) {
            params.auditReportFormat = auditReportFormat;
        }
        try {
            reportRequest = await doSwaggerCallForPdf('Reports', 'supplierReportRequestPdf', {
                ...params,
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(reportRequest);
            link.download = `stakeholder_${id}.pdf`;
            link.click();
        } catch (err) {
            notifyError(err);
            setGeneratingPdf(false);
        } finally {
            setGeneratingPdf(false);
        }
    };

    const xlsExport = async (id) => {
        try {
            const reportRequest = await doSwaggerCall('Reports', 'supplierReportRequest', {
                supplierIds: [id],
            });
            const suppliers = await doSwaggerCall('Reports', 'getXlsxSuppliersReport', {
                token: reportRequest.token,
            });
            const listToExport = [];
            suppliers.forEach((supplier) => {
                listToExport.push({
                    id: supplier.id,
                    name: supplier.name,
                    year: supplier.year,
                    co2e: convertUnitGToKg(supplier.co2e) / 1000,
                    netzero: !!supplier.netzero,
                    netzero_targets: supplier.netzero
                        ? supplier.netzero_targets
                              .map(
                                  (target) =>
                                      `${target.scope}: ${target.year ? `${target.year}` : '-'}`
                              )
                              .join('\n')
                        : null,
                    ...transformPerScope(supplier.per_scope),
                    ...transformCustomDetails(supplier.custom_details, supplier.custom_questions),
                });
            });
            exportToXlsx(listToExport, 'stakeholders', EXPORT_SHEET_TYPES.SUPPLIER_REPORT);
        } catch (err) {
            notifyError(err);
        }
    };

    // different confirm function for report by reportType
    const confirmFunction = (reportType, auditReportFormat) => {
        switch (reportType) {
            case 'HTML':
                return supplierExport(supplierId, auditReportFormat).catch((err) => {
                    notifyError(err);
                });
            case 'PDF':
                return pdfExport(supplierId, auditReportFormat).catch((err) => {
                    notifyError(err);
                });
            case 'XLSX':
                return xlsExport(supplierId).catch((err) => {
                    notifyError(err);
                });
            default:
                return () => {
                    notifyError('Something went wrong');
                };
        }
    };

    if (loading) {
        return true;
    }

    return (
        <PageWithHeader title={`Survey - ${survey.name} - ${survey.year}`}>
            {survey && (
                <>
                    {survey.details?.v < MIN_SUPPORTED_SURVEY_VERSION_FOR_DETAILS && (
                        <Grid item xs={12}>
                            <Typography variant="h4" color="error">
                                This survey was created with an old version of the survey tool and
                                might not be displayed correctly.
                            </Typography>
                        </Grid>
                    )}
                    {survey.isGhg && (
                        <>
                            <SurveyResultSummary
                                survey={survey}
                                status={checkClosedStatus(survey)}
                            />
                            <Grid item xs={12}>
                                <Paper sx={{ p: 2, mt: 2 }}>
                                    <Grid container spacing={2}>
                                        <SurveyResultNetzero survey={survey} />
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper sx={{ p: 2, mt: 2 }}>
                                    <Grid container spacing={2}>
                                        <SupplierCo2Results survey={survey} />
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper sx={{ p: 2, mt: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sx={{ pt: 4 }}>
                                            <Typography variant="subtitle1">
                                                Additional notes
                                            </Typography>
                                            {Object.entries(survey.notes).map((note) => (
                                                <Grid>
                                                    {note[0][0].toUpperCase() + note[0].slice(1)}:
                                                    <pre key={note}>{note[1] || '-'}</pre>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {survey.customQuestions.length > 0 && (
                        <Grid item xs={12}>
                            <Paper sx={{ p: 2, mt: 2 }}>
                                <Grid container spacing={2}>
                                    <SurveyCustomQuestions survey={survey} />
                                </Grid>
                            </Paper>
                        </Grid>
                    )}
                    <Grid item xs={10} sx={{ pt: 2 }}>
                        {survey.categoryId === null || typeof survey.categoryId === 'undefined' ? (
                            <Tooltip title="The Stakeholder category must be filled before the survey is approved and closed">
                                <span>
                                    <Button variant="contained" fullWidth disabled>
                                        {!surveyClosed
                                            ? 'Approve and close survey'
                                            : 'Reopen survey and delete dataline'}
                                    </Button>
                                </span>
                            </Tooltip>
                        ) : (
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={() => {
                                    if (!surveyClosed) {
                                        handleApproveAndCloseSurvey(survey.id);
                                    } else {
                                        handleReopenSurvey(survey.id);
                                    }
                                }}
                            >
                                {!surveyClosed
                                    ? 'Approve and close survey'
                                    : 'Reopen survey and delete dataline'}
                            </Button>
                        )}
                    </Grid>
                    <Tooltip
                        title={
                            !surveyClosed
                                ? 'Approve survey before generate report'
                                : 'Generate report'
                        }
                    >
                        <Grid item xs={2} sx={{ pt: 2 }}>
                            <Button
                                variant="contained"
                                fullWidth
                                disabled={
                                    !surveyClosed ||
                                    generatingPdf ||
                                    (survey.details?.v &&
                                        survey.details?.v <
                                            MIN_SUPPORTED_SURVEY_VERSION_FOR_DETAILS)
                                }
                                onClick={() => {
                                    showModal(MODAL_TYPE.SELECT_SUPPLIER_REPORT, {
                                        confirm: (reportType, auditReportFormat) => {
                                            confirmFunction(reportType, auditReportFormat).catch(
                                                (err) => {
                                                    notifyError(err);
                                                }
                                            );
                                        },
                                    });
                                }}
                            >
                                {generatingPdf ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : (
                                    <FeedOutlinedIcon />
                                )}
                            </Button>
                        </Grid>
                    </Tooltip>
                </>
            )}
        </PageWithHeader>
    );
}

export default SurveyDetailsPage;
