/* eslint-disable no-await-in-loop */
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import { doSwaggerCall } from '../hooks/useApi';
import { EXPORT_SHEET_TYPES } from './constans';

const EXPORT_LIMIT_CHUNK = 2;

const HEADER_MAPPING = {
    scope: 'Scope',
    category: 'Category',
    subcategory: 'Subcategory',
    emission_source: 'Emission source',
    siteName: 'Site',
    sumOfCO2e: 'Emission (CO2e mt)',
    location_id: 'Location',
    location: 'Location',
    co2_factor: 'CO2_factor',
    ch4_factor: 'CH4_factor',
    n2o_factor: 'N2O_factor',
    source: 'Source',
    marked_for_deletion: 'Marked_for_deletion',
    parent_name: 'Parent_name',
    id: 'Id',
    name: 'Name',
    tags: 'Tags',
    email: 'Email',
    permission: 'Permission',
};

const SUPPLIER_HEADER_MAPPING = {
    id: 'Id',
    name: 'Name',
    industry: 'Industry',
    type: 'Type',
    location: 'Location',
    scope: 'Scope',
    category: 'Category',
    tags: 'Tags',
    marked_for_deletion: 'Marked_for_deletion',
};

const SUPPLIER_REPORT_HEADER_MAPPING = {
    id: 'Id',
    name: 'Name',
    year: 'Year',
    co2e: 'CO2e (mt)',
    netzero: 'Netzero',
    netzero_targets: 'Netzero Targets',
    'Scope 1': 'Scope 1 (mt)',
    'Scope 2': 'Scope 2 (mt)',
    'Scope 3': 'Scope 3 (mt)',
    // custom_details generated from custom questions
};

const MASTER_SHEET_HEADER_MAPPING = {
    source_name: 'source_name',
    subcategory: 'subcategory',
    category: 'category',
    scope: 'scope',
    parent_source_name: 'parent_source_name',
    parent_subcategory_name: 'parent_subcategory_name',
    parent_category_name: 'parent_category_name',
    parent_scope_name: 'parent_scope_name',
    base_unit: 'base_unit',
    co2: 'co2',
    co2_unit: 'co2_unit',
    ch4: 'ch4',
    ch4_unit: 'ch4_unit',
    n2o: 'n2o',
    n2o_unit: 'n2o_unit',
    source: 'source',
    admin_note: 'admin_note',
    guide_note: 'guide_note',
    location: 'location',
    use_as_global: 'use_as_global',
    note_in_report: 'note_in_report',
    company_specific: 'company_specific',
};

const AUDIT_REPORT_HEADER_MAPPING = {
    scope: 'Scope',
    category: 'Category',
    subcategory: 'Subcategory',
    emission_source: 'Emission source',
    site: 'Site',
    co2e: 'Co2e (mt)',
    emission_description: 'Description',
    documents: 'Documents',
    notes: 'Notes',
    fields: 'Fields',
    usage: 'Usage',
    unit_of_measure: 'Unit of Measure',
    from_date: 'From date',
    to_date: 'To date',
    is_estimated: 'Estimated',
};

const ESG_REPORT_HEADER_MAPPING = {
    id: 'Id',
    site: 'Site',
    question: 'Question',
    disclosure: 'Subsubcategory',
    subcategory: 'Subcategory',
    category: 'Category',
    notes: 'Notes',
    tags: 'Tags',
    from_date: 'From date',
    to_date: 'To date',
    documents: 'Documents',
    mark_completed: 'Completed',
    completion: 'Completion',
};

const VARIANCE_TRACKER_HEADER_MAPPING = {
    levelType: 'Level Type',
    levelHierarchy: 'Level Hierarchy',
    levelName: 'Level Name',
    percentDifference: 'Percent Difference',
    co2eDifference: 'CO2e (mt) Difference',
    fromYear: '- CO2e (mt)',
    toYear: '- CO2e (mt)',
};

const VARIANCE_TRACKER_HEADER_LABELS = {
    fromYear: 'fromYear',
    toYear: 'toYear',
};

// generate contact fields from contact number and use it in header mapping
const generateContactFields = (contactNumber) => {
    for (let i = 1; i <= contactNumber; i++) {
        SUPPLIER_HEADER_MAPPING[`contact_name${i}`] = `ContactName${i}`;
        SUPPLIER_HEADER_MAPPING[`contact_email${i}`] = `ContactEmail${i}`;
        SUPPLIER_HEADER_MAPPING[`contact_phone${i}`] = `ContactPhone${i}`;
    }
};

// generate custom questions
const generateCustomQuestions = (data) => {
    Object.keys(data).forEach((key) => {
        if (!SUPPLIER_REPORT_HEADER_MAPPING[key]) {
            SUPPLIER_REPORT_HEADER_MAPPING[key] = key;
        }
    });
};

// generate fields
const generateFields = (fieldNumber) => {
    for (let i = 1; i <= fieldNumber; i++) {
        AUDIT_REPORT_HEADER_MAPPING[`Field ${i}`] = `Field ${i}`;
        AUDIT_REPORT_HEADER_MAPPING[`Field ${i} unit`] = `Field ${i} unit`;
    }
};

// generate headers for supplier report and generate custom questions based on given data
const generateSupplierReportHeaders = (data) => {
    generateCustomQuestions(data[0]);

    return Object.keys(data[0]).map((object) => {
        return SUPPLIER_REPORT_HEADER_MAPPING[object];
    });
};

// generate headers for suppliers
const generateSuppliersHeaders = (data) => {
    // find that row from newData which has most number of keys
    const mostKeysIndex = data.reduce((acc, curr, index) => {
        if (Object.keys(curr).length > Object.keys(data[acc]).length) {
            return index;
        }
        return acc;
    }, 0);
    generateContactFields((Object.keys(data[mostKeysIndex]).length - 5) / 3);

    return Object.keys(data[mostKeysIndex]).map((object) => {
        return SUPPLIER_HEADER_MAPPING[object];
    });
};

// generate headers for audit report and generate fields
const generateAuditReportHeaders = (data) => {
    // find data row which has the highest column number
    const maxLengthData = data.reduce((acc, curr) => {
        const numColumns = Object.keys(curr).filter((key) => key.startsWith('Field')).length;
        return numColumns > Object.keys(acc).filter((key) => key.startsWith('Field')).length
            ? curr
            : acc;
    }, {});
    // get max fields number from maxLengthData
    const maxFieldsNumber = Object.keys(maxLengthData).filter((key) =>
        key.startsWith('Field')
    ).length;
    // maxFieldsNumber / 2, because we need X, but field columns are like this: { 'Field X', 'Field X unit' }
    generateFields(maxFieldsNumber / 2);

    return Object.keys(maxLengthData).map((object) => {
        return AUDIT_REPORT_HEADER_MAPPING[object];
    });
};

// generate headers for esg report
const generateEsgReportHeaders = (data) => {
    const maxNumOfQuestions = data.reduce((max, obj) => Math.max(max, obj.values.length), 0);

    // make headers for the question - value - measure trio based on the longest 'values' property
    const questionsHeaders = [];
    for (let i = 0; i < maxNumOfQuestions; i++) {
        questionsHeaders.push(`Question ${i + 1}`);
        questionsHeaders.push(`Value ${i + 1}`);
        questionsHeaders.push(`Measure ${i + 1}`);
    }

    // remove property with key 'values'
    const dataWithoutValues = Object.keys(data[0]).reduce((acc, key) => {
        if (key !== 'values') {
            acc[key] = data[0][key];
        }
        return acc;
    }, {});

    return [
        ...Object.keys(dataWithoutValues).map((object) => {
            return ESG_REPORT_HEADER_MAPPING[object];
        }),
        ...questionsHeaders,
    ];
};

// generate headers for variance tracker
const generateVarianceTrackerHeaders = (data) => {
    return Object.keys(data.rows[0]).map((object) => {
        if (object === VARIANCE_TRACKER_HEADER_LABELS.fromYear) {
            return `${data.headerYears.fromYear} ${VARIANCE_TRACKER_HEADER_MAPPING[object]}`;
        }
        if (object === VARIANCE_TRACKER_HEADER_LABELS.toYear) {
            return `${data.headerYears.toYear} ${VARIANCE_TRACKER_HEADER_MAPPING[object]}`;
        }
        return VARIANCE_TRACKER_HEADER_MAPPING[object];
    });
};

// generate headers for non specific sheets
const generateBasicHeaders = (data, exportType) => {
    return Object.keys(data[0]).map((object) => {
        if (exportType === EXPORT_SHEET_TYPES.MASTER) {
            return MASTER_SHEET_HEADER_MAPPING[object];
        }
        return HEADER_MAPPING[object];
    });
};

// Helper function to collect all data based on given exportType and filter object
export async function getData(exportType, filter) {
    let data = [];
    let total = EXPORT_LIMIT_CHUNK + 1;
    let dataType;
    let dataChunk;

    for (let page = 0; page * EXPORT_LIMIT_CHUNK < total; page++) {
        const paginationHelpers = {
            limit: EXPORT_LIMIT_CHUNK,
            offset: page * EXPORT_LIMIT_CHUNK,
        };

        switch (exportType) {
            case EXPORT_SHEET_TYPES.SITE: {
                dataType = 'sites';
                dataChunk = await doSwaggerCall('Sites', 'getSitesInCompany', {
                    id: filter,
                    ...paginationHelpers,
                });
                break;
            }
            case EXPORT_SHEET_TYPES.USERS: {
                dataType = 'users';
                dataChunk = await doSwaggerCall('Users', 'getUsersInCompany', {
                    id: filter,
                    page,
                    pageSize: EXPORT_LIMIT_CHUNK,
                });
                break;
            }
            case EXPORT_SHEET_TYPES.SUPPLIERS:
            case EXPORT_SHEET_TYPES.SUPPLIER_REPORT: {
                dataType = 'suppliers';
                dataChunk = await doSwaggerCall('Suppliers', 'getSuppliers', {
                    year: filter,
                    ...paginationHelpers,
                });
                break;
            }
            case EXPORT_SHEET_TYPES.LOCATIONS: {
                dataType = 'locations';
                dataChunk = await doSwaggerCall('Locations', 'getAllLocations', {
                    page,
                    pageSize: EXPORT_LIMIT_CHUNK,
                });
                break;
            }
            default:
                break;
        }
        total = dataChunk.total;
        data = [...data, ...dataChunk[dataType]];
    }

    // get and set parent_name from parent_id
    if (exportType === EXPORT_SHEET_TYPES.LOCATIONS) {
        for (let i = 0; i < data.length; i++) {
            // remove World from location export
            if (data[i].parent_id === null) {
                continue;
            }
            const parentLocation = await doSwaggerCall('Locations', 'getLocation', {
                id: data[i].parent_id,
            });
            data[i].parent_name = parentLocation.name;
        }
        return data.filter((l) => l.parent_id !== null);
    }

    return data;
}

/**
 * Generate xlsx data from an array of objects
 * @param {array} data array of objects to convert to xlsx rows
 * @returns header fields from object keys and rows from values
 */
export const generateXlsxData = (data, exportType) => {
    if (!data || data.length === 0) {
        if (exportType !== EXPORT_SHEET_TYPES.SITE) {
            return [];
        }
        if (exportType === EXPORT_SHEET_TYPES.MASTER) {
            data.push({
                id: '',
                name: '',
                location: '',
                tags: '',
                company_specific: '',
                marked_for_deletion: '',
            });
        }
    }

    const newData = exportType === EXPORT_SHEET_TYPES.VARIANCE_TRACKER ? data.rows : data;
    let headers;

    switch (exportType) {
        case EXPORT_SHEET_TYPES.SUPPLIER_REPORT: {
            headers = generateSupplierReportHeaders(newData);
            break;
        }
        case EXPORT_SHEET_TYPES.SUPPLIERS: {
            headers = generateSuppliersHeaders(newData);
            break;
        }
        case EXPORT_SHEET_TYPES.AUDIT_REPORT: {
            headers = generateAuditReportHeaders(newData);
            break;
        }
        case EXPORT_SHEET_TYPES.ESG_REPORT:
            headers = generateEsgReportHeaders(newData);
            break;
        case EXPORT_SHEET_TYPES.VARIANCE_TRACKER:
            headers = generateVarianceTrackerHeaders(data);
            break;
        default:
            headers = generateBasicHeaders(newData, exportType);
            break;
    }

    const rows = newData.map((element) => {
        return Object.values(element).map((value) => {
            // break question-value-measure values properly in the row (ESG_REPORT)
            if (value && element.values === value && exportType === EXPORT_SHEET_TYPES.ESG_REPORT) {
                return value.map((e) => [e.question, e.answer, e.measure]);
            }
            if (Array.isArray(value) && element.tags !== value) {
                return value.map((e) => e.name).join('\n');
            }
            if (
                Array.isArray(value) &&
                element.tags === value &&
                exportType === EXPORT_SHEET_TYPES.ESG_REPORT
            ) {
                return value.map((e) => `${e.name}: ${e.percentage}%`).join('\n');
            }
            return value;
        });
    });

    // break question-value-measure values properly in the row (ESG_REPORT)
    if (exportType === EXPORT_SHEET_TYPES.ESG_REPORT) {
        rows.forEach((row) => {
            row.forEach((element, index) => {
                if (Array.isArray(element)) {
                    row.splice(index, 1, ...element.flat());
                }
            });
        });
    }

    return [headers, ...rows];
};

/**
 * Generate xlsx data from an array of objects
 * @param ws
 * @param columnName
 */
const setColumnToNumber = (ws, columnName) => {
    const column = columnName;
    // get the worksheet range (column letter followed by row number)
    const range = ws['!ref'];
    if (!range) {
        return;
    }

    const [, , startRow, , endRow] = range.match(/([A-Z]+)(\d+):([A-Z]+)(\d+)/);
    const firstDataRow = parseInt(startRow, 10) + 1; // Skip header
    for (let i = firstDataRow; i <= endRow; i++) {
        const cellAddress = column + i;
        const cell = ws[cellAddress];

        if (cell) {
            cell.t = 'n'; // Set type to number
            cell.v = Number(cell.v); // Convert value to number format
            cell.w = undefined; // Remove formatted text to allow Excel to auto-format
        }
    }
};

// helper function to add an extra tab for any available data
const addExtraTab = (wb, data, sheetName) => {
    const helperTextRow = [
        `This is a helper tab to list all available ${sheetName.toLowerCase()}.`,
    ];
    const ws = utils.aoa_to_sheet(generateXlsxData(data));
    utils.sheet_add_aoa(ws, [[helperTextRow]], { origin: 'D1' });
    utils.book_append_sheet(wb, ws, sheetName);
};

/**
 * Generate xlsx file and initiate download
 * @param {array} data array of objects to convert to xlsx rows
 */
export const exportToXlsx = (
    data,
    name,
    exportSheetType,
    locations = null,
    supplierTags = null,
    companies = null
) => {
    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = utils.aoa_to_sheet(generateXlsxData(data, exportSheetType));
    const wb = utils.book_new();

    switch (exportSheetType) {
        case EXPORT_SHEET_TYPES.AUDIT_REPORT:
            ws['!autofilter'] = { ref: `${ws['!ref'].slice(0, -1)}1` };
            // force column F to number
            setColumnToNumber(ws, 'F');
            break;
        case EXPORT_SHEET_TYPES.ESG_REPORT:
            ws['!autofilter'] = { ref: `${ws['!ref'].slice(0, -1)}1` };
            break;
        default:
            break;
    }

    utils.book_append_sheet(
        wb,
        ws,
        exportSheetType === EXPORT_SHEET_TYPES.MASTER ? 'import_this' : 'SheetJS'
    );

    switch (exportSheetType) {
        case EXPORT_SHEET_TYPES.SITE:
        case EXPORT_SHEET_TYPES.MASTER:
            addExtraTab(wb, locations, 'Locations');
            addExtraTab(wb, companies, 'Companies');
            break;
        case EXPORT_SHEET_TYPES.SUPPLIERS:
            addExtraTab(wb, locations, 'Locations');
            addExtraTab(wb, supplierTags, 'Stakeholder tags');
            break;
        default:
            break;
    }

    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
    const file = new Blob([excelBuffer], { type: fileType });
    saveAs(file, `${name}${fileExtension}`);
};

export function setComment(ws, cell, value) {
    const cellData = ws[cell];
    if (!cellData.c) cellData.c = [];
    cellData.c.hidden = true;
    cellData.c.push({ t: value });
}
