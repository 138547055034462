import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import EsgOrCustomQuestionBarChart from './charts/EsgOrCustomQuestionBarChart';

const ChartWrapper = ({
    year,
    customQuestionBarKeys,
    customQuestionBarData,
    customQuestionName,
    customQuestionFieldMeasures,
}) => {
    return customQuestionBarKeys.map((key, index) => {
        return (
            <EsgOrCustomQuestionBarChart
                data={customQuestionBarData.filter((item) => item.label === key)}
                questionName={customQuestionName}
                keys={[year]}
                fieldName={key}
                indexBy="supplier"
                axisLeftLegend={customQuestionFieldMeasures[index]}
                type="custom"
            />
        );
    });
};

const BySelectedSupplierCustomSurvey = ({
    year,
    suppliers,
    customQuestion,
    fields,
    setChartData,
}) => {
    const { notifyError } = useNotify();
    const [customQuestionBarData, setCustomQuestionBarData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [customQuestionBarKeys, setCustomQuestionBarKeys] = useState([]);
    const [customQuestionFieldMeasures, setCustomQuestionFieldMeasures] = useState([]);
    const [customQuestionName, setCustomQuestionName] = useState('');

    useEffect(() => {
        setLoading(true);
        doSwaggerCall(
            'Dashboards',
            'getDataForSupplierCustomQuestionsBarChart',
            {
                year,
                supplierIds: suppliers.map((supplier) => supplier.id),
                customQuestionId: parseInt(customQuestion, 10),
            },
            { fields }
        )
            .then((res) => {
                setCustomQuestionName(res.customQuestionName);
                setCustomQuestionBarData(
                    res.data?.reduce((acc, curr) => {
                        // Find existing entry by supplier and label
                        let existing = acc.find(
                            (item) => item.supplier === curr.supplier && item.label === curr.label
                        );

                        // If not found, create a new entry
                        if (!existing) {
                            existing = {
                                supplier: curr.supplier,
                                label: curr.label,
                            };
                            acc.push(existing);
                        }

                        // Add  data to the existing or new entry
                        Object.keys(curr).forEach((key) => {
                            existing[key] = curr[key];
                        });

                        return acc;
                    }, [])
                );
                setCustomQuestionBarKeys(res.customQuestionFields.map((field) => field.label));
                setCustomQuestionFieldMeasures(
                    res.customQuestionFields.map((field) => field.defaultMeasure)
                );
                setChartData(res.data);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
                setLoading(false);
            });
    }, [year, suppliers, customQuestion, JSON.stringify(fields), setChartData, notifyError]);

    return (
        <Grid item xs={12}>
            {customQuestionBarData?.length === 0 ? (
                <Grid item>
                    {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
                </Grid>
            ) : (
                <ChartWrapper
                    year={year}
                    customQuestionBarKeys={customQuestionBarKeys}
                    customQuestionBarData={customQuestionBarData}
                    customQuestionName={customQuestionName}
                    customQuestionFieldMeasures={customQuestionFieldMeasures}
                />
            )}
        </Grid>
    );
};

export default BySelectedSupplierCustomSurvey;
