import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Chip,
    Typography,
} from '@mui/material';
import { groupEmissionSource, groupCategory, groupSubcategory } from '../helpers/groups';
import CommonButton from '../components/atom/CommonButton';
import AutocompleteSelect from '../components/atom/AutoCompleteSelect';
import {
    scopeAutocomplete,
    categoryAutocomplete,
    subcategoryAutocomplete,
    sourceForIntegrationsAutocomplete,
} from '../autocomplete';
import useNotify from '../hooks/useNotify';

const ConnectServiceTypesToEmissionsModal = ({
    title,
    showModal,
    MODAL_TYPE,
    serviceType, // key
    services,
    setServices,
}) => {
    const [sourceFilters, setSourceFilters] = useState({
        scopeFilter: null,
        categoryFilter: null,
        subcategoryFilter: null,
        emissionSourceFilter: null,
    });
    const [emissions, setEmissions] = useState([...services[serviceType].emissionSources]);
    const [multipliers, setMultipliers] = useState([...services[serviceType].multipliers]);
    const { notifyError } = useNotify();

    const closeAction = () => {
        // Update services with new emissions
        setServices({
            ...services,
            [serviceType]: {
                ...services[serviceType],
                emissionSources: emissions.map((e) => ({ id: e.id, name: e.name })),
                multipliers,
            },
        });
        showModal(MODAL_TYPE.NONE);
    };

    const handleDelete = (source) => {
        setEmissions((prev) => prev.filter((e) => e !== source));
        setMultipliers((prev) => prev.filter((_, index) => emissions[index] !== source));
    };

    const handleClear = () => {
        setSourceFilters({
            scopeFilter: null,
            categoryFilter: null,
            subcategoryFilter: null,
            emissionSourceFilter: null,
        });
        setEmissions([]);
        setMultipliers([]);
    };

    useEffect(() => {
        // Reset filters when emission is added
        setSourceFilters((prev) => ({
            ...prev,
            scopeFilter: null,
            categoryFilter: null,
            subcategoryFilter: null,
            emissionSourceFilter: null,
        }));
    }, [emissions]);

    return (
        <Dialog open onClose={closeAction} maxWidth="xs" fullWidth>
            <DialogTitle sx={{ alignSelf: 'center' }}>{title}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ pt: 1 }}>
                    <Grid item xs={12}>
                        <AutocompleteSelect
                            initCall={scopeAutocomplete.init}
                            autocompleteCall={scopeAutocomplete.search}
                            value={sourceFilters?.scopeFilter || null}
                            setValue={(ev, getItem) => {
                                const item = getItem(ev);
                                setSourceFilters({
                                    scopeFilter: item?.id || null,
                                    categoryFilter: null,
                                    subcategoryFilter: null,
                                    emissionSourceFilter: null,
                                });
                            }}
                        >
                            <TextField label="Scope" fullWidth size="small" />
                        </AutocompleteSelect>
                    </Grid>
                    <Grid item xs={12}>
                        <AutocompleteSelect
                            grouping={groupCategory}
                            initCall={categoryAutocomplete.init}
                            autocompleteCall={categoryAutocomplete.search}
                            value={sourceFilters?.categoryFilter || null}
                            setValue={(ev, getItem) => {
                                const item = getItem(ev);
                                setSourceFilters((prev) => ({
                                    scopeFilter: item?.scope_id || prev?.scopeFilter || null,
                                    categoryFilter: item?.id || null,
                                    subcategoryFilter: null,
                                    emissionSourceFilter: null,
                                }));
                            }}
                            searchParams={{
                                scope: sourceFilters?.scopeFilter,
                                sourceLimitation: true,
                            }}
                        >
                            <TextField label="Category" fullWidth size="small" />
                        </AutocompleteSelect>
                    </Grid>
                    <Grid item xs={12}>
                        <AutocompleteSelect
                            grouping={groupSubcategory}
                            initCall={subcategoryAutocomplete.init}
                            autocompleteCall={subcategoryAutocomplete.search}
                            value={sourceFilters?.subcategoryFilter || null}
                            setValue={(ev, getItem) => {
                                const item = getItem(ev);
                                setSourceFilters((prev) => ({
                                    scopeFilter: item?.scope_id || prev?.scopeFilter || null,
                                    categoryFilter:
                                        item?.category_id || prev?.categoryFilter || null,
                                    subcategoryFilter: item?.id || null,
                                    emissionSourceFilter: null,
                                }));
                            }}
                            searchParams={{
                                scope: sourceFilters?.scopeFilter,
                                category: sourceFilters?.categoryFilter,
                                sourceLimitation: true,
                            }}
                        >
                            <TextField label="Subcategory" fullWidth size="small" />
                        </AutocompleteSelect>
                    </Grid>
                    <Grid item xs={12}>
                        <AutocompleteSelect
                            grouping={groupEmissionSource}
                            initCall={sourceForIntegrationsAutocomplete.init}
                            autocompleteCall={sourceForIntegrationsAutocomplete.search}
                            value={sourceFilters?.emissionSourceFilter || null}
                            setValue={(ev, getItem) => {
                                const item = getItem(ev);
                                setSourceFilters((prev) => ({
                                    scopeFilter: item?.scope_id || prev?.scopeFilter || null,
                                    categoryFilter:
                                        item?.category_id || prev?.categoryFilter || null,
                                    subcategoryFilter:
                                        item?.subcategory_id || prev?.subcategoryFilter || null,
                                    emissionSourceFilter: item?.id || null,
                                }));
                                if (item?.name) {
                                    if (!emissions.find((e) => e.id === item.id)) {
                                        setEmissions((prev) => [
                                            ...prev,
                                            { id: item?.id, name: item?.name, serviceType },
                                        ]);
                                        setMultipliers((prev) => [...prev, 1]);
                                    } else {
                                        notifyError('Emission source already added');
                                    }
                                }
                            }}
                            searchParams={{
                                scope: sourceFilters?.scopeFilter,
                                category: sourceFilters?.categoryFilter,
                                subcategory: sourceFilters?.subcategoryFilter,
                                sourceLimitation: true,
                            }}
                        >
                            <TextField label="Source" fullWidth size="small" />
                        </AutocompleteSelect>
                    </Grid>
                </Grid>
                {emissions.length !== 0 && (
                    <>
                        <Grid item xs={12} sx={{ mt: 2, mb: 1 }}>
                            <Typography variant="h5">Selected emission sources</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {emissions.map((source) => (
                                <Chip
                                    sx={{ margin: 0.5 }}
                                    label={source.name}
                                    onDelete={() => handleDelete(source)}
                                />
                            ))}
                        </Grid>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <CommonButton
                            onClick={handleClear}
                            fullWidth
                            label="Clear"
                            buttonType="secondary"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CommonButton
                            onClick={closeAction}
                            fullWidth
                            label="Add"
                            buttonType="primary"
                            disabled={emissions.length === 0}
                        />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default ConnectServiceTypesToEmissionsModal;
