import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Stack,
    Typography,
    List,
    ListItem,
} from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { Link } from 'react-router-dom';
import NoteIcon from '@mui/icons-material/Note';
import WarningIcon from '@mui/icons-material/Warning';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import BadgeWithIcon from '../../../modals/components/BadgeWithIcon';
import StatusIcon from './StatusIcon';

const IntegrationsTable = ({
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    reloadData,
    sort,
    sortDir,
    setSorting,
}) => {
    const { showModal } = useModals();
    const { notifyError, notifySuccess } = useNotify();

    const sendIntegrationIdList = async (integrationIdList) => {
        try {
            for (let i = 0; i < integrationIdList.length; i++) {
                // eslint-disable-next-line no-await-in-loop
                await doSwaggerCall(
                    'Integrations',
                    'sendIntegrationLink',
                    {},
                    {
                        integrationId: integrationIdList[i],
                    }
                );
            }
            notifySuccess('Integration link(s) has been sent successfully');
            reloadData();
        } catch (error) {
            notifyError(error);
        }
    };

    const deleteIntegration = (id) => {
        doSwaggerCall('Integrations', 'deleteIntegration', { id })
            .then(() => {
                notifySuccess('Integration deleted');
                reloadData();
            })
            .catch((err) => {
                notifyError(err);
            });
    };

    const getContactsData = (selectedIds) => {
        const data = rows
            .filter((row) => selectedIds.includes(row.id))
            .map((row) => ({
                name: row.name,
                contacts: row.contacts,
            }));
        return data;
    };

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <SortableTableCell
                                arrow={sort === 'access_key' ? sortDir : ''}
                                onClick={() => setSorting('access_key')}
                            >
                                Status
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'name' ? sortDir : ''}
                                onClick={() => setSorting('name')}
                            >
                                Name
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'sitename' ? sortDir : ''}
                                onClick={() => setSorting('sitename')}
                            >
                                Site
                            </SortableTableCell>
                            <TableCell>Service types</TableCell>
                            <SortableTableCell
                                arrow={sort === 'sync_period' ? sortDir : ''}
                                onClick={() => setSorting('sync_period')}
                            >
                                Sync period
                            </SortableTableCell>
                            <TableCell>Last sync</TableCell>
                            <SortableTableCell
                                arrow={sort === 'type' ? sortDir : ''}
                                onClick={() => setSorting('type')}
                            >
                                Type
                            </SortableTableCell>
                            <TableCell>Notes</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <SkeletonTable rows={10} cells={9} />
                        ) : (
                            rows?.map((row) => {
                                return (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            <StatusIcon
                                                status={row.access_key}
                                                service_type={row.service_type}
                                            />
                                        </TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.site_name}</TableCell>
                                        <TableCell>
                                            <Stack spacing={1}>
                                                {Object.entries(row.service_type).map(
                                                    ([key, values]) => (
                                                        <div key={key}>
                                                            <Typography
                                                                variant="subtitle2"
                                                                color="primary"
                                                            >
                                                                {key}:
                                                            </Typography>
                                                            <List dense sx={{ pl: 1 }}>
                                                                {values.emissionSources.map(
                                                                    (source) => (
                                                                        <ListItem
                                                                            key={source.name}
                                                                            sx={{ p: 0 }}
                                                                        >
                                                                            {source.name}
                                                                        </ListItem>
                                                                    )
                                                                )}
                                                            </List>
                                                        </div>
                                                    )
                                                )}
                                            </Stack>
                                        </TableCell>
                                        <TableCell>{row.sync_period}</TableCell>
                                        <TableCell>
                                            <Grid alignItems="center" sx={{ display: 'flex' }}>
                                                {row.is_incorrect && (
                                                    <Tooltip title="The last sync was incorrect">
                                                        <WarningIcon
                                                            fontSize="small"
                                                            sx={{ color: 'red', mr: 0.5 }}
                                                        />
                                                    </Tooltip>
                                                )}
                                                {row.last_run_date}
                                            </Grid>
                                        </TableCell>
                                        <TableCell>{row.type}</TableCell>
                                        <TableCell>
                                            {row?.notes !== null && row?.notes !== '' && (
                                                <Grid item>
                                                    <BadgeWithIcon title={row?.notes}>
                                                        <NoteIcon
                                                            style={{
                                                                height: '20px',
                                                                width: '20px',
                                                                opacity: 0.3,
                                                            }}
                                                        />
                                                    </BadgeWithIcon>
                                                </Grid>
                                            )}
                                        </TableCell>
                                        <TableCell align="right" width="10%">
                                            <Tooltip title="Send integration link">
                                                <IconButton
                                                    onClick={() => {
                                                        showModal(MODAL_TYPE.SEND_INTEGRATION, {
                                                            title: 'Are you sure you want to send this integration link?',
                                                            id: row.id,
                                                            contactsData: getContactsData([row.id]),
                                                            confirm: () => {
                                                                sendIntegrationIdList([row.id]);
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <SendIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Link to={`/integrations/edit/${row.id}`}>
                                                <Tooltip title="Edit">
                                                    <IconButton>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Link>
                                            <IconButton
                                                onClick={() => {
                                                    showModal(MODAL_TYPE.CONFIRMATION, {
                                                        title: 'Are you sure you want to remove this integration?',
                                                        content: 'This action is irreversible.',
                                                        confirm: () => deleteIntegration(row.id),
                                                    });
                                                }}
                                            >
                                                <Tooltip title="Delete">
                                                    <DeleteIcon />
                                                </Tooltip>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default IntegrationsTable;
