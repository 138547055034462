import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DashboardPanel from './DashboardPanel';
import { useModals, MODAL_TYPE } from '../../../hooks/useModal';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import CommonButton from '../../../components/atom/CommonButton';
import AddPlaceholder from '../../../components/atom/AddPlaceholder';

const gridWidth = (visualType) => {
    if (
        visualType === 'scope_pie_chart' ||
        visualType === 'category_pie_chart' ||
        visualType === 'subcategory_pie_chart' ||
        visualType === 'site_pie_chart' ||
        visualType === 'supplier_custom_survey_cert_pie_chart'
    ) {
        return 6;
    }
    return 12;
};

const DashboardBuilder = ({
    name,
    description,
    panels,
    updatePanels,
    loading,
    readOnly = false,
}) => {
    const [editedPanels, setEditedPanels] = useState([]);
    const [displayPanels, setDisplayPanels] = useState(panels);
    const { showModal } = useModals();
    const navigate = useNavigate();
    const [hierarchyLoading, setHierarchyLoading] = useState(true);
    const [yearSumData, setYearSumData] = useState({});
    const { notifyError } = useNotify();
    const { year, site } = useGlobalFilters();

    useEffect(() => {
        setHierarchyLoading(true);
        doSwaggerCall('Hierarchy', 'getSourceHierarchySum', {
            // TODO: params will be replaced by template filters later
            year,
            siteIdList: site,
            reportByTag: null,
        })
            .then((res) => {
                setYearSumData(res);
            })
            .catch((error) => {
                notifyError(error);
            })
            .then(() => {
                setHierarchyLoading(false);
            });
    }, [year, site, setHierarchyLoading, setYearSumData]);

    useEffect(() => {
        setDisplayPanels(() => {
            return editedPanels.length === 0 ? panels : editedPanels;
        });
    }, [setDisplayPanels, editedPanels, panels]);

    const onPanelAdd = async (panel) => {
        let newPanels = [...panels];
        newPanels = [...newPanels, panel];
        await updatePanels(newPanels);
    };

    const onPanelUpdate = async (panel, panelId) => {
        setEditedPanels([]);
        const newPanels = [...panels];
        newPanels[panelId] = panel;
        await updatePanels(newPanels);
    };

    const onPanelDelete = async (panelId) => {
        const newPanels = [...panels];
        newPanels.splice(panelId, 1);
        await updatePanels(newPanels);
    };

    return (
        <Grid container spacing={2} alignContent="flex-start">
            {readOnly && (
                <>
                    <Grid item xs={12}>
                        <Typography variant="h2">{name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">{description}</Typography>
                    </Grid>
                </>
            )}
            {displayPanels &&
                displayPanels.map((panel, panelId) => {
                    return (
                        <Grid item xs={gridWidth(panel.visual_type)} key={panelId}>
                            <DashboardPanel
                                panel={panel}
                                visible={!loading && !hierarchyLoading}
                                yearSumData={yearSumData}
                                onPanelDelete={onPanelDelete}
                                onPanelUpdate={onPanelUpdate}
                                panelId={panelId}
                                readOnly={readOnly}
                            />
                        </Grid>
                    );
                })}
            {!readOnly && (
                <Grid item xs={12}>
                    <AddPlaceholder
                        onClick={() => {
                            showModal(MODAL_TYPE.ADD_DASHBOARD_PANEL, {
                                confirm: (panel) => onPanelAdd(panel),
                            });
                        }}
                    />
                </Grid>
            )}
            {!readOnly && (
                <Grid item xs={12}>
                    <CommonButton
                        fullWidth
                        onClick={() => navigate(`/dashboardtemplates`)}
                        label="Cancel"
                        buttonType="secondary"
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default DashboardBuilder;
