import React, { useCallback, useState, useEffect, useRef } from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Paper,
    Alert,
    LinearProgress,
    Typography,
    CircularProgress,
    Grid,
    IconButton,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import useNotify from '../hooks/useNotify';
import { useGlobalFilters } from '../hooks/useGlobalFilters';
import {
    doMasterImport,
    collectAllCategoriesAndSubcategories,
    getLocationMatrix,
    extendLinesWithIds,
    collectErrors,
    MASTER_IMPORT_TABLE_HEADERS,
} from '../helpers/masterSourceImport';
import CommonButton from '../components/atom/CommonButton';
import SkeletonTable from '../components/atom/SkeletonTable';
import ConfirmationImportMasterSourcesModalRow from './components/ConfirmationImportMasterSourcesModalRow';
import { useDarkMode } from '../hooks/useDarkMode';

const ConfirmationImportMasterSourcesModal = ({ data, finished, showModal, MODAL_TYPE }) => {
    const { year } = useGlobalFilters();
    const [subcatMatrix, setSubCatMatrix] = useState(false);
    const [locMatrix, setLocMatrix] = useState(false);
    const [importing, setImporting] = useState(false);
    const [importLog, setImportLog] = useState([]);
    const [mappedData, setMappedData] = useState([]);
    const [reloadTrigger, setReloadTrigger] = useState(0);
    const { notifyError } = useNotify();
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const { darkMode } = useDarkMode();

    const contentRef = useRef(null);
    const [delayedScrollTo, setDelayedScrollTo] = useState(null);

    // refs for the rows in case of error
    const rowRefs = useRef([]);

    // loading category matrix
    useEffect(() => {
        collectAllCategoriesAndSubcategories(data).then(setSubCatMatrix).catch(notifyError);
    }, [data, setSubCatMatrix, reloadTrigger]);

    // loading location matrix
    useEffect(() => {
        getLocationMatrix(data).then(setLocMatrix).catch(notifyError);
    }, [data, setLocMatrix, reloadTrigger]);

    useEffect(() => {
        if (!subcatMatrix || !locMatrix) {
            return;
        }
        extendLinesWithIds(data, subcatMatrix, locMatrix, year)
            .then(setMappedData)
            .catch(notifyError);
    }, [data, subcatMatrix, locMatrix, reloadTrigger]);

    const appendLog = useCallback(
        (line) => {
            setImportLog((log) => [...log, line]);
        },
        [setImportLog]
    );

    const hasAnyErrors = !!mappedData.find((e) => e.error.length > 0);

    const handleScrollToError = (line, allData) => {
        // Split the line at the first occurrence of " - Error:"
        const [sourceName, errorDetailsPart] = line.split(' - Error:');

        // Handle cases where the split does not yield two parts
        if (!sourceName || !errorDetailsPart) {
            return;
        }
        // Extract the first error message to jump to
        const errorDetails = errorDetailsPart.split(', Error:').shift().trim();

        // Find the row that matches the source name and contains the error message
        const errorLine = allData.find(
            (e) =>
                e.source_name.trim() === sourceName.trim() &&
                e.error.some((err) => err.includes(errorDetails))
        );

        if (errorLine) {
            const index = allData.indexOf(errorLine);
            if (rowRefs.current[index]) {
                setDelayedScrollTo(index);
            }
        } else {
            console.log('No matching row found for:', sourceName.trim(), errorDetails);
        }
    };

    useEffect(() => {
        if (!delayedScrollTo || !contentRef.current) {
            return () => {};
        }

        // get the top for the index row
        const top = rowRefs.current[delayedScrollTo].offsetTop;
        contentRef.current.scrollTo({ top });

        // make sure we hit the mark after the rows reached the viewport
        const delayedScrollTimeout = setTimeout(() => {
            const topSecondTime = rowRefs.current[delayedScrollTo].offsetTop;
            contentRef.current.scrollTo({ topSecondTime });
            setDelayedScrollTo(null);
        }, 200);

        return () => {
            clearTimeout(delayedScrollTimeout);
        };
    }, [delayedScrollTo, setDelayedScrollTo]);

    return (
        <Dialog
            open
            onClose={importing ? () => {} : closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                Importing master xls to year {year}
                <div
                    style={{
                        position: 'absolute',
                        bottom: 48,
                        right: 32,
                    }}
                >
                    <IconButton
                        sx={{
                            borderRadius: '50%',
                            border: `1px solid ${darkMode ? '#fff' : '#152238'}`,
                        }}
                        onClick={() => {
                            if (!contentRef.current) {
                                return;
                            }
                            contentRef.current.scrollTo({ top: 0 });
                        }}
                    >
                        <ArrowDropUpIcon sx={{ color: darkMode ? '#fff' : '#152238' }} />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent ref={contentRef}>
                {!importing && mappedData.length === 0 && (
                    <CircularProgress color="inherit" size={15} />
                )}
                {importing && (
                    <>
                        <Typography variant="h5">Importing sources...</Typography>
                        <LinearProgress />
                        <Typography variant="body1" style={{ height: '40vh', overflowY: 'scroll' }}>
                            {importLog.map((e, idx) => {
                                return <div key={idx}>{e}</div>;
                            })}
                        </Typography>
                    </>
                )}
                {importLog.length > 0 && (
                    <>
                        <Typography variant="h5">Import finished!</Typography>
                        <Typography variant="body1" style={{ height: '40vh', overflowY: 'scroll' }}>
                            {importLog.map((e, idx) => {
                                return <div key={idx}>{e}</div>;
                            })}
                        </Typography>
                    </>
                )}
                {subcatMatrix === false && (
                    <DialogContentText id="alert-dialog-description">
                        Loading categories and subcategories...
                    </DialogContentText>
                )}
                {subcatMatrix !== false && !importing && importLog.length === 0 && (
                    <>
                        <DialogContentText id="alert-dialog-description">
                            Make sure all errors are fixed before you continue!
                        </DialogContentText>
                        <TableContainer component={Paper}>
                            {collectErrors(mappedData).length > 0 && (
                                <Alert severity="error" sx={{ mb: 1 }}>
                                    {collectErrors(mappedData).map((line, idx) => {
                                        return (
                                            <Grid
                                                key={idx}
                                                onClick={() =>
                                                    handleScrollToError(line, mappedData)
                                                }
                                                style={{
                                                    cursor: 'pointer',
                                                    textDecoration: 'underline',
                                                }}
                                            >
                                                {line}
                                            </Grid>
                                        );
                                    })}
                                </Alert>
                            )}
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {MASTER_IMPORT_TABLE_HEADERS.map((header, idx) => {
                                            return <TableCell key={idx}>{header}</TableCell>;
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {mappedData.length === 0 && (
                                        <SkeletonTable rows={10} cells={9} />
                                    )}
                                    {mappedData.map((item, idx) => {
                                        return (
                                            <ConfirmationImportMasterSourcesModalRow
                                                item={item}
                                                idx={idx}
                                                rowRefs={rowRefs}
                                            />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
            </DialogContent>
            {importLog.length > 0 && (
                <DialogActions>
                    <CommonButton
                        onClick={closeAction}
                        autoFocus
                        label="Close"
                        buttonType="secondary"
                    />
                    <CommonButton
                        onClick={() => {
                            // reseting the state of the import window
                            setImportLog([]);
                            setLocMatrix(false);
                            setSubCatMatrix(false);
                            setReloadTrigger((e) => e + 1);
                        }}
                        label="Clear import result"
                        buttonType="primary"
                    />
                </DialogActions>
            )}
            {!importing && importLog.length === 0 && (
                <DialogActions>
                    <CommonButton
                        onClick={closeAction}
                        autoFocus
                        label="Cancel"
                        buttonType="secondary"
                    />
                    {!hasAnyErrors && subcatMatrix !== false && (
                        <CommonButton
                            onClick={() => {
                                setImporting(true);
                                doMasterImport(subcatMatrix, data, locMatrix, year, appendLog)
                                    .then(() => {
                                        setImporting(false);
                                        finished();
                                    })
                                    .catch((err) => {
                                        setImporting(false);
                                        notifyError(err);
                                    });
                            }}
                            label="Import master sheet"
                            buttonType="primary"
                        />
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default ConfirmationImportMasterSourcesModal;
