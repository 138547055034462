import { ResponsivePie } from '@nivo/pie';
import React, { useEffect } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { LegendsContextProvider, useLegends } from '../../../../hooks/useLegends';
import {
    PIECHART_PROPS,
    customNivoTheme,
    formatChartNumber,
} from '../../../../helpers/chartStyleHelper';
import { useDarkMode } from '../../../../hooks/useDarkMode';
import PieLegendDisplay from '../PieLegendDisplay';

const PieLegendSink = ({ dataWithArc }) => {
    const { setPieLegends } = useLegends();

    useEffect(() => {
        setPieLegends(dataWithArc);
    }, [setPieLegends, dataWithArc]);
    return null;
};

// Custom tooltip component
const CustomTooltip = ({ data }) => {
    const tooltipData = data.datum.data;
    return (
        <Paper
            style={{
                padding: '12px',
                borderRadius: '5px',
                backgroundColor: 'white',
                boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
            }}
        >
            <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>{tooltipData.id}</strong>
            </Typography>
            <Typography variant="body2">
                <strong>Number of Stakeholders:</strong> {tooltipData.value}
            </Typography>
            <Typography variant="body2">
                <strong>Stakeholders:</strong>
            </Typography>
            <ul>
                {tooltipData.supplierNames.map((supplier, index) => (
                    <li key={index}>{supplier}</li>
                ))}
            </ul>
        </Paper>
    );
};

const SupplierSurveyCertificatePieChart = ({ data, year }) => {
    const { darkMode } = useDarkMode();

    return (
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <LegendsContextProvider>
                        <Paper sx={{ borderColor: 'transparent' }}>
                            <Grid item xs={12}>
                                <Typography variant="h5">Certifications - {year}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ aspectRatio: '2/1', flex: '1 1 0' }}>
                                <ResponsivePie
                                    data={data.data}
                                    theme={customNivoTheme(darkMode)}
                                    colors={PIECHART_PROPS.colors}
                                    activeInnerRadiusOffset={PIECHART_PROPS.activeInnerRadiusOffset}
                                    activeOuterRadiusOffset={PIECHART_PROPS.activeOuterRadiusOffset}
                                    innerRadius={PIECHART_PROPS.innerRadius}
                                    borderWidth={PIECHART_PROPS.borderWidth}
                                    enableArcLabels={PIECHART_PROPS.enableArcLabels}
                                    padAngle={PIECHART_PROPS.padAngle}
                                    cornerRadius={PIECHART_PROPS.cornerRadius}
                                    borderColor={PIECHART_PROPS.borderColor}
                                    padding={PIECHART_PROPS.padding}
                                    animate={false}
                                    colorBy="value"
                                    margin={{ top: 15, right: 15, bottom: 15, left: 15 }}
                                    tooltip={(data) => <CustomTooltip data={data} />}
                                    // display both value and % value
                                    valueFormat={(value) =>
                                        `${Number(value).toLocaleString('en-US', {
                                            minimumFractionDigits: 0,
                                        })} - ${
                                            Number.isNaN((value / data.sum) * 100)
                                                ? 0
                                                : Math.round((value / data.sum) * 100)
                                        } %`
                                    }
                                    // display only value
                                    arcLabel={(e) => formatChartNumber(e)}
                                    enableArcLinkLabels={false}
                                    motionConfig="stiff"
                                    layers={['arcLinkLabels', 'arcs', 'arcLabels', PieLegendSink]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PieLegendDisplay />
                            </Grid>
                        </Paper>
                    </LegendsContextProvider>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SupplierSurveyCertificatePieChart;
