import { Autocomplete, FormControl, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';

const SupplierSelector = ({ panelConfig, setPanelConfig }) => {
    const [suppliers, setSuppliers] = useState([]);
    const { notifyError } = useNotify();

    const getAllSuppliersByYear = async () => {
        try {
            const res = await doSwaggerCall('Suppliers', 'getAllSuppliers', {
                year: panelConfig.year,
                pageSize: 1000,
            });
            setSuppliers(res.suppliers);
        } catch (err) {
            notifyError(err);
        }
    };

    useEffect(() => {
        if (panelConfig.year) {
            getAllSuppliersByYear();
        }
    }, [panelConfig.year]);

    return (
        <Grid item xs={12}>
            {panelConfig?.year ? (
                <FormControl fullWidth>
                    <Autocomplete
                        options={suppliers}
                        getOptionLabel={(option) => `${option.name}`}
                        value={
                            panelConfig.suppliers
                                ? suppliers.filter((supplier) =>
                                      panelConfig.suppliers.some(
                                          (selectedSupplier) => selectedSupplier.id === supplier.id
                                      )
                                  )
                                : []
                        }
                        onChange={(_, newValue) => {
                            setPanelConfig({
                                ...panelConfig,
                                suppliers: newValue,
                            });
                        }}
                        multiple
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => <TextField {...params} label="Stakeholder" />}
                    />
                </FormControl>
            ) : null}
        </Grid>
    );
};

export default SupplierSelector;
