import React, { useEffect, useCallback, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { doSwaggerCall } from '../../../hooks/useApi';

const DeckWidget = ({
    deckToken, // Created link session token
    token, // Resolved from the URL (to identify the integration)
    onExit,
    autoInitialize = false,
}) => {
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Function to connect the integration
    const connectIntegration = useCallback(
        async (publicToken) => {
            try {
                await doSwaggerCall(
                    'Integrations',
                    'connectIntegration',
                    { token },
                    { publicToken }
                );
                setMessage('Thanks for connecting Deck to North Star Carbon & Impact!');
            } catch (error) {
                setMessage('Error connecting Deck to the integration.');
            }
        },
        [token]
    );

    // Function to inject the Deck script
    const injectDeckScript = useCallback((onLoad, onError) => {
        const scriptId = 'deck-script';
        if (document.getElementById(scriptId)) {
            onLoad?.();
            return;
        }

        const script = document.createElement('script');
        script.id = scriptId;
        script.src = 'https://link.deck.co/link-initialize.js';
        script.onload = () => {
            console.log('Deck script loaded successfully.');
            onLoad?.();
        };
        script.onerror = () => {
            console.log('Failed to load the Deck script.');
            onError?.();
        };

        document.body.appendChild(script);
    }, []);

    // Function to initialize the Deck widget
    const initializeDeck = useCallback(() => {
        if (!deckToken) {
            console.log('Deck token is missing.');
            return;
        }

        injectDeckScript(
            () => {
                if (window.Deck) {
                    const deck = window.Deck.create({
                        token: deckToken,
                        onSuccess: ({ public_token }) => {
                            connectIntegration(public_token);
                            setIsLoading(false);
                        },
                        onExit: () => {
                            console.log('Deck integration exited.');
                            setIsLoading(false);
                            onExit?.();
                        },
                    });
                    deck.open();
                } else {
                    console.log('Deck object not available after script load.');
                    setIsLoading(false);
                }
            },
            () => {
                console.log('Error loading the Deck script.');
                setIsLoading(false);
            }
        );
    }, [deckToken, injectDeckScript, connectIntegration, onExit]);

    // Automatically initialize if `autoInitialize` is true
    useEffect(() => {
        if (autoInitialize) {
            initializeDeck();
        }
    }, [autoInitialize, initializeDeck]);

    return (
        <Grid item xs={12}>
            {!message ? (
                <Button
                    onClick={() => {
                        setIsLoading(true);
                        initializeDeck();
                    }}
                    disabled={!deckToken || isLoading}
                >
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : 'Connect to Deck'}
                </Button>
            ) : (
                <Typography variant="h5">{message}</Typography>
            )}
        </Grid>
    );
};

export default DeckWidget;
