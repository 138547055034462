/**
 * Check month date range
 * @param {string} fromDateValue from date value
 * @param {string} toDateValue to date value
 * @param {number} year year
 * @returns {number} month
 */
export default function returnMonthFromDateRange(fromDateValue, toDateValue, year) {
    let month = 0;
    if (fromDateValue && toDateValue) {
        const fromDate = new Date(fromDateValue);
        const toDate = new Date(toDateValue);
        // check if from_date and to_date are in the same year and month
        if (
            fromDate.getFullYear() === year &&
            toDate.getFullYear() === year &&
            fromDate.getMonth() === toDate.getMonth()
        ) {
            const firstDayOfMonth = new Date(year, fromDate.getMonth(), 1);
            const lastDayOfMonth = new Date(year, fromDate.getMonth() + 1, 0);
            // check if from_date is the first day of the month and to_date is the last day of the month
            if (
                fromDate.getDate() === firstDayOfMonth.getDate() &&
                toDate.getDate() === lastDayOfMonth.getDate()
            ) {
                // set month date range
                month = fromDate.getMonth() + 1;
            }
        }
    }
    return month;
}
